export function LifeEventIllustration(props: {
  className?: string;
  svgProps?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="44"
      fill="none"
      viewBox="0 0 46 44"
      className={props.className}
      {...props.svgProps}
    >
      <g >
        <path
          fill="#C84897"
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M23 43.642c12.498 0 22.63-9.688 22.63-21.638C45.63 10.054 35.498.366 23 .366S.37 10.053.37 22.004c0 11.95 10.132 21.638 22.63 21.638Z"
        />
        <path
          fill="#1D1D20"
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M8.73 26.518c1.777-1.014 2.223-3.54.994-5.641-1.23-2.102-3.667-2.985-5.445-1.971-1.778 1.014-2.223 3.54-.994 5.642 1.229 2.102 3.667 2.984 5.445 1.97Z"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M41.424 19.598c-.985-.563-2.177-.541-3.251-.051-1.503-7.255-8.005-12.71-15.788-12.71-8.9 0-16.113 7.123-16.113 15.913s7.213 15.912 16.113 15.912c7.22 0 13.33-4.688 15.38-11.145 1.66.425 3.6-.475 4.658-2.281 1.23-2.099.785-4.63-1-5.646v.008Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M23.681 21.39c0-1.324 1.089-2.392 2.422-2.392 1.333 0 2.421 1.075 2.421 2.391M9.19 21.39c0-1.324 1.088-2.392 2.421-2.392 1.333 0 2.421 1.075 2.421 2.391M23.755 3.16a3.653 3.653 0 0 1 .74 5.154c-1.236 1.624-3.568 1.953-5.22.732"
        />
        <path
          fill="#DA3A9A"
          stroke="#1D1E20"
          d="M24.251 23.25h4.777c.39 0 .692.308.692.673a.683.683 0 0 1-.692.674H24.25a.683.683 0 0 1-.692-.674c0-.364.303-.673.692-.673Zm-15.439 0h4.776c.39 0 .692.308.692.673a.683.683 0 0 1-.692.674H8.812a.683.683 0 0 1-.692-.674c0-.364.302-.673.692-.673Z"
        />
        <path
          fill="#6B33EC"
          d="M40.55 21.272s-2.807-.446-3.599 3.723l3.599-3.723Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M40.55 21.272s-2.807-.446-3.599 3.723"
        />
        <path
          fill="#6B33EC"
          d="M38.75 24.987s.519-1.477-.333-2.99l.334 2.99Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M38.75 24.987s.519-1.477-.333-2.99m-23.681 6.135s4.947 2.252 9.279-.344"
        />
      </g>
      {/* <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h46v44H0z" />
        </clipPath>
      </defs> */}
    </svg>
  );
}

import { Link } from "react-router-dom";
import { useAppSelector } from "../store";

export function AccountWarnings(props: {}) {
    const accounts = useAppSelector((state) => state.app.userData.accounts);

    const problems = [];
    // const problems = accounts.map(acct => {
    //     if (acct.monthlyTopup === 0 && (acct.type === "saving")) {
    //         return {
    //             account: acct,
    //             message: "Please enter your monthly contribution"
    //         }
    //     } else if (acct.monthlyTopup == undefined && (acct.type === "debt")) {
    //         return {
    //             account: acct,
    //             message: "Please enter your monthly payment"
    //         }
    //     }
    // }).filter(x => !!x)

    if (problems.length == 0) {
        return <></>
    }

    return (
        <div className="my-2">
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Warning!</strong>
                <span className="block sm:inline"> You have {problems.length} problems with your accounts.</span>
                <ul className="list-disc list-inside">
                    {problems.map((p, i) => <li key={i}>
                        <Link to={`/account/${p.account.id}`}>
                            <div>
                                {/* account title */}
                                <h3 className="text-sm font-medium text-white">
                                    {p.account.name}
                                </h3>
                                <p className="text-sm text-white">
                                    {p.message}
                                    </p>
                            </div>
                            </Link>

                        </li>)}
                </ul>
                </div>
        </div>
    )
    
}
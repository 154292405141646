import { Menu, Transition } from "@headlessui/react";
import { Bars2Icon, Bars3Icon } from "@heroicons/react/24/solid";
import { CurrencyDollarIcon, PencilIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import moment from "moment";
import { Fragment } from "react";
import { useModalContext } from "../context/ModalContext";
import { formatCurrency } from "../helpers";
import { appActions, useAppDispatch, useAppSelector } from "../store";
import { ChangeIndicator } from "./ChangeIndicator";
import { DataPoint } from "./core/DataPoint";
import { TopiaDataList } from "./core/TopiaDataList";
import { IncomeForm } from "./IncomeForm";
import { IncomeFuturePopup } from "./IncomeFuturePopup";
import { LifeEventForm } from "./LifeEventForm";

export function IncomeList(props: {}) {
  const modalCtx = useModalContext();

  const oldIncomes = useAppSelector(
    (state) => state.app.userData.user?.incomes
  );

  const tinkerIncomes = useAppSelector(
    (state) => state.app.tinkerData?.user?.incomes
  );

  const incomes = tinkerIncomes || oldIncomes || [];

  const appDispatch = useAppDispatch();

  return (
    <TopiaDataList
      data={incomes}
      getLabel={(inc) => inc.name}
      editableField="amount"
      suffix={<p className="ml-2 text-topia-gray">/ month</p>}
      childrenField="future"
      onFinishEditing={(inc, val) => {
        appDispatch(
          appActions.updateTinkerUser({
            incomes: incomes.map((i) => ({
              ...(i.id === inc.id ? { ...i, amount: val } : i),
            })),
          })
        );
      }}
      sourceData={oldIncomes}
      childrenLabel="Planned Changes"
      renderChild={({ parent: inc, child: futInc }) => (
        <div
          className="flex w-full flex-1 items-center align-center justify-center"
          key={futInc.id}
        >
          <p className="ml-2 text-white text-md">
            {moment(futInc.from, "YYYY-MM-DD").format("YYYY-MM")}
          </p>
          <div className="flex-1"></div>
          <p
            className={classNames(
              "text-white text-md",
              futInc.amount > inc.amount ? "text-green-500" : "text-red-500"
            )}
          >
            {formatCurrency(futInc.amount)}
          </p>
          <p className="ml-2 text-white">/ month</p>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center px-4 py-1 text-sm font-medium text-white hover:bg-topia-gray focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100 ml-4">
                {/* Options
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}
                <Bars3Icon className=" w-6 h-6 text-white" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-black focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          modalCtx.openModal(
                            <IncomeFuturePopup
                              income={inc}
                              futureId={futInc.id}
                              onSubmit={(result) => {
                                appDispatch(
                                  appActions.updateTinkerUser({
                                    incomes: incomes.map((i) => ({
                                      ...(i.id === inc.id
                                        ? {
                                            ...inc,
                                            future: i.future.map(
                                              (f) =>
                                                (f.id === result.id
                                                  ? result
                                                  : f) as any
                                            ),
                                          }
                                        : i),
                                    })),
                                  })
                                );
                                modalCtx.closeModal();
                              }}
                            />
                          );
                        }}
                        className={`block px-4 py-2 text-sm cursor-pointer ${
                          active
                            ? "bg-pale-yellow text-topia-black"
                            : "text-white"
                        }`}
                        // {classNames(
                        //   active ? "bg-gray-800 text-white" : "text-white",
                        //   "block px-4 py-2 text-sm "
                        // )}
                      >
                        Edit
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          appDispatch(
                            appActions.updateTinkerUser({
                              incomes: incomes.map((i) => ({
                                ...(i.id === inc.id
                                  ? {
                                      ...inc,
                                      future: i.future.filter(
                                        (f) => f.id !== futInc.id
                                      ),
                                    }
                                  : i),
                              })),
                            })
                          );
                        }}
                        className={`block px-4 py-2 text-sm text-red-500 cursor-pointer ${
                          active ? "bg-pale-yellow" : ""
                        }`}
                        // {classNames(
                        //   active ? "bg-gray-800 text-white" : "text-white",
                        //   "block px-4 py-2 text-sm"
                        // )}
                      >
                        Delete
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      )}
      submenu={() => [
        {
          body: <p>Edit</p>,
          onClick: (inc) => {
            modalCtx.openModal(
              <IncomeForm
                income={inc}
                onSubmit={(result) => {
                  appDispatch(
                    appActions.updateTinkerUser({
                      incomes: incomes.map((i) => ({
                        ...(i.id === inc.id ? result : i),
                      })),
                    })
                  );
                  modalCtx.closeModal();
                }}
              />
            );
          },
        },
        {
          body: <p>Plan a Change</p>,
          onClick: (inc) => {
            modalCtx.openModal(
              <IncomeFuturePopup
                income={inc}
                onSubmit={(result) => {
                  appDispatch(
                    appActions.updateTinkerUser({
                      incomes: incomes.map((i) => ({
                        ...(i.id === inc.id
                          ? {
                              ...inc,
                              future: [...(inc.future || []), result],
                            }
                          : i),
                      })),
                    })
                  );
                  modalCtx.closeModal();
                }}
              />
            );
          },
        },
        {
          onClick: (inc) => {
            console.log(inc);
            modalCtx.prompt({
              title: `Are you sure you want to delete this income?`,
              actions: [
                {
                  label: `Delete ${inc.name}`,
                  onClick: () => {
                    appDispatch(
                      appActions.updateTinkerUser({
                        incomes: incomes.filter((i) => i.id !== inc.id),
                      })
                    );
                    modalCtx.closeModal();
                  },
                },
              ],
              body: <p className="text-gray-500"></p>,
            });
          },
          body: <p className="text-red-500">Delete</p>,
        },
      ]}
    />
  );
}

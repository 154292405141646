import { Fragment, useEffect, useState } from "react";
import { AccountList } from "../components/AccountList";
import { TopiaButton } from "../components/core/TopiaButton";
import { Card } from "../components/core/Card";
import { DashboardChart } from "../components/DashboardChart";
import { FIBaseSettings } from "../components/FIBaseSettings";
import { IncomeList } from "../components/IncomeList";
import { FIStats } from "../components/FIStats";
import { useDataContext } from "../context/DataContext";
import { Link, useNavigate } from "react-router-dom";
import {
  formatCurrency,
  getAccountsByType,
  getLastMonthTopup,
} from "../helpers";
import { useAppSelector } from "../store";
import { ACCOUNT_TYPES } from "../enums";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useModalContext } from "../context/ModalContext";
import classNames from "classnames";
import { SurplusWarning } from "../components/SurplusWarning";
import { trackAnalyticsEvent } from "../analytics";

export function PortfolioPage(props: {}) {
  const accounts = useAppSelector((state) => state.app.userData.accounts);
  const modalCtx = useModalContext();
  const dataCtx = useDataContext();
  const netWorth = useAppSelector(
    (state) => state.app.userData.modelResult?.netWorthInfo?.netWorth
  );
  const navigate = useNavigate();

  useEffect(() => {
    trackAnalyticsEvent("portfolio_page_visited");
  }, []);

  const accountsByType = getAccountsByType(accounts);

  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <div className="mb-4 flex w-full items-center justify-center">
        <h3 className="text-2xl text-white">Portfolio</h3>
        <div className="flex-1"></div>
        <p className="text-md flex w-full items-center ml-4">
          <span className="text-white text-md mr-3">Net Worth:</span>
          <strong
            className="px-2 py-1 text-lg text-white"
            style={{ background: "#0000ff" }}
          >
            {formatCurrency(netWorth)}
          </strong>
        </p>
      </div>

      <Card>
        <div className="py-4">
          <div className="flex w-full">
            <Link
              to="/add-account"
              type="button"
              className="block w-full flex-1"
            >
              <TopiaButton
                linkTo="/add-account"
                className="w-full"
                btnStyle="future"
              >
                Add Account
              </TopiaButton>
            </Link>
          </div>
        </div>
        <div className="px-4 py-2">
          <ul role="list" className="">
            {accountsByType.map((at) => (
              <li key={at.type} className="mb-4 mt-4">
                <p className="mt-2 mb-1 text-lg border-b border-gray-200 text-white">
                  {at.type.toLocaleUpperCase()}
                </p>
                {at.accounts.length > 0 ? (
                  <table className="w-full">
                    <thead>
                      <tr className="text-white text-sm truncate">
                        <td className="pb-2">Account Name</td>
                        <td className="pb-2 pr-5">Monthly Top-up</td>
                        <td className="pb-2 pr-5">Last month change</td>
                        <td className="pb-2">Current balance</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {at.accounts.map((acct) => (
                        <tr key={acct.id} className="" onClick={() => {}}>
                          {/* <Link
                          to={`/account/${acct.id}`}
                          className="hover:bg-cyan-100 active:bg-cyan-200 active:ring-2 ring-cyan-400 py-1 cursor-pointer block"
                        > */}
                          <td className="pb-2 w-1/3">
                            <p className="text-sm font-medium text-white">
                              {acct.name}
                            </p>
                            <p className="text-xs font-medium text-white">
                              {acct.subType}
                            </p>
                          </td>
                          <td className="pb-2 w-1/6">
                            <p className="text-sm text-white ml-2">
                              {formatCurrency(acct.monthlyTopup)}
                            </p>
                          </td>
                          <td className="pb-2 w-1/6">
                            <p className="text-sm text-green-500 ml-2 ">
                              {formatCurrency(getLastMonthTopup(acct))}
                            </p>
                          </td>

                          <td className="pb-2 w-1/6">
                            <p className="text-sm text-white ml-2 ">
                              {formatCurrency(acct.amount)}
                            </p>
                          </td>
                          <td>
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-white hover:bg-topia-gray focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                  {/* Options
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}
                                  <Bars3Icon className="w-6 h-6 text-white" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right focus:outline-none bg-black">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          onClick={() => {
                                            navigate("/account/" + acct.id);
                                          }}
                                          className={classNames(
                                            active
                                              ? "bg-pale-yellow text-black cursor-pointer"
                                              : "text-white",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          Edit
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          onClick={() => {
                                            modalCtx.prompt({
                                              title: `Are you sure you want to delete your account?`,
                                              body: (
                                                <p className="text-white">
                                                  {acct.name} and all associated
                                                  data will be removed from your
                                                  Topia account forever
                                                </p>
                                              ),
                                              actions: [
                                                {
                                                  label: "Yes",
                                                  onClick: async () =>
                                                    await dataCtx.operations.removeAccount(
                                                      { id: acct.id }
                                                    ),
                                                },
                                                {
                                                  label: "Cancel",
                                                  onClick: () => {
                                                    modalCtx.closeModal();
                                                  },
                                                },
                                              ],
                                            });
                                          }}
                                          className={classNames(
                                            active
                                              ? "bg-pale-yellow text-black cursor-pointer"
                                              : "text-white",
                                            "block w-full px-4 py-2 text-left text-sm"
                                          )}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </td>
                          {/* </Link> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-sm text-white mt-2">No accounts</p>
                )}
              </li>
            ))}
          </ul>
        </div>
        <SurplusWarning className="my-4 bg-red" />
      </Card>
    </div>
  );
}

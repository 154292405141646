import {
  signInWithEmailAndPassword,
  UserCredential,
  signOut as firebaseSignOut,
  updateCurrentUser,
  GoogleAuthProvider,
  updateProfile,
  signInWithPopup,
  signInWithRedirect,
  OAuthProvider,
} from "@firebase/auth";
import { signInWithCustomToken } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { store, useAppDispatch } from "./store";
import mixpanel from "mixpanel-browser";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import * as Sentry from "@sentry/react";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

interface AuthContextType {
  user: UserCredential["user"] | null;
  signIn(input: { email: string; password: string }): Promise<void>;
  signOut(): void;
  signInWithApple();
  signUpWithEmail(
    email: string,
    password: string,
    firstName?: string
  ): Promise<{
    user: UserCredential["user"];
  }>;
  signInWithGoogle(): Promise<{
    user: UserCredential["user"];
    isNewUser: boolean;
  }>;
  hasLocalCreds: boolean;
  initialLoading: boolean;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
const start = Date.now();

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<UserCredential["user"] | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const appDispatch = useAppDispatch();

  useEffect(() => {
    return auth.onAuthStateChanged(
      (user) => {
        // console.log("AUTH STATE CHANGED", user);

        if (user && user.uid) {
          try {
            mixpanel.identify(user.uid);
          } catch (ex) {
            console.log("Mixpanel identify error");
            console.error(ex);
          }

          try {
            Sentry.setUser({
              id: user.uid,
              email: user.email,
              username: user.displayName,
            });
          } catch (ex) {
            console.log("Sentry setUser error");
            console.error(ex);
          }

          try {
            //@ts-ignore
            window.Intercom("boot", {
              email: user.email,
              name: user.displayName,
              user_id: user.uid,
              api_base: "https://api-iam.intercom.io",
              app_id: "av542vnr",
              custom_launcher_selector: ".intercom-launcher",
            });
          } catch (ex) {
            console.log("Intercom boot error");
            console.error(ex);
          }

          try {
            // @ts-ignore
            smartlook("identify", user.uid);
          } catch (ex) {
            console.log("Smartlook identify error");
            console.error(ex);
          }

          global.topiaId = user.uid;

          setUser(user);
        }
      },
      (err) => {
        // console.log("AUTH ERROR");
        console.error(err);
        signOut();
      }
    );
  }, []);

  useEffect(() => {
    const t = setTimeout(() => {
      setInitialLoading(false);
    }, 500);

    return () => {
      clearTimeout(t);
    };
  }, []);

  async function signIn(input: { email: string; password: string }) {
    const user = await signInWithEmailAndPassword(
      auth,
      input.email,
      input.password
    );
    setUser(user.user);
  }

  async function signInWithApple() {
    const result = await signInWithPopup(auth, appleProvider);
    let isNewUser = false;

    if (result.user) {
      setUser(result.user);

      isNewUser = await confirmLoggedInUserIsExisting(result.user);

      if (result.user.displayName) {
        await updateProfile(result.user, {
          displayName: result.user.displayName,
        });
      }
    }

    return {
      isNewUser,
      user: result.user,
    };
  }

  async function confirmLoggedInUserIsExisting(
    user: UserCredential["user"]
  ): Promise<boolean> {
    const existingUser = await getDoc(doc(collection(db, "users"), user.uid));
    const isNewUser = !existingUser.exists();
    if (isNewUser) {
      await setDoc(doc(collection(db, "users"), user.uid), {});
      // navigate("/onboarding");
    }

    return isNewUser;
  }

  async function signInWithGoogle() {
    const result = await signInWithPopup(auth, googleProvider);
    let isNewUser = false;

    if (result.user) {
      setUser(result.user);

      isNewUser = await confirmLoggedInUserIsExisting(result.user);

      if (result.user.displayName) {
        await updateProfile(result.user, {
          displayName: result.user.displayName,
        });
      }
    }

    return {
      isNewUser,
      user: result.user,
    };
  }

  async function signUpWithEmail(
    email: string,
    password: string,
    firstName?: string
  ) {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    setUser(result.user);
    await updateProfile(result.user, {
      displayName: firstName,
    });
    return { user: result.user };
  }

  async function signOut() {
    // console.log("AUTH signOut");

    setUser(null);
    await firebaseSignOut(auth);
  }

  const hasLocalCreds = !!auth.currentUser;

  // console.log("AUTH hasLocalCreds", hasLocalCreds);
  // console.log("AUTH currentUser", auth.currentUser);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        hasLocalCreds,
        initialLoading,
        signUpWithEmail,
        signInWithGoogle,
        signInWithApple,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);

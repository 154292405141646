import {
  DateYMDString,
  Income,
  LifeEvent,
} from "@byundefined/topia-model/lib/commonTypes";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useModalContext } from "../context/ModalContext";
import { dateToDashStringYMD, uuidv4 } from "../helpers";
import { appActions, useAppDispatch } from "../store";
import { TopiaButton } from "./core/TopiaButton";
import { TopiaCurrencyInput } from "./core/TopiaInput";
import { TopiaDateInput } from "./core/TopiaDateInput";
import { LifeEventForm } from "./LifeEventForm";
import { format } from "date-fns";
export function IncomeFuturePopup(props: {
  income: Income;
  futureId?: string;
  onSubmit: (vals: Income) => void;
}) {
  const [lifeEvent, setLifeEvent] = useState<Partial<LifeEvent> | undefined>();
  const appDispatch = useAppDispatch();
  const modalCtx = useModalContext();
  const form = useForm();

  let future;
  if (props.futureId) {
    future = props.income.future.find((f) => f.id === props.futureId);
  }
  console.log("Chad", future);
  const [loading, setLoading] = useState(false);

  async function onSubmit(vals) {
    setLoading(true);
    await props.onSubmit({
      amount: Number(vals.amount),
      from: moment(vals.from).format("YYYY-MM-DD") as DateYMDString,
      id: props.futureId || uuidv4(),
      name: props.income.name + " (future)",
      type: props.income.type,
    });
    modalCtx.closeModal();
    setLoading(false);

    // TODO: Error handling
  }

  return (
    <div className="">
      <form onSubmit={form.handleSubmit(onSubmit)} className="min-h-[50vh]">
        <TopiaCurrencyInput
          id={"future-income"}
          label="Future monthly net income"
          inputProps={form.register("amount", {
            value: future?.amount || props.income.amount + 1000,
            required: true,
          })}
        />
        <TopiaDateInput
          id="future-income"
          label="Effective date"
          control={form.control}
          // inputProps={form.register("from", {
          //   // value: moment(new Date(), "YYYY-MM").toDate(),
          //   // value: moment(props.income.from, "YYYY-MM-DD").toDate(),
          //   // value: moment(props.income.from).add(1, "years").format("YYYY-MM"),

          //   required: true,
          // })}
          controllerProps={{
            name: "from",
            defaultValue: future?.from
              ? moment(future.from).toDate()
              : moment(new Date()).add(1, "years").toDate(),
            rules: {
              required: true,
              value: moment(props.income.from, "YYYY-MM-DD").toDate(),
            },
          }}
          datePickerProps={{
            showMonthYearPicker: true,
          }}
        />
        <TopiaButton type="submit" className="mt-2">
          Submit
        </TopiaButton>
      </form>
    </div>
  );
}

import classNames from "classnames";
import { BASE_INPUT_CLASSES } from "./TopiaInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Control, Controller, FieldValues } from "react-hook-form";
import { format } from "date-fns";
export function TopiaDateInput(props: {
  className?: string;
  type?: "month";
  id: string;
  label: string;
  control?: Control<FieldValues, any>;
  datePickerProps?: any;
  controllerProps?: Omit<
    Parameters<typeof Controller>[0],
    "render" | "control"
  >;
}) {
  let actualType: "date" | "month" = "date";
  if (props.type) actualType = props.type;

  return (
    <div className={classNames(props.className)}>
      <label
        htmlFor={props.id}
        className="block text-sm font-medium text-topia-gray"
      >
        {props.label}
      </label>
      <div className="mt-1">
        {/* <input
          {...props.inputProps}
          type={actualType}
          id={props.id}
          className={BASE_INPUT_CLASSES}
        /> */}
        <Controller
          control={props.control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              id={props.id}
              className={BASE_INPUT_CLASSES}
              onChange={(date) => {
                onChange(date);
              }}
              selected={value}
              showYearDropdown
              dropdownMode="select"
              showMonthDropdown
              {...props.datePickerProps}
            />
          )}
          {...props.controllerProps}
        />
      </div>
    </div>
  );
}

import {
  Account,
  DateYMDString,
} from "@byundefined/topia-model/lib/commonTypes";
import {
  ACCOUNT_SUBTYPES,
  ACCOUNT_TYPES,
} from "@byundefined/topia-model/lib/constants/accountConstants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  DEBT_OPTIONS,
  SAVING_OPTIONS,
  SAVING_OPTIONS_US,
  TAX_STATUSES,
} from "../enums";
import { toFixedPrecision } from "../helpers";
import { TopiaCurrencyInput } from "./core/TopiaInput";
import { TopiaDateInput } from "./core/TopiaDateInput";
import { TopiaSelect } from "./core/TopiaSelect";
import { TopiaTextInput } from "./core/TopiaInput";
import { TopiaToggle } from "./core/TopiaToggle";
import { TopiaButton } from "./core/TopiaButton";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type AccountFieldType =
  | "name"
  | "amount"
  | "monthlyTopup"
  | "interestRate"
  | "paidOffEachMonth"
  | "annualReturn"
  | "isCheckingToggle"
  | "subType"
  | "taxStatus"
  | "managementCharge"
  | "dateAccessed"
  | "property"
  | "withdrawalRate"
  | "from";

const FIELDS: Record<ACCOUNT_SUBTYPES, AccountFieldType[]> = {
  employer_pension: [
    "name",
    "subType",
    "taxStatus",
    "amount",
    "monthlyTopup",
    "annualReturn",
    "managementCharge",
    "withdrawalRate",
  ],
  self_invested_pension: [
    "name",
    "subType",
    "taxStatus",
    "amount",
    "monthlyTopup",
    "annualReturn",
    "managementCharge",
    "withdrawalRate",
  ],
  defined_benefits_pension: [
    "name",
    "subType",
    "taxStatus",
    "dateAccessed",
    "monthlyTopup",
  ],
  state_pension: [
    "name",
    "subType",
    "taxStatus",
    "dateAccessed",
    "monthlyTopup",
  ],
  stocks_shares_isa: [
    "name",
    "subType",
    "taxStatus",
    "amount",
    "monthlyTopup",
    "annualReturn",
    "managementCharge",
    "withdrawalRate",
  ],
  cash_isa: [
    "name",
    "subType",
    "taxStatus",
    "amount",
    "monthlyTopup",
    "annualReturn",
    "managementCharge",
    "withdrawalRate",
  ],
  other: [
    "name",
    "subType",
    "taxStatus",
    "amount",
    "monthlyTopup",
    "annualReturn",
    "managementCharge",
    "withdrawalRate",
  ],
  cash: [
    "name",
    "taxStatus",
    "amount",
    "monthlyTopup",
    "annualReturn",
    "managementCharge",
    "withdrawalRate",
  ],
  credit_card: [
    "name",
    "subType",
    "amount",
    "interestRate",
    "monthlyTopup",
    "paidOffEachMonth",
  ],
  debt_other: ["name", "subType", "amount", "interestRate", "monthlyTopup"],
  debt: [
    "name",
    "subType",
    "amount",
    "interestRate",
    "monthlyTopup",
    "paidOffEachMonth",
  ],
  mortgage: [
    "name",
    "subType",
    "amount",
    "interestRate",
    "monthlyTopup",
    "property",
  ],
  lisa: ["name", "subType", "amount", "monthlyTopup", "annualReturn"],
  emergency_fund: ["name", "subType", "amount"],
  short_term_saving: [
    "name",
    "subType",
    "amount",
    "monthlyTopup",
    "annualReturn",
  ],

  property_valuation: [
    "name",
    "subType",
    "amount",
    "monthlyTopup",
    "annualReturn",
  ],
};

export function AccountForm(props: {
  account: Partial<Account>;
  onSubmit: (inc: Account) => void;
  isFuture?: boolean;
}) {
  const form = useForm();
  const isUS = global.region === "us";
  const acct = props.account;
  const formValues = form.watch();

  // useEffect(() => {
  //   for (let k in props.account) {
  //     form.setValue(k, props.account[k]);
  //   }
  // }, [props.account]);

  // Chad code here
  const [loading, setLoading] = useState(false);

  function onSubmit(values) {
    setLoading(true);
    // @ts-ignore

    const newAccount: Account = {
      ...props.account,
      amount: Number(values.amount),
      annualReturn: Number(values.annualReturn),
      monthlyTopup: Number(values.monthlyTopup),
      name: values.name,
      paidOffEachMonth: values.paidOffEachMonth,
      subType: values.subType,
      category: props.account?.category || "other",
      id: props.account?.id,
      managementCharge: Number(values.managementCharge || 0),
      withdrawalRate: Number(values.withdrawalRate || 4),
      type: values.type || props.account.type || "saving",
      dateAccessed: moment(values.dateAccessed)
        .startOf("month")
        .format("YYYY-MM-DD") as DateYMDString,
    };

    if (Object.keys(values).find((k) => k.includes("property_"))) {
      newAccount.property = {
        ...(props.account?.property || {}),
        valueOfProperty: Number(values["property_valueOfProperty"]),
        includeHomeEquity: values["property_includeHomeEquity"],
        appreciationValue: Number(values["property_appreciationValue"]),
        monthlyIncome: Number(values["property_monthlyIncome"]),
      };
    }

    if (values.from) {
      newAccount.from = moment(values.from).format(
        "YYYY-MM-DD"
      ) as DateYMDString;
    }
    console.log(
      "Chad - Value being passed in Acc Form",
      values.dateAccessed,
      typeof values.dateAccessed
    );

    props.onSubmit(newAccount);
  }

  console.log(
    "Chad - test",
    props.account.dateAccessed,
    typeof props.account.dateAccessed,
    moment(props.account.dateAccessed, "YYYY-MM-DD").toDate()
  );

  const acctSubType: ACCOUNT_SUBTYPES =
    formValues.subType || props.account.subType || "other";
  const acctType: ACCOUNT_TYPES =
    formValues.type || props.account.type || "saving";

  const fields = [...FIELDS[acctSubType]];
  console.log("fields", fields);

  if (props.isFuture) {
    fields.push("from");
  }

  // const displayCheckingToggle =
  //   (!acct.userGenerated && acctType === ACCOUNT_TYPES.SAVING) ||
  //   acctType === ACCOUNT_TYPES.STANDARD;

  // if (displayCheckingToggle) {
  //   fields.push("isCheckingToggle");
  // }

  const subTypeOptions =
    {
      [ACCOUNT_TYPES.SAVING]: isUS ? SAVING_OPTIONS_US : SAVING_OPTIONS,
      [ACCOUNT_TYPES.DEBT]: DEBT_OPTIONS,
      [ACCOUNT_TYPES.PROPERTY]: DEBT_OPTIONS,
    }[acctType] || [];

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <>
        {fields.map((f) => (
          <div key={f}>
            {f === "name" && (
              <TopiaTextInput
                inputProps={form.register("name", {
                  required: true,
                  value: props.account.name,
                })}
                label="Name"
                id="name"
              />
            )}

            {f === "amount" && !props.account.property && (
              <TopiaCurrencyInput
                inputProps={form.register("amount", {
                  required: true,
                  value: toFixedPrecision(props.account.amount),
                })}
                label="Amount"
                id="amount"
              />
            )}

            {f === "monthlyTopup" && !props.account.property && (
              <TopiaCurrencyInput
                inputProps={form.register("monthlyTopup", {
                  required: true,
                  value: toFixedPrecision(props.account.monthlyTopup),
                })}
                label="Monthly Contribution"
                id="monthlyTopup"
              />
            )}

            {f === "subType" && (
              <TopiaSelect
                inputProps={form.register("subType", {
                  required: true,
                  value: props.account.subType,
                })}
                options={subTypeOptions}
                label={
                  acctType === ACCOUNT_TYPES.SAVING
                    ? "Tax Wrapper"
                    : "Account Type"
                }
                id="subType"
              />
            )}

            {f === "taxStatus" && (
              <TopiaSelect
                inputProps={form.register("taxStatus", {
                  required: true,
                  // TODO: @Siebe does taxStatus need to be added to common types?
                  // @ts-ignore
                  value: props.account.taxStatus,
                })}
                options={TAX_STATUSES}
                label={"Tax Status"}
                id="taxStatus"
              />
            )}

            {f === "dateAccessed" && (
              <TopiaDateInput
                label="Date Accessed"
                id="dateAccessed"
                control={form.control}
                controllerProps={{
                  name: "dateAccessed",
                  defaultValue: props.account.dateAccessed
                    ? moment(props.account.dateAccessed, "YYYY-MM-DD").toDate()
                    : new Date(),
                  rules: {
                    required: true,
                    value: moment(
                      props.account.dateAccessed,
                      "YYYY-MM-DD"
                    ).toDate(),
                  },
                }}
              />
            )}

            {f === "annualReturn" && (
              <TopiaTextInput
                inputProps={form.register("annualReturn", {
                  required: true,
                  value: props.account.annualReturn,
                })}
                label={"Annual Return"}
                id="annualReturn"
              />
            )}

            {f === "interestRate" && !props.account.property && (
              <TopiaTextInput
                inputProps={form.register("annualReturn", {
                  required: true,
                  value: props.account.annualReturn,
                })}
                label={"Interest Rate"}
                id="interestRate"
              />
            )}

            {f === "managementCharge" && (
              <TopiaTextInput
                inputProps={form.register("managementCharge", {
                  value: props.account.managementCharge,
                })}
                label={"Management Charge"}
                id="managementCharge"
              />
            )}

            {f === "withdrawalRate" && (
              <TopiaTextInput
                inputProps={form.register("withdrawalRate", {
                  required: true,
                  value: props.account.withdrawalRate,
                })}
                label={"Safe Withdrawal Rate"}
                id="withdrawalRate"
              />
            )}

            {f === "isCheckingToggle" && (
              <TopiaToggle
                inputProps={form.register("isCheckingToggle", {
                  required: true,
                })}
                label="Everyday Checking Account"
                id="isCheckingToggle"
              />
            )}

            {f === "paidOffEachMonth" && (
              <TopiaToggle
                inputProps={form.register("paidOffEachMonth", {})}
                label="I pay this debt off in full every month"
                id="paidOffEachMonth"
              />
            )}

            {f === "property" && (
              <div className="">
                <TopiaCurrencyInput
                  inputProps={form.register("amount", {
                    required: true,
                    value: toFixedPrecision(props.account.amount),
                  })}
                  label="Mortgage Amount"
                  id="amount"
                />
                <TopiaTextInput
                  inputProps={form.register("annualReturn", {
                    required: true,
                    value: props.account.annualReturn,
                  })}
                  label={"Interest Rate"}
                  id="interestRate"
                />
                <TopiaCurrencyInput
                  inputProps={form.register("monthlyTopup", {
                    required: true,
                    value: toFixedPrecision(props.account.monthlyTopup),
                  })}
                  label="Monthly Payment"
                  id="monthlyTopup"
                />
                <TopiaCurrencyInput
                  inputProps={form.register("property_valueOfProperty", {
                    required: true,
                    value: toFixedPrecision(
                      props.account.property?.valueOfProperty
                    ),
                  })}
                  label="Property Value"
                  id="propertyValueOfProperty"
                />

                <TopiaTextInput
                  inputProps={form.register("property_appreciationValue", {
                    required: true,
                    value: props.account.property?.appreciationValue,
                  })}
                  label={"Forecasted appreciation rate"}
                  id="propertyAppreciationValue"
                />

                <TopiaCurrencyInput
                  inputProps={form.register("property_monthlyIncome", {
                    required: true,
                    value: toFixedPrecision(
                      props.account.property?.monthlyIncome
                    ),
                  })}
                  label="Monthly Income"
                  id="propertyMonthlyIncome"
                />

                <TopiaToggle
                  inputProps={form.register("property_includeHomeEquity", {
                    value: props.account.property?.includeHomeEquity,
                  })}
                  label="Include home equity in networth calculations"
                  id="paidOffEachMonth"
                />
              </div>
            )}

            {f === "from" && (
              <TopiaDateInput
                control={form.control}
                // inputProps={form.register("from", {
                //   required: true,
                //   value: props.account.from,
                // })}

                controllerProps={{
                  defaultValue: new Date(
                    moment().add(1, "years").add(1, "days").format("YYYY-MM-DD")
                  ),
                  name: "from",
                  rules: {
                    required: true,
                    value: moment(props.account.from, "YYYY-MM-DD").toDate(),
                  },
                }}
                label={"Effective date"}
                id="from"
              />
            )}
          </div>
        ))}
        <TopiaButton loading={loading} type="submit">
          Submit
        </TopiaButton>
        {/* <button
          type="submit"
          className="mt-2 p-2 hover:bg-blue-500 text-white rounded-sm mt-4"
          style={{ background: "blue" }}
        >
          Submit
        </button> */}
      </>
    </form>
  );
}

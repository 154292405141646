export function PencilIllustration(props: {
  className?: string;
  svgProps?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
      className={props.className}
      {...props.svgProps}
    >
      <path
        fill="#FDFFF9"
        d="M17.129 3.646 14.912 1.44a2.016 2.016 0 0 0-1.435-.595c-.537 0-1.055.215-1.436.595l-1.875 1.875 5.078 5.088 1.885-1.875c.38-.39.586-.898.586-1.435 0-.547-.205-1.055-.586-1.446Zm-1.104 1.768-.78.781-2.872-2.88.772-.772a.465.465 0 0 1 .332-.137c.127 0 .244.05.332.137l2.216 2.217a.456.456 0 0 1 .127.332.434.434 0 0 1-.127.322Zm-8.74 8.74-3.223 1.084-.742-.732 1.084-3.223 5.977-5.976-1.104-1.104-6.23 6.24L.459 18.1l7.666-2.579 6.24-6.24-1.103-1.103-5.977 5.976Zm-1.7-1.172a.755.755 0 0 0 .548.235c.205 0 .4-.078.556-.235L12.373 7.3 11.27 6.195 5.586 11.88a.777.777 0 0 0-.234.556c0 .196.078.4.234.547Z"
      />
    </svg>
  );
}

import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Children, Fragment } from "react";
import { formatCurrency } from "../../helpers";
import { DataPoint } from "./DataPoint";

export type DataListDatum = {
  id: any;
};

export function TopiaDataList(props: {
  data: DataListDatum[];
  sourceData?: DataListDatum[];
  editableField?: string;
  childrenField?: string;
  suffix?: any;
  getLabel?: (data: any) => any;
  onFinishEditing?: (data: any, value: string) => void;
  renderRow?: (row: any, matching?: any) => JSX.Element;
  renderChild?: (props: { parent: any; child: any }) => JSX.Element;
  childrenLabel?: string;
  submenu?: (data: any) => {
    onClick: (d: any) => void;
    body: any;
  }[];
}) {
  const submenu = (d: any) => (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100 ml-4">
          {/* Options
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}
          <Bars3Icon className="w-6 h-6 text-white" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.submenu(d).map((s, ix) => (
              <Menu.Item key={ix}>
                {({ active }) => (
                  <a
                    onClick={() => s?.onClick(d)}
                    className={classNames(
                      active ? "bg-pale-yellow text-black cursor-pointer" : "text-white",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {s.body}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );

  return (
    <dd className="text-sm text-white">
      <ul
        role="list"
        className={classNames(
          props.data.length > 1 ? "divide-y divide-white/20" : "",
          "rounded-tl-sm rounded-tr-sm"
        )}
      >
        {props.data.map((d) => {
          const matching = props.sourceData.find((i) => i.id === d.id);

          if (props.renderRow) {
            return (
              <li key={d.id}>
                <div className="flex items-center justify-between py-2 pl-3 pr-4 text-sm ">
                  {props.renderRow(d, matching)}
                  {props.submenu && submenu(d)}
                </div>
              </li>
            );
          }

          return (
            <li key={d.id}>
              <div className="flex items-center justify-between pt-2 pl-3 pr-4 text-sm ">
                <div className="flex w-0 flex-1 items-center align-center justify-center">
                  {/* <CurrencyDollarIcon
                    className="h-5 w-5 flex-shrink-0 text-white"
                    aria-hidden="true"
                  /> */}
                  <p className="mx-2 ml-4">{props.getLabel(d)}</p>
                  <div className="flex-1"></div>

                  {props.editableField && (
                    <div className="flex items-center">
                      <DataPoint
                        changeIndicatorPosition="left"
                        className="ml-4"
                        // label="Amount"
                        ogValue={
                          matching ? matching[props.editableField] : undefined
                        }
                        inverseColors={true}
                        value={d[props.editableField]}
                        type="currency"
                        suffix={props.suffix}
                        editable
                        onFinishEditing={(val) => props.onFinishEditing(d, val)}
                      />
                    </div>
                  )}
                  {/* {matching && (
                    <ChangeIndicator
                      className="ml-4"
                      change={inc.amount - matching?.amount}
                      changeType={
                        matching.amount > inc.amount ? "increase" : "decrease"
                      }
                    />
                  )} */}
                  {props.submenu && submenu(d)}
                  {/* <DataPoint
                  ogValue={matching?.amount}
                  value={inc.amount}
                  type="currency"
                  suffix={<p className="ml-2 text-white text-md">/ month</p>}
                  editable
                  onFinishEditing={(val) => {
                    appDispatch(
                      appActions.updateTinkerUser({
                        incomes: incomes.map((i) =>
                          i.id === inc.id ? { ...i, amount: Number(val) } : i
                        ),
                      })
                    );
                  }}
                /> */}
                  {/* <p className="text-sm text-white truncate ml-2">
                <span className="text-black">
{formatCurrency(inc.amount)}
                  </span> / month
              </p> */}
                  {/* <Bars3Icon className="ml-6 cursor-pointer w-5 h-5 text-white group-hover:text-blue-600" /> */}
                </div>
              </div>
              {props.renderChild &&
                props.childrenField &&
                d[props.childrenField] &&
                d[props.childrenField].length > 0 && (
                  <div className="px-2 p-2 text-sm">
                    {props.childrenLabel && (
                      <p className="text-xs mb-2 mr-2 text-topia-gray ml-4">
                        {props.childrenLabel}
                      </p>
                    )}
                    <div className="px-2">
                      {d[props.childrenField].map((childItem) => (
                        <props.renderChild
                          key={childItem.id}
                          child={childItem}
                          parent={d}
                        />
                      ))}
                    </div>
                  </div>
                )}
            </li>
          );
        })}
      </ul>
    </dd>
  );
}

import { Card } from "./core/Card";
import { CurrencyDollarIcon, PaperClipIcon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector, appActions } from "../store";
import { IncomeForm } from "./IncomeForm";
import { IncomeList } from "./IncomeList";
import { useModalContext } from "../context/ModalContext";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { IncomeIllustration } from "./core/illustrations/IncomeIllustration";
import { TopiaBannerCTA } from "./core/TopiaBannerCTA";

export function IncomeCard(props: {}) {
  const modalCtx = useModalContext();
  const appDispatch = useAppDispatch();
  const appData = useAppSelector(
    (state) => state.app.tinkerData || state.app.userData
  );

  return (
    <div className="flex-1 flex flex-col">
      <p className="text-lg text-white mt-2 px-2 mb-2">Income</p>
      <IncomeList />
      <div className="flex-1"></div>
      <TopiaBannerCTA
        className="mt-4"
        analyticsEvent="add_income_cta_clicked"
        onClick={() => {
          modalCtx.openModal(
            <>
            <h1 className="text-2xl text-white mb-4">Add an income</h1>
            <IncomeForm
              onSubmit={(inc) => {
                appDispatch(
                  appActions.updateTinkerUser({
                    incomes: [...appData.user.incomes, inc],
                  })
                );
                modalCtx.closeModal();
              } } />
            </>
          );
        } }
        illustration={<IncomeIllustration className="" svgProps={{}} />}
        title="Add an Income"
        copy="Increase your income via sidehustles" 
        color={"royal-purple"} />
    </div>
  );
}

import * as d3 from "d3";
import { calculateFullModel } from "@byundefined/topia-model";
import { LifeEvent } from "@byundefined/topia-model/lib/commonTypes";
import { formatCurrency } from "../helpers";
type ModelResult = ReturnType<typeof calculateFullModel>;

export class TopiaScoreCardGraph {
  private modelResult: ModelResult = null as any;

  margin: { top: number; right: number; bottom: number; left: number };
  xScale: d3.ScaleTime<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  vis: d3.Selection<any, unknown, null, undefined>;
  fiNrLabelRect: d3.Selection<SVGRectElement, unknown, null, undefined>;
  width: number;
  height: number;
  valueline: d3.Line<[number, number]>;
  normalResultFill: d3.Selection<SVGPathElement, any, null, undefined>;
  normalResultLine: d3.Selection<SVGPathElement, any, null, undefined>;
  valuearea: d3.Area<[number, number]>;
  yAxis: d3.Selection<SVGGElement, unknown, null, undefined>;
  horizontalLine: d3.Selection<SVGLineElement, unknown, null, undefined>;
  fiNrLabel: d3.Selection<SVGTextElement, unknown, null, undefined>;
  xAxis: d3.Selection<SVGGElement, unknown, null, undefined>;
  completedInitialLoad: boolean;
  lifeEvents: LifeEvent[];
  lifeEventLines: any[];
  horizontalLines: d3.Selection<SVGLineElement, unknown, null, undefined>[];
  inverse: boolean;

  constructor(
    private readonly _svgElement: any,
    private readonly _options: {
      inverse?: boolean;
      fill?: boolean;
    } = {}
  ) {
    // console.log("@@ ctor");
    this.initGraph();
  }

  public initGraph() {
    // console.log("@@ initGraph");
    const bbox = this._svgElement.getBoundingClientRect();
    this.margin = { top: 20, right: 0, bottom: 50, left: 100 };
    const width = bbox.width - this.margin.left - this.margin.right;
    const height = bbox.height - this.margin.top - this.margin.bottom;
    this.width = width;
    this.height = height;

    this.xScale = d3.scaleTime().range([0, width]);
    this.yScale = d3.scaleLinear().range([height, 0]);

    this.vis = d3.select(this._svgElement);
    this.vis.selectAll("*").remove();

    // Add the x Axis
    this.xAxis = this.vis
      .append("g")
      .attr(
        "transform",
        `translate(${this.margin.left}, ${height + this.margin.top})`
      )
      .call(d3.axisBottom(this.xScale).tickSize(0))
      .attr("font-family", "apercu")
      .attr("color", "#9E9E9E")
      .attr("font-size", "16px");

    // Add the y Axis
    this.yAxis = this.vis
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .call(
        d3.axisLeft(this.yScale).tickFormat((d) => {
          const prefix = global.region === "gb" ? "£" : "$";
          const array = ["", "k", "M", "G", "T", "P"];

          let v: any = d;
          let i = 0;
          while (v > 1000) {
            i++;
            v = v / 1000;
          }

          v = v + " " + array[i];

          return v;
          // d3.format(",s")(d)
        })
      )
      .attr("font-family", "apercu")
      .attr("color", "#9E9E9E")
      .attr("font-size", "16px");

    // gray rectangle taking up full visible area
    this.vis
      .append("g")
      .attr(
        "transform",
        `translate(${this.margin.left - 1}, ${this.margin.top + 1})`
      )
      .append("rect")
      .attr("width", width + 80)
      .attr("height", height + 6)
      .attr("fill", this._options.inverse ? "white" : "#1D1E20");

    this.horizontalLines = [0.25, 0.5, 0.75, 1].map((pct) => {
      return this.vis
        .append("g")
        .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
        .append("line")
        .attr("x1", 0)
        .attr("y1", this.yScale(pct))
        .attr("x2", width)
        .attr("y2", this.yScale(pct))
        .attr("stroke", "#9E9E9E")
        .attr("stroke-width", 1);
    });

    this.lifeEventLines = [];

    this.valuearea = d3
      .area()
      .x((d: any) => this.xScale(new Date(`${d.calculatedYear}-01-01`)))
      .y0(height)
      .y1((d: any) => this.yScale(d.total));

    this.valueline = d3
      .line()
      .x((d: any) => this.xScale(new Date(`${d.calculatedYear}-01-01`))) // CHANGED THIS LINE
      .y((d: any) => this.yScale(d.total));

    // Normal result
    this.normalResultFill = this.vis
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .append("path")
      .attr("class", "area")
      .attr("z-index", "100")
      .attr("fill", this._options.fill ? "#75FBDE" : "transparent");

    // Normal result line
    this.normalResultLine = this.vis
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .append("path")
      .attr("class", "line")
      .attr("stroke", this._options.fill ? "#0000DE" : "#75FBDE")
      .attr("stroke-width", 4)
      .attr("fill", "none");

    // Horizontal FINr line
    this.horizontalLine = this.vis
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .append("line")
      .attr("stroke", "white")
      .attr("stroke-dasharray", "10")
      .attr("stroke-width", 2);

    // FINr label
    // this.fiNrLabelRect = this.vis.append("rect").attr("fill", "white");

    this.fiNrLabel = this.vis
      .append("text")
      .attr("fill", "black") // fill color of the text
      .attr("text-anchor", "right");

    // Add the legend
    // this.vis
    //   .append("g")
    //   .attr(
    //     "transform",
    //     `translate(${this.margin.left + 10}, ${this.margin.top})`
    //   )
    //   .append("rect")
    //   .attr("x", 0)
    //   .attr("y", 0)
    //   .attr("width", 10)
    //   .attr("height", 10)
    //   .attr("fill", "#f1f1f1");

    // this.vis
    //   .append("g")
    //   .attr(
    //     "transform",
    //     `translate(${this.margin.left + 10}, ${this.margin.top})`
    //   )
    //   .append("text")
    //   .attr("x", 15)
    //   .attr("y", 10)
    //   .text("FI Timeline");
  }

  modelTimeout: any;

  setModelData(modelResult: ModelResult, lifeEvents: LifeEvent[]) {
    if (this.modelTimeout) {
      clearTimeout(this.modelTimeout);
    }

    this.modelResult = modelResult;
    this.lifeEvents = lifeEvents;

    this.modelTimeout = setTimeout(() => {
      this.updateGraph();
    }, 200);
  }

  updateGraph() {
    // console.log("@@ updateGraph");
    const bbox = this._svgElement.getBoundingClientRect();

    this.margin = { top: 20, right: 0, bottom: 50, left: 100 };

    const width = bbox.width - this.margin.left - this.margin.right;
    const height = bbox.height - this.margin.top - this.margin.bottom;

    this.width = width;
    this.height = height;

    this.xScale = d3.scaleTime().range([0, width]);
    this.yScale = d3.scaleLinear().range([height, 0]);

    const graphData = Object.values(
      this.modelResult.calculatedAccountsYearly || {}
    ).sort((a: any, b: any) => a.calculatedYear - b.calculatedYear);

    this.xScale.domain(
      // @ts-ignore
      d3.extent(graphData, (d) => new Date(`${d.calculatedYear}-01-01`))
    );

    // @ts-ignore
    const yMax = Math.max(d3.max(graphData, (d) => Number(d.total))!) * 1.2;
    this.yScale.domain([0, yMax]);

    const horizontalLineY = this.yScale(this.modelResult.FINr);

    const transitionDuration = this.completedInitialLoad ? 400 : 0;

    // Horizontal FINr line
    this.horizontalLine
      //.transition(d3.transition().duration(transitionDuration))
      .attr("x1", 0)
      .attr("x2", this.width - 0)
      .attr("y1", horizontalLineY)
      .attr("y2", horizontalLineY);

    // // Life Event lines
    // const newLifeEventLines = this.lifeEvents.map(
    //   (le, ix) =>
    //     this.lifeEventLines[ix] || {
    //       label: this.vis
    //         .append("g")
    //         .attr(
    //           "transform",
    //           `translate(${this.margin.left}, ${this.margin.top})`
    //         )
    //         .append("text")
    //         .attr("fill", "black"),
    //       fromLine: this.vis
    //         .append("g")
    //         .attr(
    //           "transform",
    //           `translate(${this.margin.left}, ${this.margin.top})`
    //         )
    //         .append("line"),
    //       toLine: this.vis
    //         .append("g")
    //         .attr(
    //           "transform",
    //           `translate(${this.margin.left}, ${this.margin.top})`
    //         )
    //         .append("line"),
    //     }
    // );

    // this.lifeEventLines = newLifeEventLines;

    // for (let i = 0; i < this.lifeEventLines.length; i++) {
    //   const line = this.lifeEventLines[i];
    //   const le = this.lifeEvents[i];
    //   const fromX = this.xScale(new Date(`${le.from}-01`));
    //   // @ts-ignore
    //   line.label
    //     .attr("x", fromX + 30)
    //     // @ts-ignore
    //     .text(le.icon);

    //   line.fromLine
    //     .attr("stroke", "#dedede")
    //     .attr("stroke-width", 1)
    //     .attr("x1", fromX)
    //     .attr("x2", fromX)
    //     .attr("y1", 0)
    //     .attr("y2", this.height);

    //   if (le.to) {
    //     const x = this.xScale(new Date(`${le.to}-01`));
    //     line.toLine
    //       .attr("stroke", "black")
    //       .attr("stroke-width", 2)
    //       .attr("x1", x)
    //       .attr("x2", x)
    //       .attr("y1", 0)
    //       .attr("y2", this.height);
    //   }
    // }
    // console.log(this.lifeEventLines);

    // FINr label
    const finrLabelLen = this.width * 0.25;
    // this.fiNrLabelRect
    //   .transition(d3.transition().duration(transitionDuration))
    //   .attr("x", this.width - this.margin.right - 170 - finrLabelLen) // x-coordinate of the text
    //   .attr("y", horizontalLineY + 7) // y-coordinate of the text
    //   .attr("width", finrLabelLen * 1.68 - 50)
    //   .attr("height", 24);

    this.fiNrLabel
      .transition(d3.transition().duration(transitionDuration))
      .attr("x", this.width - this.margin.right - finrLabelLen - 150) // x-coordinate of the text
      .attr("y", horizontalLineY + 25) // y-coordinate of the text
      // .text("FI Number: " + formatCurrency(this.modelResult.FINr))
      .attr("font-family", "apercu")
      .attr("font-size", "18px");

    // Normal result
    this.normalResultFill
      .data([graphData])
      .attr("fill", this._options.fill ? "#75FBDE" : "transparent")
      // .transition(d3.transition().duration(transitionDuration))
      //   @ts-ignore
      .attr("d", this.valuearea);

    // Normal result line
    this.normalResultLine
      .data([graphData])
      // .transition(d3.transition().duration(transitionDuration))
      //   @ts-ignore
      .attr("d", this.valueline);

    this.xAxis
      .transition(d3.transition().duration(transitionDuration))
      .call(d3.axisBottom(this.xScale).ticks(8));

    this.yAxis
      .transition(d3.transition().duration(transitionDuration))
      .attr("stroke", "none")
      // dont show ticks along the y axis

      .call(
        d3
          .axisLeft(this.yScale)
          .tickFormat((d) => {
            const prefix = global.region === "gb" ? "£" : "$";
            const array = ["", "k", "M", "G", "T", "P"];

            let v: any = d;
            let i = 0;
            while (v > 1000) {
              i++;
              v = v / 1000;
            }

            v = v + " " + array[i];

            return v;
            // d3.format(",s")(d)
          })
          .tickFormat(
            global.region === "gb"
              ? (d) => "£" + d3.format(",d")(d)
              : (d) => "$" + d3.format(",d")(d)
          )
          .tickSize(0)
      );

    this.completedInitialLoad = true;
  }
}

import { XCircleIcon } from "@heroicons/react/24/outline";
import { updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { trackAnalyticsEvent } from "../analytics";
import { useAuthContext } from "../auth";
import { useDataContext } from "../context/DataContext";
import { useAppSelector } from "../store";
import { TopiaButton } from "./core/TopiaButton";
import { IntercomIllustration } from "./core/illustrations/IntercomIllustration";

export function WelcomeToTopiaModal(props: {}) {
  const navigate =useNavigate();
  const authCtx = useAuthContext();
  const dataCtx = useDataContext();
  const firstName = authCtx.user?.displayName;

  useEffect(() => {
    trackAnalyticsEvent("welcome_to_topia_popup_shown");
  }, [])

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-future-blue/80 z-10 flex items-center align-center justify-center text-center">
      <div className="relative flex flex-col bg-white rounded-2xl p-8 w-11/12 lg:w-5/12">
        <div className="absolute top-6 right-6 cursor-pointer" onClick={() => {
            updateDoc(dataCtx.userRef, {
                hasOnboarded: true
            })
        }}>
          <XCircleIcon className="w-8 h-8 text-black" />
        </div>

        <h1 className="text-4xl">
          Welcome to
          <br />
          Topia {firstName}
        </h1>
        <p className="text-md mt-4 font-light">
          Congratulations on taking this step towards your financial freedom!
          <br />
          Start tracking your progress to FI by adding your accounts to your
          portfolio.
        </p>
        <TopiaButton btnStyle="future" className="mx-auto my-8" onClick={() => {
            updateDoc(dataCtx.userRef, {
                hasOnboarded: true
            })
            navigate("/add-account")
        }}>
          Add your accounts
        </TopiaButton>
        <div className="rounded-xl bg-pale-yellow p-4 flex h-full flex items-center align-center justify-center intercom-launcher">
          <div className="flex items-center justify-center align-center h-full mr-4">
            <IntercomIllustration />
          </div>
          <p className="text-topia-black text-xs">
            If you need help getting set, or you have any questions about your
            settings, click on the chat icon in the bottom right of the screen.
          </p>
        </div>
      </div>
    </div>
  );
}

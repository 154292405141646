import {
  Account,
  DateYMDString,
  Income,
  LifeEvent,
} from "@byundefined/topia-model/lib/commonTypes";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toFixedPrecision, uuidv4 } from "../helpers";
import { TopiaCurrencyInput } from "./core/TopiaInput";
import { TopiaDateInput } from "./core/TopiaDateInput";
import { TopiaSelect } from "./core/TopiaSelect";
import { TopiaTextInput } from "./core/TopiaInput";
import moment from "moment";
import { TopiaButton } from "./core/TopiaButton";

export function LifeEventForm(props: {
  lifeEvent: Partial<LifeEvent>;
  accounts?: Account[];
  onSubmit: (inc: LifeEvent) => void | Promise<void>;
}) {
  const form = useForm();

  async function onSubmit(values) {
    setLoading(true);
    const le: LifeEvent = {
      id: props.lifeEvent.id || uuidv4(),
      name: values.name,
      asset: values.asset,
      type: values.type,
      amount: Number(values.amount),

      from: values.from
        ? (moment(values.from, "YYYY-MM-DD").format("YYYY-MM-DD") as any)
        : undefined,
      // ? (moment(values.from).format("YYYY-MM-DD") as any)
      // : undefined,
      to: values.to
        ? (moment(values.to).format("YYYY-MM-DD") as any)
        : undefined,
      increasedIncome: values.increasedIncome
        ? Number(values.increasedIncome)
        : undefined,
      increasedSpend: values.increasedSpend
        ? Number(values.increasedSpend)
        : undefined,
      recurringTime: values.recurringTime,
      downPayment: values.downPayment ? Number(values.downPayment) : undefined,
    };

    await props.onSubmit(le);
    // setLoading(false)
  }

  const [loading, setLoading] = useState(false);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <TopiaTextInput
        inputProps={form.register("name", {
          required: true,
          value: props.lifeEvent.name,
        })}
        className="my-2"
        label="Name"
        id="name"
      />
      {props.accounts && props.accounts.length > 0 && (
        <TopiaSelect
          inputProps={form.register("asset", {
            required: true,
            value: props.lifeEvent.asset,
          })}
          className="my-2"
          label="Asset"
          id="le-asset"
          options={props.accounts.map((acc) => ({
            value: acc.id,
            label: acc.name,
          }))}
        />
      )}
      {props.lifeEvent.increasedSpend && (
        <TopiaCurrencyInput
          inputProps={form.register("increasedSpend", {
            required: true,
            value: toFixedPrecision(props.lifeEvent.increasedSpend),
          })}
          className="my-2"
          label="Estimated Expenditure"
          id="increasedSpend"
        />
      )}
      {props.lifeEvent.increasedIncome && (
        <TopiaCurrencyInput
          inputProps={form.register("increasedIncome", {
            required: true,
            value: toFixedPrecision(props.lifeEvent.increasedIncome),
          })}
          className="my-2"
          label="Estimated Income"
          id="increasedSpend"
        />
      )}
      <TopiaDateInput
        control={form.control}
        // inputProps={form.register("from", {
        //   required: true,
        //   value: props.lifeEvent.from,
        // })}

        controllerProps={{
          defaultValue: props.lifeEvent.from
            ? moment(props.lifeEvent.from).toDate()
            : moment(new Date()).toDate(),
          name: "from",
          rules: {
            required: true,
            value: moment(props.lifeEvent.from, "YYYY-MM-DD").toDate(),
          },
        }}
        className="my-2"
        type="month" //TODO: if type is month, then it should automatically remove & add the date
        label={props.lifeEvent.recurringTime ? "From" : "Date"}
        id="le-from"
        datePickerProps={{
          showMonthYearPicker: true,
        }}
      />

      {props.lifeEvent.recurringTime && (
        <>
          <TopiaDateInput
            control={form.control}
            // inputProps={form.register("to", {
            //   required: true,
            //   value: props.lifeEvent.to,
            // })}
            controllerProps={{
              defaultValue: props.lifeEvent.to
                ? moment(props.lifeEvent.to).toDate()
                : moment(new Date()).toDate(),

              name: "to",
              rules: {
                required: true,
                value: moment(props.lifeEvent.to, "YYYY-MM-DD").toDate(),
              },
            }}
            className="my-2"
            type="month"
            label="To"
            id="le-to"
            datePickerProps={{
              showMonthYearPicker: true,
            }}
          />
          <TopiaSelect
            inputProps={form.register("recurringTime", {
              required: true,
              value: props.lifeEvent.recurringTime,
            })}
            className="my-2"
            label="Recurring Frequency"
            id="le-recurring-time"
            options={[
              { value: "monthly", label: "Monthly" },
              { value: "quarterly", label: "Quarterly" },
              { value: "annually", label: "Annually" },
            ]}
          />
        </>
      )}
      <TopiaButton loading={loading} type="submit">
        Submit
      </TopiaButton>
    </form>
  );
}

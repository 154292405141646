import { getMonthlyTakehomeFromIncome } from "@byundefined/topia-model";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../context/ModalContext";
import { formatCurrency } from "../helpers";
import { useAppSelector } from "../store";
import { TopiaBannerCTA } from "./core/TopiaBannerCTA";
import { TopiaButton } from "./core/TopiaButton";
import { BriefcaseIllustration } from "./core/illustrations/BriefcaseIllustration";

export function SurplusWarning(props: { className?: string }) {
  const appData = useAppSelector(
    (state) => state.app.tinkerData || state.app.userData
  );

  const navigate = useNavigate();

  const modalCtx = useModalContext();

  if (!appData?.modelResult?.fullModel) {
    return <></>;
  }

  const activeAccounts = appData.accounts.filter(
    (acc) => !acc.from && !acc.disabled
  );
  const totalTopup = activeAccounts.reduce((x, acc) => x + acc.monthlyTopup, 0);
  const totalIncome = appData.user.incomes.reduce(
    (acc, inc) => inc.amount + acc,
    0
  );

  function openSurplusModal() {
    modalCtx.openModal(
      <div className="p-2">
        <p>
          <span className="block sm:inline text-white">
            {surplus > 0 ? (
              <>
                We see a monthly savings surplus of{" "}
                <strong className="ml-2 font-bold text-future-blue">
                  {formatCurrency(
                    appData.modelResult.fullModel.allocatedSurplus
                  )}
                  .
                </strong>
              </>
            ) : (
              <>Your monthly contributions exceed your current savings.</>
            )}
          </span>
        </p>
        <div className="text-md text-orange-600 flex items-center align-center justify-center mt-2">
          <div className="flex-1">
            <p className="text-white text-xs">Monthly Income</p>
            <p className="text-green-500 font-bold">
              {formatCurrency(monthlyTakehome)}
            </p>
          </div>
          <span className="mx-2 mr-4 text-white text-lg pt-3">-</span>
          <div className="flex-1">
            <p className="text-white text-xs">Monthly Spend</p>
            <p className="text-red-500 font-bold">
              {formatCurrency(appData.user.settings.monthlySpend)}
            </p>
          </div>
          <span className="mx-2 mr-4 text-white text-lg pt-3">=</span>
          <div className="flex-1">
            <p className="text-white text-xs">{"Investable Income"}</p>
            <p className="text-white-500 font-bold">
              {formatCurrency(
                monthlyTakehome - appData.user.settings.monthlySpend
              )}
            </p>
          </div>
        </div>
        <div className="text-md text-orange-600 flex items-center align-center justify-center mt-2">
          <div className="flex-1">
            <p className="text-white text-xs">Investable Income</p>
            <p className="text-green-500 font-bold">
              {formatCurrency(
                monthlyTakehome - appData.user.settings.monthlySpend
              )}
            </p>
          </div>
          <span className="mx-2 mr-4 text-white text-lg pt-3">-</span>
          <div className="flex-1">
            <p className="text-white text-xs">Total Monthly Contributions</p>
            <p className="text-red-500 font-bold">
              {formatCurrency(totalTopup)}
            </p>
          </div>
          <span className="mx-2 mr-4 text-white text-lg pt-3">=</span>
          <div className="flex-1">
            <p className="text-white text-xs">{"Total Difference"}</p>
            <p className="text-white-500 font-bold">
              {formatCurrency(appData.modelResult.fullModel.allocatedSurplus)}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-md text-white">
            Please adjust your monthly contributions so we know what account
            this is going to and we can better estimate your FI roadmap.
          </p>
        </div>
        <TopiaButton
          onClick={() => {
            modalCtx.closeModal();
            navigate(
              appData.accounts.length > 0 ? "/portfolio" : "/add-account"
            );
          }}
          className="mt-4"
        >
          {appData.accounts.length > 0
            ? "Adjust your Contributions"
            : "Add an account"}
        </TopiaButton>
        <div className="mt-4">
          <p className="text-xs text-white">{footerText}</p>
        </div>
      </div>
    );
  }

  const monthlyTakehome = getMonthlyTakehomeFromIncome(appData.user.incomes);

  const surplus = appData.modelResult.fullModel.allocatedSurplus;

  const footerText =
    surplus > 0
      ? "We have assumed that you are investing this sum into an asset yielding a 7% annual return."
      : `We have assumed that your contributions are ${formatCurrency(
          totalTopup
        )} and not ${formatCurrency(
          totalIncome
        )} please update your contributions or income to ensure we correctly allocate your savings.`;

  if (Math.abs(appData.modelResult.fullModel.allocatedSurplus) < 100) {
    return <></>;
  }

  if (surplus < 100) {
    return (
      <TopiaBannerCTA
        illustration={<BriefcaseIllustration className="" svgProps={{}} />}
        analyticsEvent="negative_surplus_cta_clicked"
        title={"Your monthly contributions exceed your current savings."}
        copy={footerText}
        onClick={openSurplusModal}
        // color={"future-blue"}
        color="royal-purple"
        className={props.className}
      />
    );
  }
  else if (surplus > 100) {
    return (
      <TopiaBannerCTA
        illustration={<BriefcaseIllustration className="" svgProps={{}} />}
        analyticsEvent="positive_surplus_cta_clicked"
        title={
          <>
            We see a monthly savings surplus of{" "}
            <strong className="ml-2 font-bold text-white">
              {formatCurrency(appData.modelResult.fullModel.allocatedSurplus)}.
            </strong>
          </>
        }
        copy={<>Your monthly contributions exceed your current savings.</>}
        onClick={openSurplusModal}
        color={"royal-purple"}
        className={props.className}
      />
    );
  }

  return <></>;
}

export function BriefcaseIllustration(props: {
  className?: string;
  svgProps?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="39"
      fill="none"
      viewBox="0 0 42 39"
      className={props.className}
      {...props.svgProps}
    >
      <g 
      // clip-path="url(#a)"
      >
        <path
          fill="#C84897"
          fill-rule="evenodd"
          d="M18.33.372h4.583c1.755 0 3.178 1.439 3.178 3.214V8.22c0 1.775-1.423 3.214-3.178 3.214h-4.584c-1.755 0-3.178-1.439-3.178-3.214V3.586c0-1.775 1.423-3.214 3.178-3.214Zm.434 1.904h3.711c.957 0 1.733.785 1.733 1.752v3.753c0 .968-.776 1.752-1.733 1.752h-3.712a1.742 1.742 0 0 1-1.732-1.752V4.028c0-.967.776-1.752 1.733-1.752Z"
          clip-rule="evenodd"
        />
        <path
          fill="#1D1D20"
          d="M22.913-.128h-4.584v1h4.584v-1Zm3.678 3.714c0-2.046-1.642-3.714-3.678-3.714v1c1.474 0 2.678 1.21 2.678 2.714h1Zm0 4.634V3.586h-1V8.22h1Zm-3.678 3.714c2.036 0 3.678-1.668 3.678-3.714h-1c0 1.504-1.204 2.714-2.678 2.714v1Zm-4.584 0h4.584v-1h-4.584v1ZM14.651 8.22c0 2.046 1.642 3.714 3.678 3.714v-1c-1.473 0-2.678-1.21-2.678-2.714h-1Zm0-4.634V8.22h1V3.586h-1ZM18.33-.128c-2.036 0-3.678 1.668-3.678 3.714h1c0-1.504 1.205-2.714 2.678-2.714v-1Zm4.146 1.904h-3.712v1h3.712v-1Zm2.233 2.252a2.242 2.242 0 0 0-2.233-2.252v1c.675 0 1.233.556 1.233 1.252h1Zm0 3.753V4.028h-1v3.753h1Zm-2.233 2.252a2.242 2.242 0 0 0 2.233-2.252h-1c0 .697-.557 1.252-1.233 1.252v1Zm-3.712 0h3.712v-1h-3.712v1Zm-2.232-2.252c0 1.239.994 2.252 2.233 2.252v-1a1.242 1.242 0 0 1-1.233-1.252h-1Zm0-3.753v3.753h1V4.028h-1Zm2.233-2.252a2.242 2.242 0 0 0-2.233 2.252h1c0-.696.557-1.252 1.233-1.252v-1Z"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M35.88 7.023H6.124c-2.438 0-4.415 1.998-4.415 4.463v22.678c0 2.466 1.977 4.464 4.415 4.464h29.754c2.438 0 4.414-1.998 4.414-4.464V11.486c0-2.465-1.976-4.463-4.414-4.463Z"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.568 5.75h34.868c1.765 0 3.2 1.451 3.2 3.237 0 9.016-7.24 16.336-16.156 16.336h-8.957C7.607 25.323.368 18.003.368 8.987c0-1.786 1.434-3.237 3.2-3.237Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4.569 34.157H36.5"
        />
        <path
          fill="#1D1D20"
          stroke="#E0FEA1"
          stroke-miterlimit="10"
          d="M22.218 19.677h-2.436c-1.444 0-2.615 1.184-2.615 2.644v4.08c0 1.461 1.171 2.645 2.615 2.645h2.436c1.444 0 2.615-1.184 2.615-2.644v-4.08c0-1.461-1.171-2.645-2.615-2.645Z"
        />
        <path
          stroke="#E0FEA1"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21 22.827v3.541"
        />
      </g>
      {/* <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h42v39H0z" />
        </clipPath>
      </defs> */}
    </svg>
  );
}

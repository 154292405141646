export function PlaidLockIllustration(props: {
  className?: string;
  svgProps?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="61"
      fill="none"
      viewBox="0 0 63 61"
      className={props.className}
      {...props.svgProps}
    >
      <g>
        <path
          fill="#6B33EC"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M31.06 59.68c16.878 0 30.56-13.248 30.56-29.59S47.938.5 31.06.5.5 13.748.5 30.09s13.682 29.59 30.56 29.59Z"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M23.11 18.82v13.24c0 1.12.91 2.03 2.03 2.03h11.47c1.12 0 2.03-.91 2.03-2.03V18.82a7.35 7.35 0 0 0-7.35-7.35h-.84a7.35 7.35 0 0 0-7.35 7.35h.01Zm11.05 12.84h-6.58c-1.12 0-2.03-.91-2.03-2.03V19.2c0-2.93 2.37-5.3 5.3-5.3 2.94 0 5.33 2.39 5.33 5.33v10.4c0 1.12-.91 2.03-2.03 2.03h.01Z"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M41.93 22.5H19.81a2.07 2.07 0 0 0-2.07 2.07v19.88a2.07 2.07 0 0 0 2.07 2.07h22.12A2.07 2.07 0 0 0 44 44.45V24.57a2.07 2.07 0 0 0-2.07-2.07Z"
        />
        <path
          fill="#1D1D20"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M32.49 31.71c0-.89-.72-1.62-1.62-1.62-.9 0-1.62.72-1.62 1.62 0 .51.24.96.61 1.25L29.32 38h3.11l-.54-5.04c.37-.3.61-.74.61-1.25h-.01Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-miterlimit="10"
          d="M20.19 25.22h21.37m-1.43 14.97-2.27 2.31m1.27-7.44-2.27 2.31m3.88-.72-5.3 5.05"
        />
      </g>
    </svg>
  );
}

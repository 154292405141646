import { Income } from "@byundefined/topia-model/lib/commonTypes";
import { useForm } from "react-hook-form";
import { toFixedPrecision, uuidv4 } from "../helpers";
import { TopiaButton } from "./core/TopiaButton";
import { TopiaCurrencyInput } from "./core/TopiaInput";
import { TopiaSelect } from "./core/TopiaSelect";
import { TopiaTextInput } from "./core/TopiaInput";

export function IncomeForm(props: {
  income?: Income;
  onSubmit: (inc: Income) => void;
}) {
  const form = useForm();
  const values = form.watch();

  function onSubmit(values) {
    props.onSubmit({
      amount: Number(values.amount),
      name: values.name,
      type: values.type,
      id: uuidv4(),
    });
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <TopiaTextInput
        inputProps={form.register("name", {
          required: true,
          value: props.income?.name,
        })}
        label="Name"
        id="name"
      />
      <TopiaSelect
        inputProps={form.register("type", {
          required: true,
          value: props.income?.type || "salary",
        })}
        label="Type"
        id="income-type"
        options={[
          { value: "salary", label: "Salary" },
          { value: "sidehustle", label: "Sidehustle" },
        ]}
      />
      <TopiaCurrencyInput
        inputProps={form.register("amount", {
          required: true,
          value: toFixedPrecision(values.amount || props.income?.amount),
        })}
        label="Net Monthly Income"
        id="income-type"
      />
      <TopiaButton className="mt-2" type="submit">
        Submit
      </TopiaButton>
    </form>
  );
}

import { TopiaButton } from "../components/core/TopiaButton";
import { Card } from "../components/core/Card";
import { useDataContext } from "../context/DataContext";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, getLastMonthTopup } from "../helpers";
import { useAppSelector } from "../store";
import { ACCOUNT_TYPES } from "../enums";
import { SavingsRateChart } from "../components/SavingsRateChart";
import { PencilIcon } from "@heroicons/react/24/solid";
import { DataPoint } from "../components/core/DataPoint";
import { Account, HistoryItem } from "@byundefined/topia-model/lib/commonTypes";
import { useEffect } from "react";
import { trackAnalyticsEvent } from "../analytics";
import { useModalContext } from "../context/ModalContext";
import { SavingsRatePopup } from "../components/savingsRatePopup";

export function SavingsRatePage(props: {}) {
  const dataCtx = useDataContext();
  const modalCtx = useModalContext();

  const accounts = useAppSelector((state) =>
    state.app.userData.accounts.filter((acct) => !acct.from)
  );
  const history = dataCtx.history;

  function getHistoryAccountList(d: string) {
    const allHistories = accounts
      .map<{
        accountId: string;
        account: Account;
        item: HistoryItem;
      }>((acct) => ({
        item: (acct.history || ({} as any))[d],
        accountId: acct.id,
        account: acct,
      }))
      .filter((x) => !!x.item);
    return allHistories;
  }

  useEffect(() => {
    trackAnalyticsEvent("investment_rate_page_visited");
  }, []);

  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <Card>
        <SavingsRateChart />
        <div className="divide-y divide-white/20 p-2">
          {history.map((h, ix) => (
            <div key={ix} className="py-4">
              <div className="flex items-center mb-4">
                <h4 className="text-xl text-white">{h.dateFormatted}</h4>
                <p className="text-md text-white ml-4">
                  <strong className="text-white">{h.staticSavingRate}%</strong>{" "}
                  Investment Rate
                </p>
                <div className="flex-1"></div>
                <p className="text-md text-white ">
                  <span className="mx-2">Total income:</span>
                  <strong className="text-white">
                    {formatCurrency(h.staticIncome)}
                  </strong>
                </p>
                <p className="text-md text-white ">
                  <span className="mx-2">You Invested: </span>
                  <strong className="text-white">
                    {formatCurrency(h.investment)}
                  </strong>
                </p>
              </div>
              {getHistoryAccountList(h.date).map((x, ix) => (
                <div key={ix} className="flex items-center justify-center">
                  <p className="text-md text-white flex-1">
                    <span className="mr-2">{x.account.name}:</span>
                  </p>
                  <p className="text-md text-white">
                    {x.item.monthlyTopup === 0 ? (
                      <strong className="text-white">-</strong>
                    ) : (
                      <strong className="text-green-500">
                        + {formatCurrency(x.item.monthlyTopup)}
                      </strong>
                    )}
                  </p>
                  {/* <PencilIcon className="w-4 h-4 text-white ml-4" /> */}
                </div>
              ))}
              <div className={"flex items-center justify-center mt-2"}>
                <TopiaButton
                  btnStyle="future"
                  onClick={() => {
                    console.log("h is", h);
                    modalCtx.openModal(<SavingsRatePopup history={h} />);
                  }}
                >
                  Not adding up?
                </TopiaButton>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}

// Basis taken from (with some improvements) https://github.com/erhangundogan/yodlee-react-hook/
import { useEffect, useState } from "react";

// export type ConfigName = "string";

export type TokenEnumType = "AccessToken" | "JwtToken";

export interface TokenType {
  tokenType: TokenEnumType;
  tokenValue: string;
}

export interface FastLinkOptionsType {
  fastLinkURL: string;
  token?: TokenType;
  configName?: string;
}

export interface YodleeHookPropsType {
  containerId: string;
  createScriptTag?: boolean;
  fastLinkOptions: FastLinkOptionsType;
  onSuccess?: (args: any) => void;
  onError?: (args: any) => void;
  onClose?: (args: any) => void;
  onEvent?: (args: any) => void;
}

export interface YodleeHookReturnType {
  init: (token?: TokenType) => void;
  data: any;
  error: any;
  ready: boolean;
  active: boolean;
}

export interface YodleeHookType {
  (props: YodleeHookPropsType): YodleeHookReturnType;
}

const useYodlee: YodleeHookType = ({
  containerId,
  createScriptTag = true,
  fastLinkOptions: { fastLinkURL, token, configName = "Verification" },
  onSuccess,
  onError,
  onClose,
  onEvent,
}) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let script: HTMLScriptElement;
    if (createScriptTag) {
      script = document.createElement("script");

      script.id = "yodlee-fastlink-script";

      script.src = "https://cdn.yodlee.com/fastlink/v4/initialize.js";
      script.async = true;
      script.defer = true;
      script.onload = () => setReady(true);
      script.onerror = () =>
        setError("Yodlee FastLink library could not be loaded!");

      document.body.appendChild(script);
    }

    return () => {
      window.fastlink?.close();
      if (createScriptTag) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const init = (currentToken?: TokenType) => {
    const getTokenString = (t: TokenType) => {
      switch (t.tokenType) {
        case "AccessToken": {
          return { accessToken: `Bearer ${t.tokenValue}` };
        }
        case "JwtToken": {
          return { jwtToken: `Bearer ${t.tokenValue}` };
        }
      }
    };

    setActive(true);

    window.fastlink?.open(
      {
        fastLinkURL,
        params: { configName },
        ...getTokenString(currentToken || token),
        onSuccess: (customerData: any) => {
          console.log("on success", customerData);
          setData(customerData);
          onSuccess && onSuccess(customerData);
        },
        onError: (fastLinkError: any) => {
          console.log("set error", fastLinkError);
          setError(fastLinkError);
          onError && onError(fastLinkError);
        },
        onClose: (args) => {
          setActive(false);
          onClose && onClose(args);
        },
        onEvent,
      },
      containerId
    );
  };

  return {
    init,
    data,
    error,
    ready,
    active,
  };
};

declare global {
  interface Window {
    fastlink: any;
  }
}

export default useYodlee;

export function IncomeIllustration(props: { className?: string; svgProps?: any; }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="55"
      fill="none"
      viewBox="0 0 62 55"
      className={props.className}
      {...props.svgProps}
    >
      <g 
      //clip-path="url(#a)"
      >
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-miterlimit="10"
          d="m.5 6.33 3.96 1.78M5.57 1.2l2.07 3.81M.61.5l5.32 6.44"
        />
        <path
          fill="#C84897"
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M16.63 52.71a5.36 5.36 0 1 0 0-10.72 5.36 5.36 0 0 0 0 10.72Z"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M28.46 16.13c0-6.99-6.06-12.56-13.21-11.75-5.29.6-9.61 4.81-10.33 10.09-.46 3.34.48 6.45 2.31 8.84 1.99 2.59 2.92 5.84 2.92 9.11v6.02h12.97v-6.28c0-3.3 1.17-6.44 3.1-9.11 1.41-1.95 2.24-4.33 2.24-6.92Z"
        />
        <path
          fill="#C84897"
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M21.98 38.44H11.27A1.27 1.27 0 0 0 10 39.71v7.43c0 .701.569 1.27 1.27 1.27h10.71a1.27 1.27 0 0 0 1.27-1.27v-7.43a1.27 1.27 0 0 0-1.27-1.27Z"
        />
        <path
          fill="#C84897"
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M23.8 38.16H9.45a1.06 1.06 0 0 0 0 2.12H23.8a1.06 1.06 0 1 0 0-2.12Zm0 8.13H9.45a1.06 1.06 0 0 0 0 2.12H23.8a1.06 1.06 0 1 0 0-2.12Z"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M13.2 20.13a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm6.6 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        />
        <path
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M13.2 20.14h6.6m-4.33 13-3.68-13.99m5.74 13.99 3.68-13.99"
        />
        <path
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M11.59 41.99h10.07m-10.07 2.15h10.07"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-miterlimit="10"
          d="m57.12 11.18 4.24-1.22m-6.98-2.02 2-3.32m-.6 4.63 5.59-4.08"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M52.8 29.89s-.83-13.43-11.57-10.01c-11.13 3.54-8.31 13.99-25.89 9.31l-1.25-14.35c17.59 4.68 14.45-6.97 25.89-9.31 12.19-2.5 11.97 12.68 11.97 12.68l.85 11.68Z"
        />
        <path
          fill="#C84897"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M47.17 36.59s6.02.47 5.73-5.55c-.36-7.5-4.8-10.15-4.8-10.15l-.92 15.7h-.01Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M43.34 16.88c-6.93 0-10.73 4.93-13.91 8.34-3.18 3.41-8.41 2.63-8.41 2.63-.45-3.46-4.09-2.9-4.09-2.9l-.45-4.26s1.97-.24 1.66-2.6c0 0 3.94 1.09 9.02-1.21 4.17-1.89 5.72-5.48 9.47-7.94 3.75-2.46 6.61-1.77 6.61-1.77s-3.81 1.92-3.66 5.33c.15 3.41 3.76 4.38 3.76 4.38Zm8.86 4.72c-1.85-5.29-6-4.79-6-4.79s2.73-1.66 1.87-4.65c-.86-2.99-2.37-4.45-2.37-4.45s2.79-.03 5.86 6.67"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 52.11c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23V28.34h15.24v23.77Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 52.17c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 49.89c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 47.84c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 45.68c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23V43.8h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 43.41c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 41.36c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 39.19c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 36.91c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 34.86c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 32.81c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88ZM33.198 3.1c.81.93-1.11 3.92-4.29 6.68-3.18 2.76-6.41 4.24-7.21 3.31l-1.23-1.42 11.5-9.99 1.23 1.42Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M27.677 8.364c3.178-2.76 5.098-5.75 4.29-6.68-.807-.93-4.037.553-7.214 3.313-3.177 2.76-5.098 5.751-4.29 6.68.807.93 4.037-.553 7.214-3.313Z"
        />
        <path
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="m24.058 11.28 1.04 1.2m-.05-1.76 1.04 1.2m-.14-1.98 1.04 1.2m4.35-6.6 1.04 1.2"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 30.53c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23v-1.88h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M45.35 28.48c0 1.23-3.41 2.23-7.62 2.23s-7.62-1-7.62-2.23V26.6h15.24v1.88Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M37.73 28.83c4.208 0 7.62-.998 7.62-2.23s-3.412-2.23-7.62-2.23-7.62.998-7.62 2.23 3.412 2.23 7.62 2.23Z"
        />
        <path
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M33.08 28.66v22.36m1.48-19.16v22.37m1.55-22.37v22.37M43 28.34v18.81"
        />
      </g>
      {/* <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h61.87v54.9H0z" />
        </clipPath>
      </defs> */}
    </svg>
  );
}

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export function TopiaSkeleton(props: {}) {
  return (
    <>
      <div>
        <Skeleton />
        <Skeleton count={5} />
      </div>
    </>
  );
}

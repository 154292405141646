import { useNavigate, useParams } from "react-router-dom";
import { AccountForm } from "../components/AccountForm";
import { Card } from "../components/core/Card";
import { useDataContext } from "../context/DataContext";
import { useAppSelector } from "../store";

export function AccountPage(props: {}) {
  const params = useParams();
  const dataCtx = useDataContext();
  const accounts = useAppSelector((state) => state.app.userData.accounts);
  const accountId = params.accountId as string;
  const acct = accounts.find((a) => a.id == accountId);
  const navigate = useNavigate();

  if (!acct) {
    // TODO: Error handling
    console.error("No account found");
    navigate("/");
    return null;
  }


  
  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <div className="mb-4 flex w-full items-center justify-center">
        <div className="text-xl text-white">{acct.name}</div>
      </div>
      <Card className="p-4">
        <h3 className="text-white">{acct.name}</h3>
        <AccountForm account={acct} onSubmit={async (vals) => {
          await dataCtx.operations.updateAccount({ id: accountId, data: vals });
          navigate("/");
        }} />
      </Card>
    </div>
  );
}

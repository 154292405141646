import { signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth";
import { Card } from "../components/core/Card";
import { TopiaButton } from "../components/core/TopiaButton";
import { TopiaSelect } from "../components/core/TopiaSelect";
import {
  TopiaEmailInput,
  TopiaPasswordInput,
  TopiaTextInput,
} from "../components/core/TopiaInput";
import { auth, db } from "../firebase";
import { TopiaLogo } from "../components/core/TopiaLogo";
import { CONSTANTS } from "../constants";
import { collection, doc, setDoc } from "firebase/firestore";

export function SignUpPage(props: {}) {
  const authCtx = useAuthContext();
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>();
  const navigate = useNavigate();

  async function onSubmit(vals) {
    try {
      setLoading(true);
      const user = await authCtx.signUpWithEmail(vals.email.trim(), vals.password, vals.firstName);
      await setDoc(doc(collection(db, "users"), user.user.uid), {})
      navigate("/onboarding");
    } catch (ex) {
      if (ex.code === "auth/email-already-in-use") {
        setErr(
          <div className="p-4">
            <Link to="/login">
              <p className="text-red-500">Email already in use</p>
              <p className="text-red-500">Try logging in</p>
            </Link>
          </div>
        );
      }
      console.error(ex);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="lg:grid lg:grid-cols-5 min-h-full justify-center py-12 sm:px-6 lg:px-8 bg-topia-black ">
        <div className="col-span-2 px-16">
          <div className="w-full">
            <div className="">
              <TopiaLogo svgProps={{ style: { fill: "white", width: 103 } }} />
            </div>
            <h2 className="my-8 text-5xl tracking-tight text-white font-serif font-light">
              Create your
              <br />
              account
            </h2>
            <p className="text-white text-md mb-6">Let’s get started with some basic information</p>
          </div>

          <div className="w-full">
            <div className="">
              <form
                className="space-y-6"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <div>
                  <TopiaTextInput
                    label="First Name"
                    inputProps={form.register("firstName", {
                      required: true,
                    })}
                    id="login-email"
                    className=""
                  />
                  <TopiaEmailInput
                    label="Email address"
                    inputProps={form.register("email", {
                      required: true,
                    })}
                    id="login-email"
                    className=""
                  />
                  <TopiaPasswordInput
                    label="Password"
                    inputProps={form.register("password", {
                      required: true,
                    })}
                    id="login-password"
                    className="mt-4"
                  />
                </div>

                <div>
                  <TopiaButton
                    analyticsEvent="sign_up_page_submit_clicked"
                    type="submit"
                    className="w-full"
                    btnStyle="primary"
                    loading={loading}
                  >
                    Create Account
                  </TopiaButton>
                </div>
              </form>

              {/* <div className="mt-6">
                <div className="relative">
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-white">Or continue with</span>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-3 gap-3">
                  <div>
                    <a
                      href="#"
                      className="inline-flex w-full justify-center   border border-gray-300 py-2 px-4 text-sm font-medium text-white"
                    >
                      <span className="sr-only">Sign in with Facebook</span>
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>

                  <div>
                    <a
                      href="#"
                      className="inline-flex w-full justify-center   border border-gray-300 py-2 px-4 text-sm font-medium text-white"
                    >
                      <span className="sr-only">Sign in with Twitter</span>
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  </div>

                  <div>
                    <a
                      href="#"
                      className="inline-flex w-full justify-center   border border-gray-300 py-2 px-4 text-sm font-medium text-white"
                    >
                      <span className="sr-only">Sign in with GitHub</span>
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div> */}
              {err}
              </div>
          </div>
          <p className="text-topia-gray mt-8 text-center">
            By continuing, you agree to Topia's
            <br />
            <a href={CONSTANTS.links.privacyPolicy} target="_blank" className="text-future-blue">
              Privacy Policy
            </a>{" "}
            &amp;{" "}
            <a href={CONSTANTS.links.termsAndConditions} target="_blank" className="text-future-blue">
              Terms and Conditions
            </a>
          </p>
        </div>
        <div className="col-span-3 p-8">
          {/* <div className="rounded-xl bg-future-blue w-full h-full"></div> */}
        </div>
      </div>
    </>
  );
}

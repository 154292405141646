import {
  Income,
  LifeEvent,
  Account,
  LifeEventTypes,
} from "@byundefined/topia-model/lib/commonTypes";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDataContext } from "../context/DataContext";
import { useModalContext } from "../context/ModalContext";
import { dateToDashStringYMD, uuidv4 } from "../helpers";
import { defaultValues } from "../pages/AddAccountManual";
import { appActions, useAppDispatch } from "../store";
import { AccountForm } from "./AccountForm";
import { LifeEventForm } from "./LifeEventForm";

export enum PortfolioEvents {
  WITHDRAWAL_RECURRING = "WITHDRAWAL_RECURRING",
  LUMP_SUM_SINGLE = "LUMP_SUM_SINGLE",
  WITHDRAWAL_SINGLE = "WITHDRAWAL_SINGLE",
  ASSET_SALE_OR_PURCHASE = "ASSET_SALE_OR_PURCHASE",
}

type PortfolioLifeEventTypes =
  | "recurringWithdrawal"
  | "oneTimeIncome"
  | "oneTimeWithdrawal";

type PortfolioEventTemplateLifeEvent = {
  icon: string;
  name: string;
  type: PortfolioLifeEventTypes;
  accounts?: Account[];
  lifeEvent?: Partial<LifeEvent>;
};
type PortfolioEventTemplateFutureAccount = {
  icon: string;
  name: string;
  type: "saleOrPurchase";
  accountType: "saving" | "property";
  accounts?: Account[];
};

type PortfolioEventTemplate =
  | PortfolioEventTemplateLifeEvent
  | PortfolioEventTemplateFutureAccount;
export function SaleOrPurchaseEdit({
  accountType,
}: {
  accountType: "saving" | "property";
}) {
  const appDispatch = useAppDispatch();
  const modalCtx = useModalContext();

  const defaultAcc = {
    id: uuidv4(),
    ...defaultValues[accountType],
  } as Account;

  return (
    <div className="w-3/4">
      <AccountForm
        account={defaultAcc}
        isFuture
        onSubmit={async (vals) => {
          appDispatch(appActions.addTinkerAccount(vals as any));
          modalCtx.closeModal();
        }}
      />
    </div>
  );
}

export function NewPortfolioEventPopup(props: {}) {
  const [event, setEvent] = useState<PortfolioEventTemplate | undefined>();
  const appDispatch = useAppDispatch();
  const modalCtx = useModalContext();
  const dataCtx = useDataContext();

  const investmentAccs = dataCtx.accounts.filter(
    (acc) => acc.type === "saving"
  );

  const propertyAccs = dataCtx.accounts.filter((acc) => !!acc.property);

  const diyLifeEvents: PortfolioEventTemplate[] = [
    {
      name: "Sell",
      type: "oneTimeWithdrawal",
      icon: "💸",
      lifeEvent: {
        increasedSpend: 2000,
        name: "Sell Asset",
        from: dateToDashStringYMD(moment().add(6, "months").toDate()),
      },
    },
    {
      name: "Buy Investment",
      accountType: "saving",
      type: "saleOrPurchase",
      icon: "💰",
    },
    {
      name: "Buy Property",
      type: "saleOrPurchase",
      accountType: "property",
      icon: "💰",
    },
  ];

  const COMMON_PORTFOLIO_EVENTS: PortfolioEventTemplate[] = [
    {
      type: "recurringWithdrawal",
      name: "Recurring Withdrawal",
      accounts: investmentAccs,
      icon: "💸",
      lifeEvent: {
        recurringTime: "monthly",
        increasedSpend: 2000,
        name: "Recurring Withdrawal",
        from: dateToDashStringYMD(moment().add(1, "years").toDate()),
        to: dateToDashStringYMD(moment().add(2, "years").toDate()),
      },
    },
    {
      type: "oneTimeIncome",
      name: "One-time lump sum",
      accounts: investmentAccs,
      icon: "💰",
      lifeEvent: {
        increasedIncome: 2000,
        name: "One-time lump sum",
        from: dateToDashStringYMD(moment().add(1, "years").toDate()),
      },
    },
    {
      type: "oneTimeWithdrawal",
      name: "One-time withdrawal",
      accounts: investmentAccs,
      icon: "💸",
      lifeEvent: {
        increasedSpend: 2000,
        name: "One-time withdrawal",
        from: dateToDashStringYMD(moment().add(1, "years").toDate()),
      },
    },
  ];

  if (propertyAccs.length > 0) {
    diyLifeEvents.push({
      name: "Equity Release",
      icon: "💸",
      type: "oneTimeWithdrawal",
      accounts: propertyAccs,
      lifeEvent: {
        increasedSpend: 2000,
        name: "Equity Release",
        from: dateToDashStringYMD(moment().add(1, "years").toDate()),
      },
    });
  }

  if (event?.type === "saleOrPurchase") {
    return <SaleOrPurchaseEdit accountType={event.accountType} />;
  }

  const lifeEvent: Partial<LifeEvent> | undefined = event?.lifeEvent;

  if (lifeEvent) {
    return (
      <div className="w-3/4">
        <LifeEventForm
          onSubmit={(vals) => {
            const newLe = {
              ...vals,
              id: uuidv4(),
            };
            appDispatch(appActions.addTinkerLifeEvent(newLe as any));
            modalCtx.closeModal();
          }}
          accounts={event.accounts || investmentAccs}
          lifeEvent={lifeEvent}
        />
      </div>
    );
  }

  if (investmentAccs.length < 0) {
    return (
      <div className="">
        <div className="">
          <h3 className="text-lg mb-4 text-white font-bold">
            Portfolio Events
          </h3>
          <div className="grid grid-cols-3">
            <p className="text-sm text-white col-span-2">
              Expecting a windfall or bonus? Planning to buy a rental property
              or invest in an ETF?
            </p>
          </div>

          <div>
            <p className="text-red-400 text-sm mt-4">
              Please make sure you have investment accounts before adding in
              portfolio events
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="">
      <div className="">
        <h3 className="text-lg mb-4 text-white font-bold">Portfolio Events</h3>
        <div className="grid grid-cols-3">
          <p className="text-sm text-white col-span-2">
            Expecting a windfall or bonus? Planning to buy a rental property or
            invest in an ETF?
          </p>
          <div></div>
        </div>
      </div>
      <div className="grid grid-cols-2 mt-4 sm:mt-8 px-2 py-2">
        <div>
          <h3 className="text-lg mb-4 text-white font-bold">
            Portfolio Events
          </h3>
          <div className="">
            {COMMON_PORTFOLIO_EVENTS.map((event, ix) => (
              <div
                className="flex items-center mb-2 border-1 p-4 cursor-pointer hover:bg-topia-gray my-1"
                onClick={() => {
                  setEvent(event);
                }}
                key={ix}
              >
                <div className="mr-2">{event.icon}</div>
                <p className="text-white">{event.name}</p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-lg mb-4 text-white font-bold">
            Sale or Purchase of an Asset
          </h3>
          <div className="">
            {diyLifeEvents.map((event, ix) => (
              <div
                className="flex items-center mb-2 border-1 p-4 cursor-pointer hover:bg-topia-gray my-1"
                onClick={() => {
                  setEvent(event as any);
                }}
              >
                <p className="text-white">{event.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

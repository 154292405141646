import { useTinkerContext } from "../context/TinkerContext";
import { appActions, useAppDispatch, useAppSelector } from "../store";
import { TopiaButton } from "./core/TopiaButton";
import { DiamondIllustration } from "./core/illustrations/DiamondIllustration";

export function TinkerBanner(props: {}) {
  const appDispatch = useAppDispatch();
  const tinkerCtx = useTinkerContext();
  const isTinkerMode = useAppSelector((state) => !!state.app.tinkerData);

  if (!isTinkerMode) {
    return <></>;
  }

  return (
    <div
      className="flex items-center align-center w-full p-4  topia-fade-in bg-rgb-blue border-b-2 border-future-blue sticky top-0 z-[50]"
    >
      <DiamondIllustration className="mr-8" />
        <h4 className="text-2xl font-bold text-white mr-16">Tinker Mode</h4>
        <div>
          <h4 className="text-xl text-white">
            You have pending changes
          </h4>
      <p className="text-white text-sm m-0">
        Change your settings to see how it affects your FI date.
      </p>
        </div>
      <div className="flex-1"></div>
      <div className="flex items-center px-4">
        <div className="">
          <TopiaButton
            loading={tinkerCtx.isSavingTinker}
            analyticsEvent="reset_tinker_clicked"
            onClick={() => {
              appDispatch(appActions.resetTinker({}));
            }}
            className="mr-4"
            btnStyle="secondary"
          >
            Reset
          </TopiaButton>
          <TopiaButton
            className=""
            analyticsEvent="save_tinker_clicked"
            loading={tinkerCtx.isSavingTinker}
            btnStyle="primary"
            onClick={() => {
              tinkerCtx.saveTinker();
            }}
          >
            Make it my plan
          </TopiaButton>
        </div>
      </div>
    </div>
  );
}

import { Account } from "@byundefined/topia-model/lib/commonTypes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { trackAnalyticsEvent } from "../analytics";
import { AccountForm } from "../components/AccountForm";
import { Card } from "../components/core/Card";
import { useDataContext } from "../context/DataContext";
import { ACCOUNT_CATEGORIES, ACCOUNT_TYPES } from "../enums";
import { uuidv4 } from "../helpers";
import { useQuery } from "../hooks/useQuery";

export const defaultValues: Record<ACCOUNT_TYPES, Partial<Account>> = {
  saving: {
    amount: 0,
    name: "",
    annualReturn: 7,
    category: ACCOUNT_CATEGORIES.OTHER,
    type: "saving",
    subType: "stocks_shares_isa",
    history: {},
    monthlyTopup: 500,
  },
  debt: {
    amount: 0,
    name: "",
    annualReturn: 7,
    category: ACCOUNT_CATEGORIES.CREDIT_CART,
    type: "debt",
    subType: "credit_card",
    history: {},
    monthlyTopup: 100,
  },
  standard: {
    amount: 0,
    name: "",
    annualReturn: 7,
    category: ACCOUNT_CATEGORIES.EMERGENCY_FUND,
    type: "standard",
    subType: "cash",
    history: {},
    monthlyTopup: 0,
  },
  property: {
    amount: 100000,
    name: "",
    annualReturn: 7,
    category: ACCOUNT_CATEGORIES.MORTGAGE,
    type: "debt",
    property: {
      valueOfProperty: 100000,
      includeHomeEquity: true,
      appreciationValue: 7,
      monthlyIncome: 0,
    },
    subType: "mortgage",
    history: {},
    monthlyTopup: 1000,
  },
};

export function AddAccountManual(props: {}) {
  const dataCtx = useDataContext();
  const params = useParams();
  const navigate = useNavigate();
  const query = useQuery();

  let type = params.type;
  if (query.get("subType") === "mortgage") {
    type = "property";
  }

  useEffect(() => {
    trackAnalyticsEvent(`add_account_manual_${type}_page_visited`);
  }, []);

  const [acct, setAcct] = useState<Account>({
    id: uuidv4(),
    ...defaultValues[type as ACCOUNT_TYPES],
  } as Account);

  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <div className="mb-4 flex w-full items-center justify-center">
        <div className="text-xl">{acct.name}</div>
      </div>
      <Card className="p-4">
        <h3>{acct.name}</h3>
        <AccountForm
          account={acct}
          onSubmit={async (vals) => {
            await dataCtx.operations.createUserGeneratedAccount(vals);
            navigate("/");
          }}
        />
      </Card>
    </div>
  );
}

import _ from "lodash";
import { useEffect, useRef } from "react";
import { useDataContext } from "../context/DataContext";
import { useModalContext } from "../context/ModalContext";
import { PortfolioDelta } from "../PortfolioDelta";
import { AppUserData, useAppSelector } from "../store";
import { TopiaScoreCardGraph } from "../topia-graph/TopiaScoreCardGraph";
import { Card } from "./core/Card";
import { FIStats } from "./FIStats";

export function DashboardChart(props: {
  inverse?: boolean;
  fill?: boolean;
  modelResult?: AppUserData["modelResult"];
}) {
  const svgRef = useRef<any>();
  const graphRef = useRef<TopiaScoreCardGraph>();

  const userData = useAppSelector((state) => state.app.userData);
  const tinkerData = useAppSelector((state) => state.app.tinkerData);

  const appData = tinkerData || userData;

  const modelResult = props.modelResult || appData.modelResult;

  useEffect(() => {
    if (svgRef.current && !graphRef.current) {
      graphRef.current = new TopiaScoreCardGraph(svgRef.current, {
        inverse: props.inverse,
        fill: props.fill
      });
    }

    const onWindowResize = _.throttle(
      () => {
        graphRef.current.initGraph();
        graphRef.current.updateGraph();
      },
      100,
      {
        trailing: true,
      }
    );

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [svgRef.current]);

  useEffect(() => {
    if (graphRef.current && modelResult.fullModel) {
      graphRef.current.setModelData(modelResult.fullModel, appData.lifeEvents);
    }
  }, [modelResult]);

  return (
    <div className="w-full">
      <div className="">
        <div className="pt-4 pr-4 pb-4 -ml-4">
          <svg width="100%" height={400} ref={svgRef} style={{}} />
        </div>
      </div>
    </div>
  );
}

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { trackAnalyticsEvent } from "../../analytics";

export function TopiaBannerCTA(props: {
  title: any;
  copy: any;
  onClick: () => void;
  illustration: any;
  analyticsEvent?: string;
  className?: string;
  color: "pale-yellow" | "royal-purple" | "future-blue";
}) {
  const fgColor = props.color === "royal-purple" ? "white" : "black";
  const textColor = `text-${fgColor}`;

  return (
    <div
      className={classNames(
        "p-4 flex items-center cursor-pointer rounded-xl mt-4",
        `bg-${props.color}`,
        props.className
      )}
      onClick={() => {
        props.onClick && props.onClick();
        if (props.analyticsEvent) {
          trackAnalyticsEvent(props.analyticsEvent);
        }
      }}
    >
      <div>{props.illustration}</div>
      <div>
        <p className={classNames("text-md pl-4 font-bold m-0", textColor)}>
          <span className="">{props.title}</span>
        </p>
        <p className={classNames("text-xs pl-4 m-0", textColor)}>
          {props.copy}
        </p>
      </div>
      <div className="flex-1"></div>
      <ArrowRightIcon className="h-6 w-6" style={{ color: fgColor }} />
    </div>
  );
}

// TODO
// This is a dummy component to trick tailwind into emitting color classes for everything
function Dummy() {
  return (
    <>
      <div className="bg-royal-purple"></div>
      <div className="bg-pale-yellow"></div>
      <div className="bg-future-blue"></div>
      <div className="bg-rgb-blue"></div>
    </>
  );
}

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

export function ChangeIndicator(props: {
  className?: string;
  changeType: "increase" | "decrease";
  change: any;
  inverseColors?: boolean;
}) {
  if (props.change == 0) {
    return <></>;
  }

  console.log('c', props.change)

  if (props.inverseColors) {
    return (
      <div className={props.className}>
        <p
          className={classNames(
            props.changeType === "increase" ? "text-green-600" : "text-red-600",
            "flex items-baseline text-xs font-semibold animate-bounce-in"
          )}
        >
          {props.changeType === "increase" ? (
            <ArrowUpIcon
              className="h-5 w-5 flex-shrink-0 self-center text-green-500"
              aria-hidden="true"
            />
          ) : (
            <ArrowDownIcon
              className="h-5 w-5 flex-shrink-0 self-center text-red-500"
              aria-hidden="true"
            />
          )}

          <span className="sr-only">
            {" "}
            {props.changeType === "increase"
              ? "Increased"
              : "Decreased"} by{" "}
          </span>
          <span className="ml-2">{props.change}</span>
        </p>
      </div>
    );
  }

  return (
    <div className={props.className}>
      <p
        className={classNames(
          props.changeType === "increase" ? "text-red-600" : "text-green-600",
          "flex items-baseline text-xs font-semibold animate-bounce-in"
        )}
      >
        {props.changeType === "increase" ? (
          <ArrowUpIcon
            className="h-5 w-5 flex-shrink-0 self-center text-red-500"
            aria-hidden="true"
          />
        ) : (
          <ArrowDownIcon
            className="h-5 w-5 flex-shrink-0 self-center text-green-500"
            aria-hidden="true"
          />
        )}

        <span className="sr-only">
          {" "}
          {props.changeType === "increase" ? "Increased" : "Decreased"} by{" "}
        </span>
        <span className="ml-2">{props.change}</span>
      </p>
    </div>
  );
}

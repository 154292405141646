import Lottie from "lottie-react";
import loopAnimation from "./LogoLoop.json";

export function TopiaLogo(props: {
  className?: string;
  color?: string;
  svgProps?: any;
}) {
  // return (
  //   <div className="bg-red-500 p-4">
  //     <Lottie
  //       animationData={loopAnimation}
  //       loop={true}
  //       width={200}
  //       height={100}
  //       style={{
  //         filter: "inverse(1)",
  //         width: "100%",
  //         height: "auto"
  //       }}

  //       color="white"
  //     />
  //   </div>
  // );
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 430.7 84.21"
      className={props.className}
      {...props.svgProps}
    >
      <defs></defs>
      <g id="a" />
      <g id="b">
        <g id="c">
          <path
            className="d"
            d="M410.56,42.37h-26.12c1.47,2.87,2.93,5.74,4.41,8.6,.44,.85,.24,.96-.61,.96-8.71-.02-17.42-.01-26.13-.01-1.18,0-1.19-.01-.64-1.08,1.44-2.82,2.88-5.64,4.32-8.46h-24.16c-6.66,13.04-13.32,26.08-19.98,39.11-.53,1.04-.49,1.1,.69,1.1,7.29,0,14.59-.01,21.88,.02,.8,0,1.21-.28,1.55-.97,1.95-3.88,3.97-7.74,5.92-11.62,.37-.74,.81-1,1.64-1,14.56,.02,29.11,.02,43.67,0,.87,0,1.32,.3,1.71,1.06,1.91,3.83,3.9,7.62,5.81,11.45,.4,.81,.9,1.09,1.8,1.09,7.4-.04,14.79-.02,22.19-.02,2.64,0,2.62,0,1.44-2.31-6.46-12.63-12.92-25.27-19.37-37.91Z"
          />
          <path
            className="d"
            d="M375.87,42.37c2.99-5.86,5.97-11.71,8.96-17.57,.09-.17,.2-.33,.41-.66,.72,1.41,1.37,2.69,2.02,3.96,2.43,4.76,4.85,9.51,7.28,14.27h26.12c-2.13-4.17-4.27-8.34-6.4-12.52-4.72-9.25-9.45-18.49-14.14-27.75-.36-.71-.81-.95-1.58-.94-4.09,.03-8.17,.01-12.26,.01-3.99,0-7.97,.04-11.95-.03-1.13-.02-1.73,.32-2.25,1.36-4.07,8.07-8.21,16.09-12.32,24.14-2.68,5.24-5.36,10.49-8.04,15.73h24.16Z"
          />
          <path
            className="d"
            d="M290.02,42.39c0,12.96,0,25.93,0,38.89,0,1.31,0,1.32,1.38,1.32,4.83,0,9.66,0,14.49,0,2.43,0,4.86-.02,7.29,.01,.74,0,1.06-.26,1.02-1-.02-.34,0-.67,0-1.01,0-12.74,0-25.48,0-38.22h-24.18Z"
          />
          <path
            className="d"
            d="M324,42.38v-.2c0-13.08,0-26.15,0-39.23,0-1.35-.03-1.38-1.34-1.38-7.16,0-14.32,0-21.48,0-1.35,0-1.36,0-1.36,1.35,0,13.15,0,26.31,0,39.46h24.18Z"
          />
          <path
            className="d"
            d="M291.03,42.41c.5-1.23,.92-2.54,1.24-3.93,1.4-6.01,1.33-12.03-.14-18.01-1.89-7.74-6.62-13.03-14.06-15.92-5.56-2.15-11.39-2.88-17.28-2.91-18.68-.1-37.35-.05-56.03-.05q-1.73,0-1.73,1.7c0,12.91,0,25.82,0,38.73v.38h87.99Zm-62.77-21.52c5.3,.04,10.6,.02,15.9,.02,4.9,0,9.79,0,14.69,0,1.84,0,3.63,.24,5.38,.82,2.76,.92,4.48,2.79,5.09,5.64,.49,2.27,.47,4.55-.12,6.8-.88,3.34-3.33,4.92-6.5,5.63-1.62,.36-3.27,.44-4.93,.44-9.79,0-19.58,0-29.38,0-1.37,0-1.37,0-1.37-1.31,0-5.59,.02-11.19-.02-16.78,0-.97,.28-1.27,1.26-1.26Z"
          />
          <path
            className="d"
            d="M193.25,42.41c0,12.92,0,25.84,0,38.76,0,1.46,0,1.46,1.46,1.46,7.06,0,14.12,0,21.18,0,1.33,0,1.34,0,1.34-1.36,0-7.15,0-14.29,0-21.44,0-1.48,0-1.47,1.49-1.49,11.51-.11,23.03,.27,34.54-.21,4.95-.2,9.8-1.04,14.43-2.85,6.5-2.54,11.08-6.79,13.55-12.88h-87.99Z"
          />
          <path
            className="d"
            d="M162.04,42.37c0,3.31-.55,6.54-1.97,9.63-2.71,5.88-7.48,9.32-13.5,11.23-3.89,1.24-7.9,1.7-11.13,1.66-6.06,0-11.16-.68-15.94-2.88-6.99-3.2-11.1-8.59-12.14-16.23-.15-1.14-.24-2.28-.26-3.42h-24.06c.03,2.18,.2,4.38,.5,6.59,1.74,12.89,8.47,22.36,19.95,28.44,8.91,4.72,18.56,6.4,28.51,6.75,4.8,.17,9.58-.09,14.33-.77,7.38-1.05,14.46-3.05,20.95-6.81,7.17-4.15,12.61-9.89,15.79-17.6,2.22-5.39,3.16-10.95,3.14-16.62h-24.18Z"
          />
          <path
            className="d"
            d="M117.11,42.37c-.06-2.69,.28-5.36,1.17-7.96,2.34-6.87,7.42-10.82,14.15-12.89,5.26-1.62,10.66-1.91,16.13-1.5,3.94,.29,7.76,1.02,11.37,2.65,7.66,3.46,11.6,9.46,12.08,17.79,.04,.64,.05,1.28,.05,1.92h24.18c0-2.15-.14-4.32-.41-6.5-1.4-11.39-6.78-20.41-16.39-26.78-8.25-5.47-17.54-7.84-27.28-8.74-1.61-.15-3.22-.24-4.83-.35h-5.47c-2.71,.25-5.44,.35-8.15,.73-7.05,.97-13.82,2.8-20.13,6.15-7.66,4.07-13.58,9.83-17.09,17.84-2.51,5.71-3.52,11.62-3.43,17.66h24.06Z"
          />
          <path
            className="d"
            d="M24.59,42.37c0,12.92,0,25.83,0,38.75,0,1.46,0,1.46,1.45,1.47,7.16,0,14.32,0,21.48,0,1.9,0,1.84,.24,1.84-1.88,0-12.78,0-25.55,0-38.33H24.59Z"
          />
          <path
            className="d"
            d="M32.82,21.5c.3,0,.61,0,.91,0,.46,0,.69,.21,.7,.67,0,.37,.01,.74,.01,1.11,0,6.36,0,12.73,0,19.09h24.77c0-6.37,0-12.74,0-19.11q0-1.77,1.72-1.77c4.42,0,8.85,0,13.27,0,6.11,0,12.22,0,18.34,0,.68,0,1.16-.05,1.16-.95-.03-6.03-.02-12.07,0-18.1,0-.65-.25-.93-.9-.89-.27,.02-.54,0-.81,0-30.22,0-60.45,0-90.67,0-.44,0-.88,.04-1.31,.06V21.44c.47,.02,.94,.06,1.41,.06,10.47,0,20.94,0,31.41,0Z"
          />
        </g>
      </g>
    </svg>
  );
}

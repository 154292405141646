import { Income, LifeEvent } from "@byundefined/topia-model/lib/commonTypes";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useModalContext } from "../context/ModalContext";
import { dateToDashStringYMD, uuidv4 } from "../helpers";
import { appActions, useAppDispatch } from "../store";
import { LifeEventForm } from "./LifeEventForm";

type LifeEventTemplate = {
  icon: string;
  name: string;
  type: string;
  lifeEvent: Partial<LifeEvent>;
};

const COMMON_LIFE_EVENTS: LifeEventTemplate[] = [
  {
    type: "have-kid",
    name: "Have a Child",
    icon: "👶",
    lifeEvent: {
      recurringTime: "monthly",
      increasedSpend: 2000,
      name: "Have a child",
      from: dateToDashStringYMD(moment().toDate()),
      to: dateToDashStringYMD(moment().add(18, "years").toDate()),
    },
  },
  {
    type: "vacation",
    name: "Take a Vacation",
    icon: "🏝",
    lifeEvent: {
      increasedSpend: 5000,
      name: "Take a Vacation",
      from: dateToDashStringYMD(moment().add(6, "months").toDate()),
    },
  },
];

export function NewLifeEventPopup(props: {}) {
  const [lifeEvent, setLifeEvent] = useState<Partial<LifeEvent> | undefined>();
  const appDispatch = useAppDispatch();
  const modalCtx = useModalContext();

  const diyLifeEvents = [
    {
      name: "One-time purchase",
      lifeEvent: {
        increasedSpend: 5000,
        name: "One-time purchase",
        from: dateToDashStringYMD(moment().add(6, "months").toDate()),
      },
    },
    {
      name: "Recurring expense",
      lifeEvent: {
        recurringTime: "monthly",
        increasedSpend: 2000,
        name: "Recurring expense",
        from: dateToDashStringYMD(moment().add(1, "years").toDate()),
        to: dateToDashStringYMD(moment().add(2, "years").toDate()),
      },
    },
  ];

  if (lifeEvent) {
    return (
      <div className="w-3/4">
        <LifeEventForm
          onSubmit={(vals) => {
            const newLe = {
              ...vals,
              id: uuidv4(),
            };
            appDispatch(appActions.addTinkerLifeEvent(newLe as any));
            modalCtx.closeModal();
          }}
          lifeEvent={lifeEvent}
        />
      </div>
    );
  }

  return (
    <div className="">
      <div className="">
        <h3 className="text-lg mb-4 text-white font-bold">Life Events</h3>
        <div className="grid grid-cols-3">
          <p className="text-sm text-white col-span-2">
            Plan life events that may impact your journey to FI, such as buying
            a house, having a child, or taking a big vacation.
          </p>
          <div></div>
        </div>
      </div>
      <div className="grid grid-cols-2 mt-4 sm:mt-8 px-2 py-2">
        <div>
          <h3 className="text-lg mb-4 text-white font-bold">DIY</h3>
          <div className="">
            {diyLifeEvents.map((event, ix) => (
              <div
                className="flex items-center mb-2 border-1 p-4 cursor-pointer my-1 hover:bg-topia-gray"
                onClick={() => {
                  setLifeEvent(event.lifeEvent as any);
                }}
              >
                <p className="text-white">{event.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h3 className="text-lg mb-4 text-white font-bold">
            Common Life Events
          </h3>
          <div className="">
            {COMMON_LIFE_EVENTS.map((event, ix) => (
              <div
                className="flex items-center mb-2 border-1 p-4 cursor-pointer my-1 hover:bg-topia-gray"
                onClick={() => {
                  setLifeEvent(event.lifeEvent);
                }}
                key={ix}
              >
                <div className="mr-2">{event.icon}</div>
                <p className="text-white">{event.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function DiamondIllustration(props: {
  className?: string;
  svgProps?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="41"
      fill="none"
      viewBox="0 0 37 r1"
      className={props.className}
      {...props.svgProps}
    >
      <g
      //  clip-path="url(#a)"
      >
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M35.81 18.363H2.3l5.588-8.116h22.335l5.587 8.116Z"
        />
        <path
          fill="#0000D6"
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m7.888 10.247 2.79 4.061 2.797 4.055H2.301l2.797-4.055 2.79-4.061Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m19.055 40.667-8.377-11.152-8.377-11.152H35.81l-8.377 11.152-8.378 11.152Z"
        />
        <path
          fill="#0000D6"
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m19.055 10.247 2.79 4.061 2.797 4.055H13.475l2.79-4.055 2.79-4.061Zm11.168 0 2.797 4.061 2.79 4.055H24.643l2.79-4.055 2.79-4.061Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.055 40.667V18.363m0 22.304 11.168-22.304M19.102 40.667 7.928 18.363"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-miterlimit="10"
          d="m31.446 8.083 2.863-7.75M29.324 7.63l-.893-4.88m5.012 6.138 3.226-3.748"
        />
        <path
          fill="#C84897"
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.333 6.777c-1.918 0-3.472 2.097-3.472 4.68h-.053c0-2.583-1.553-4.68-3.47-4.68V6.71c1.917 0 3.47-2.324 3.47-4.907h.053c0 2.583 1.554 4.907 3.472 4.907v.067ZM35.81 29.408c-1.369 0-2.486 1.265-2.486 2.83h-.04c0-1.558-1.11-2.83-2.486-2.83v-.04c1.369 0 2.486-1.404 2.486-2.962h.04c0 1.558 1.11 2.963 2.486 2.963v.04Z"
        />
      </g>
      {/* <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h37v41H0z" />
        </clipPath>
      </defs> */}
    </svg>
  );
}

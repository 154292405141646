export function PlaidGlobeIllustration(props: {
  className?: string;
  svgProps?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="59"
      fill="none"
      viewBox="0 0 63 59"
      className={props.className}
      {...props.svgProps}
    >
      <g>
        <path
          fill="#DA3A9A"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M30.09 57.78c16.342 0 29.59-12.823 29.59-28.64S46.432.5 30.09.5.5 13.323.5 29.14s13.248 28.64 29.59 28.64Z"
        />
        <path
          fill="#75FBDE"
          stroke="#1D1D20"
          stroke-linejoin="round"
          d="M35.21 38.55c2.18 0 1.97-2.82-1.99-4.71-3.96-1.89-3.94-3.71-2.49-4.86 1.83-1.45 8.51-.6 8.89-2.29.38-1.69-5.28-.24-4.99-3.81.29-3.58 4.04-5.09 9.13-6.35.45-.11.88-.19 1.3-.24-3.51-3.96-8.62-6.47-14.33-6.47-2.72 0-5.31.58-7.66 1.6-.73 2.3-.89 4.78-.59 5.49.56 1.32 2.26 5.27 2.26 9.98 0 4.71-5.65 1.13-6.79 2.1-1.14.97-1.12 4.67-.16 6.14.86 1.32-1.83.75-5.13.21 2.62 7.46 9.71 12.81 18.07 12.81 1.54 0 3.02-.2 4.45-.54-6.26-3.35-2.15-9.06.02-9.06h.01Z"
        />
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M17.8 35.12c-.95-1.47-.98-5.17.16-6.14 1.14-.97 6.79 2.6 6.79-2.1s-1.69-8.66-2.26-9.98c-.3-.7-.15-3.19.59-5.49-6.77 2.96-11.51 9.7-11.51 17.57 0 2.23.4 4.36 1.1 6.35 3.3.54 5.99 1.11 5.13-.21Zm32.1-6.14c0-4.88-1.84-9.32-4.84-12.7-.42.06-.85.13-1.3.24-5.09 1.26-8.84 2.78-9.13 6.35-.29 3.58 5.36 2.12 4.99 3.81-.37 1.69-7.06.85-8.89 2.29-1.45 1.15-1.46 2.98 2.49 4.86 3.95 1.88 4.16 4.71 1.99 4.71s-6.28 5.71-.02 9.06c8.43-2.01 14.71-9.58 14.71-18.63v.01Z"
        />
        <path
          fill="#6B33EC"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M24.13 31.63c-1.05-.68-1.92-.62-2.72-.4-.65-1.94-2.46-3.35-4.62-3.35-2.47 0-4.5 1.84-4.83 4.23-.45-.22-.95-.35-1.48-.35-1.77 0-3.21 1.35-3.38 3.07H4.93a2.121 2.121 0 0 0 0 4.24h17.66c1.97 0 3.58-1.6 3.58-3.58 0-1.54-.74-3.03-2.03-3.86h-.01Zm34.41-7.23c-.44-.71-1.19-1.2-2.06-1.3.27-.79.33-1.67-.26-2.68-.59-1.01-1.61-1.72-2.76-1.76a3.355 3.355 0 0 0-3.48 3.35c0 .18.03.36.05.53-.76-.48-1.72-.69-2.73-.4-1.2.34-2.14 1.37-2.36 2.6a3.355 3.355 0 0 0 3.3 3.97h11.58c1.19 0 2.15-.96 2.15-2.15s-.96-2.15-2.15-2.15h-1.27l-.01-.01Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-miterlimit="10"
          d="M37.86 34.09c.46.32.94.63 1.45.93 1.04.61 2.21 1.19 3.56 1.71 2.72 1.06 6.17 1.9 10.83 2.39M15.38 22.81c2.37-.16 4.98.13 7.79 1.16 6.25 2.27 8.93 5.43 12.35 8.32M1.47 29.93s3.78-4.6 10.03-6.43m37.54 9.71c1.67.36 3.54.65 5.66.88M29.87 21.77c1.7 1.12 3.08 2.32 4.42 3.52 2.79 2.49 5.43 4.98 10.51 6.75M2.47 24.9s8.75-10.66 21.7-5.96c1.05.38 2.01.79 2.88 1.22"
        />
      </g>
    </svg>
  );
}

import classNames from "classnames";
import { Fragment, useState } from "react";
import { appActions, useAppDispatch, useAppSelector } from "../store";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CogIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useAuthContext } from "../auth";
import { Sidebar } from "./Sidebar";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { TinkerBanner } from "./TinkerBanner";
import { TopiaLogo } from "./core/TopiaLogo";
import { Footer } from "./Footer";

export function ApplicationShell(props: { children: any }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isTinkerMode = useAppSelector((state) => !!state.app.tinkerData);
  const authCtx = useAuthContext();

  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    {
      name: "FI Plan",
      href: "/",
      current: location?.pathname === "/",
    },
    {
      name: "Portfolio",
      href: "/portfolio",
      current: location?.pathname === "/portfolio",
    },
    {
      name: "Investment Rate",
      href: "/savings-rate",
      current: location?.pathname === "/savings-rate",
    },
    // {
    //   name: "Become an FI-Preneur",
    //   href: "https://topia-app.com/become-a-fipreneur",
    //   current:
    //     location?.pathname === "https://topia-app.com/become-a-fipreneur",
    // },
    // {
    //   name: "Community",
    //   href: "/community",
    //   current: location?.pathname === "/community",
    // },
  ];

  return (
    <>
      <TinkerBanner />
      <header className="topia-bounce-in">
        <div className="flex flex-shrink-0 items-center px-4 pr-8 pt-6 pb-6 border-b border-white/20 mx-4 mb-4">
          <div className="mr-16 ml-4" style={{ width: 92 }}>
            <TopiaLogo svgProps={{ fill: "white" }} />
          </div>
          {/* <img
            className="h-8 w-auto mr-8"
            src="/Topia_Logo-PrimaryBlack.png"
            alt="Topia"
        /> */}
          <nav className="flex-1">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current)?.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
                {/* <option>
                  <a
                    href="https://topia-app.com/become-a-fipreneur"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Become an FI-Prenuer
                  </a>
                </option> */}
              </select>
            </div>
            <div className="hidden sm:block flex-1 items-center flex justify-center align-center">
              <nav
                className="flex space-x-4 items-center flex-12 justify-center align-center"
                aria-label="Tabs"
              >
                {tabs.map((tab) => (
                  <Link
                    key={tab.name}
                    to={tab.href}
                    className={classNames(
                      tab.current
                        ? "bg-pale-yellow rounded-xl text-black border border-black"
                        : "text-white hover:text-white",
                      "px-3 py-2 font-bold text-xs lg:text-sm transition-all rounded-xl"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </Link>
                ))}
                <a
                  href="https://topia-app.com/become-a-fipreneur"
                  target="_blank"
                  className="text-white text-xs font-bold px-3 py-2 lg:text-sm transition-all rounded-xl"
                  rel="noreferrer"
                >
                  Become an FI-Prenuer
                </a>
              </nav>
            </div>
          </nav>
          <a className="cursor-pointer ml-4">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-8 h-8 topia-future-blue/20 rounded-full">
                  <CogIcon className="w-6 h-6 text-white" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-black">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={classNames(
                            active ? "bg-pale-yellow text-black" : "text-white",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={() => {
                            navigate("/settings");
                          }}
                        >
                          Account settings
                        </a>
                      )}
                    </Menu.Item>
                    <form
                      onSubmit={() => {
                        authCtx.signOut();
                      }}
                    >
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="submit"
                            className={classNames(
                              active
                                ? "bg-pale-yellow text-black"
                                : "text-white",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </form>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </a>
        </div>
      </header>
      <div className="flex h-full">
        {/* Static sidebar for desktop */}
        {location?.pathname === "/" && (
          <div className="hidden lg:flex lg:flex-shrink-0 h-full 2xl:w-1/5 w-3/12">
            <div className="flex w-full flex-col topia-bounce-in">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              {/* <Card> */}
              <Sidebar />
              {/* </Card> */}
            </div>
          </div>
        )}
        <div className="flex min-w-0 flex-1 flex-col">
          <div className="relative z-0 flex flex-1 ">
            <main className="relative z-0 flex-1 focus:outline-none xl:order-last">
              {/* Start main area*/}
              <div className="absolute inset-0 py-6 pt-4 px-4">
                {props.children}
              </div>
              {/* End main area */}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

export const createPlaidToken = (data) =>
  httpsCallable(functions, "plaid-createLinkToken")(data);

export const createLinkRefreshToken = (data) =>
  httpsCallable(functions, "plaid-createLinkRefreshToken")(data);
export const refreshPlaidData = (data) =>
  httpsCallable(functions, "plaid-refreshData")(data);
export const setPlaidAccessToken = (data) =>
  httpsCallable(functions, "plaid-setUserAccessToken")(data);
export const finalizePlaidUserConnection = (data) =>
  httpsCallable(functions, "plaid-finalizeUserConnection")(data);
export const disconnectPlaid = (data?: any) =>
  httpsCallable(functions, "plaid-disconnectPlaid")(data);
export const storeUserAccounts = (data) =>
  httpsCallable(functions, "plaid-storeUserAccounts")(data);
export const setPrimaryIcomeAccount = (data) =>
  httpsCallable(functions, "plaid-setPrimaryIcomeAccount")(data);
export const setPrimaryIcomeTransactions = (data) =>
  httpsCallable(functions, "plaid-setPrimaryIcomeTransactions")(data);
export const connectPlaidRecentlyAddedAccounts = (data) =>
  httpsCallable(functions, "plaid-connectRecentlyAddedAccounts")(data);

export const getPrimaryIncomeTransactions = (data?) =>
  httpsCallable(functions, "plaid-getPrimaryIncomeTransactions")(data);
export const finalizePlaidOnboarding = (data) =>
  httpsCallable(functions, "plaid-finalizePlaidOnboarding")(data);
export const getInstitutions = (data) =>
  httpsCallable(functions, "plaid-getInstitutions")(data);

export const createInvestmentToken = (data) =>
  httpsCallable(functions, "openInvestment-createLinkToken")(data);
export const updateInvestmentAccounts = (data) =>
  httpsCallable(functions, "openInvestment-updateInvestmentAccounts")(data);

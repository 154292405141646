import { CheckIcon } from "@heroicons/react/24/outline";
import {
  Income,
  LifeEvent,
  UserModel,
} from "@byundefined/topia-model/lib/commonTypes";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useModalContext } from "../context/ModalContext";
import { dateToDashStringYMD, uuidv4 } from "../helpers";
import { appActions, useAppDispatch, useAppSelector } from "../store";
import { LifeEventForm } from "../components/LifeEventForm";
import classNames from "classnames";
import { ACCOUNT_TYPES } from "@byundefined/topia-model/lib/constants/accountConstants";
import { Card } from "../components/core/Card";
import { Link } from "react-router-dom";
import { TopiaButton } from "../components/core/TopiaButton";

export function AddAccountManualPrimer(props: {}) {
  const appDispatch = useAppDispatch();
  const modalCtx = useModalContext();

  const appData = useAppSelector(
    (state) => state.app.tinkerData || state.app.userData
  );

  const user = appData.user;
  const settings: UserModel["settings"] = user?.settings || ({} as any);

  const options: {
    label: string;
    description: any;
    type: string;
  }[] = [
    // TODO: Double check with Logan
    // Doesn't make sense to manually add spending accounts?
    // {
    //   label: "Everyday spending",
    //   description: <p></p>,
    //   type: "standard",
    // },
    {
      label: "Savings & Investment",
      description: <p></p>,
      type: "saving",
    },
    {
      label: "Debts & Liabilities",
      description: <p></p>,
      type: "debt",
    },
    {
      label: "Property",
      description: <p></p>,
      type: "debt?subType=mortgage",
    },
  ];

  const activeSetting = options.find((o) => o.type === "standard");

  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <Card>
        <div className="p-2">
          <h3 className="text-2xl text-white text-center">Add Account</h3>
          <div className="mt-8 space-y-4 lg:grid lg:grid-cols-3 lg:gap-x-4 lg:space-y-0 p-4">
            {options.map((tier) => (
              <div
                key={tier.description}
                className="relative flex flex-col -2xl border border-gray-200  p-4 shadow-sm rounded-xl"
              >
                <div className="flex-1">
                  <p className="text-md text-center my-4 font-semibold text-white">
                    {tier.label}
                  </p>
                  {/* <p className="mt-4 flex items-baseline text-white">
                <span className="text-5xl font-bold tracking-tight">
                  ${tier.price}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  {tier.frequency}
                </span>
              </p> */}
                  <p className="mt-0 text-white">{tier.description}</p>

                  {/* Feature list */}
                  {/* <ul role="list" className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-blue-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-white">{feature}</span>
                  </li>
                ))}
              </ul> */}
                </div>

                <Link to={`/add-account-manual/${tier.type}`} className="w-full">
                  <TopiaButton btnStyle="primary" className="w-full my-2">Add Account</TopiaButton>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
}

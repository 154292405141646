import { useDataContext } from "../context/DataContext";
import { useModalContext } from "../context/ModalContext";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";
import { useContext, useEffect, useState } from "react";
import {
  createPlaidToken,
  connectPlaidRecentlyAddedAccounts,
  createInvestmentToken,
  updateInvestmentAccounts,
} from "../api";
import { Card } from "../components/core/Card";
import { Link, useNavigate } from "react-router-dom";
import useYodlee from "../hooks/useYodlee";
import { TopiaButton } from "../components/core/TopiaButton";
import { appActions, useAppDispatch } from "../store";
import { trackAnalyticsEvent } from "../analytics";
import { FullScreenTopiaLoader } from "../components/core/TopiaLoader";
import { PlaidGlobeIllustration } from "../components/core/illustrations/PlaidGlobeIllustration";
import { PlaidGlassesIllustration } from "../components/core/illustrations/PlaidGlassesIllustration";
import { PlaidLockIllustration } from "../components/core/illustrations/PlaidLockIllustration";

interface YodleeTokenResp {
  fastLinkURL: string;
  token: string;
}

function OpenPlaidButton(props: { token: string; region: string }) {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const dataCtx = useDataContext();

  const config: PlaidLinkOptions = {
    onSuccess: async (public_token, metadata) => {
      setIsGlobalLoading(true);

      // TODO: Error handling
      const result = await connectPlaidRecentlyAddedAccounts({
        public_token,
        region: props.region, //TODO: make this dynamic
        metadata: metadata,
      });

      // @ts-ignore
      appDispatch(appActions.setPendingAccounts(result.data.accounts));

      navigate("/confirm-accounts");

      setIsGlobalLoading(false);
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.token,
    //required for OAuth; if not using OAuth, set to null or omit:
    // receivedRedirectUri: redirect_uri,
  };

  const { open, exit, ready } = usePlaidLink(config);

  if (isGlobalLoading) {
    return <FullScreenTopiaLoader />;
  }

  return (
    <TopiaButton
      onClick={() => {
        open();
      }}
      loading={!props.token}
      btnStyle="future"
    >
      {props.region === "gb"
        ? "Connect Every day spending Account"
        : "Connect Account"}
    </TopiaButton>
  );
}

function OpenYodleeButton(props: { tokenData: YodleeTokenResp }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
  const appDispatch = useAppDispatch();

  const { ready, init, data, active } = useYodlee({
    containerId: "container-fastlink",
    onSuccess: async () => {
      setIsGlobalLoading(true);

      const response = await updateInvestmentAccounts({
        token: props.tokenData.token,
      });

      // @ts-ignore
      appDispatch(appActions.setPendingAccounts(response.data.accounts));
      navigate("/confirm-accounts");

      setIsGlobalLoading(false);
    },
    fastLinkOptions: {
      fastLinkURL:
        "https://fl4.production.yodlee.uk/authenticate/UKProd-132/fastlink?channelAppName=ukdevxprod",
      configName: "topiayodlee",
      token: {
        tokenType: "AccessToken",
        tokenValue: props.tokenData.token,
      },
    },
  });

  if (isGlobalLoading) {
    return <FullScreenTopiaLoader />;
  }

  // Use hardcoded styles as div always needs to be available (instead of using js modal)
  return (
    <div className="mx-2">
      <div className={!active ? "hidden" : ""}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg   text-left shadow-xl transition-all sm:my-2 sm:w-full sm:max-w-4xl ">
              <div>
                <div id="container-fastlink"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopiaButton
        onClick={() => init()}
        loading={loading || !ready}
        btnStyle="future"
      >
        Connect Saving, Investment & Debt Account
      </TopiaButton>
    </div>
  );
}

export function AddAccount(props: {}) {
  const [linkToken, setLinkToken] = useState<string | undefined>(undefined);
  const [yodleeToken, setYodleeToken] = useState<YodleeTokenResp>();
  const dataCtx = useDataContext();

  const isUk = global.region === "gb";

  // Chad's code here!

  const list = [
    {
      title: "What is open banking ?",
      content: (
        <p className="text-white text-md">
          Simply put, open banking enables you to effortlessly see all of your
          finances in one place. Fundamentally, this improves consumers’
          experience and the value they get from specific banking services, by
          letting them share their data with third-party providers securely
          <p>{"\n\n"}</p>
          Open banking plays an important role in Topia, allowing our users to
          track and monitor their networth in real-time without having to
          manually input their financial data. Furthermore, open banking enables
          our users to quickly and easily track their savings rate.
        </p>
      ),
    },
    {
      title: "Your Login details are Secure",
      content: (
        <p className="text-white">
          Topia does not gain access to any of your online banking login
          details, these remain strictly confidential and private.
        </p>
      ),
    },
    {
      title: "Your Data is Private",
      content: (
        <p className="text-white">
          For both Topia and Plaid, security and data privacy is everything. All
          sensitive financial data is encrypted, regular risk assessments and
          penetration tests are carried out to ensure all sensitive data is
          secure.
          <p>{"\n\n"}</p>
          <p className="text-white">Plaid</p>
          <p>{"\n\n"}</p>
          Plaid regularly undergoes both internal and external network
          penetration tests, and third-party code reviews. Plaid also maintains
          a SOC 2 Type II report by testing the design and operational
          effectiveness of our Information Security program using independent
          auditors. rate.
          <p>{"\n\n"}</p>
          <p className="text-white">Topia</p>
          <p>{"\n\n"}</p>
          Topia automatically encrypts all data. The data is automatically and
          transparently decrypted when read by an authorized user. Topia’s data
          and metadata is encrypted under the 256-bit Advanced Encryption
          Standard, and each encryption key is itself encrypted with a regularly
          rotated set of master keys. While data is in transit TLS 1.2 is
          enabled. 2-factor authentication is required for all authorised users.
        </p>
      ),
    },
    {
      title: "Disconnect at any time",
      content: (
        <p className="text-white">
          You can disconnect any of your accounts at any time.
        </p>
      ),
    },
  ];

  const modalCtx = useModalContext();
  const plaidModalBody = (
    <div>
      {list.map((info, indx) => (
        <div key={indx} className="mb-2">
          <h2 className="text-white text-lg my-2">{info.title}</h2>
          {info.content}
        </div>
      ))}
    </div>
  );
  const showModalHandler = () => {
    modalCtx.openModal(plaidModalBody);
  };

  // Chad's code complete

  useEffect(() => {
    trackAnalyticsEvent("add_account_page_visited");
  }, []);

  useEffect(() => {
    if (!dataCtx.user?.region) return;

    let _unmounted = false;
    async function getPlaidLinkToken() {
      if (_unmounted) return;

      console.log("Calling");
      const tokenResponse = await createPlaidToken({
        region: dataCtx.user?.region,
        platform: "web",
        // redirect_uri: redirect_uri,
      });

      // @ts-ignore
      setLinkToken(tokenResponse.data.link_token);
      // setLinkToken(token.);
    }

    getPlaidLinkToken();

    return () => {
      _unmounted = true;
    };
  }, [dataCtx.user?.region]);

  useEffect(() => {
    if (!dataCtx.user?.region) return;

    let _unmounted = false;
    async function getYodleeToken() {
      if (_unmounted) return;

      const tokenData = await createInvestmentToken(undefined);

      setYodleeToken(tokenData.data as any);
    }

    if (dataCtx.user?.region === "gb") {
      getYodleeToken();
    }

    return () => {
      _unmounted = true;
    };
  }, [dataCtx.user?.region]);

  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <div className="mb-4 flex w-full items-center justify-center">
        <div className="text-xl text-white">Add Account</div>
      </div>
      <Card className="p-4">
        <div className="px-12 my-4">
          <p className="text-center text-topia-gray my-2">Powered By</p>
          <div className="flex items-center my-4 p-8 bg-white rounded-xl">
            {isUk ? (
              <>
                <img src="/yodlee.png" />
                <p className="flex-1 text-center text-topia-gray text-xl">
                  &amp;
                </p>
                <img src="/plaid.png" style={{ width: 150, height: "auto" }} />
              </>
            ) : (
              <>
                <img
                  src="/plaid.png"
                  style={{ width: 150, height: "auto", margin: "auto" }}
                />
              </>
            )}
          </div>
          <div className="flex items-center my-8">
            <div style={{ width: 65 }}>
              <PlaidGlobeIllustration />
            </div>
            {isUk ? (
              <div className="ml-4">
                <p className="font-strong text-white text-lg">
                  35M people use yodlee
                </p>
                <p className="text-white text-lg">
                35m people around the world use Yodlee to connect 400mn+ accounts safely.  1 in 4 adults use Plaid
                </p>
              </div>
            ) : (
              <div className="ml-4">
                <p className="font-strong text-white text-lg">
                  1 in 4 adults use Plaid
                </p>
                <p className="text-white text-lg">
                  1 in 4 US adults has connected a financial account via Plaid
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center my-8">
            <div style={{ width: 65 }}>
              <PlaidGlassesIllustration />
            </div>
            {isUk ? (
              <div className="ml-4">
                <p className="font-strong text-white text-lg">Read Only</p>
                <p className="text-white text-lg text-light">
                  It is impossible for Yodlee or Plaid to transfer any money in or out of
                  your account. They simply aggregate data.
                </p>
              </div>
            ) : (
              <div className="ml-4">
                <p className="font-strong text-white text-lg">Read Only</p>
                <p className="text-white text-lg text-light">
                  It is impossible for Plaid to transfer any money in or out of
                  your account. Plaid simply aggregates data.
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center my-8">
            <div style={{ width: 65 }}>
              <PlaidLockIllustration />
            </div>
            {isUk ? (
              <div className="ml-4">
                <p className="font-strong text-white text-lg">Military grade</p>
                <p className="text-white text-lg">
                  Yodlee &amp; Plaid use 256-bit encryption to connect to your institution
                </p>
              </div>
            ) : (
              <div className="ml-4">
                <p className="font-strong text-white text-lg">Military grade</p>
                <p className="text-white text-lg">
                  Plaid uses 256-bit encryption to connect to your institution
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-center align-center">
          <OpenPlaidButton token={linkToken} region={dataCtx.user?.region} />
          {yodleeToken && <OpenYodleeButton tokenData={yodleeToken} />}
        </div>
      </Card>

      <div className="mt-4">
        <Link
          to="/add-account-manual"
          type="button"
          className="relative block w-full border-2 rounded-xl border-dashed p-3 text-center hover:border-gray-400 border-gray-300 focus:outline-none"
        >
          <span
            className="block text-future-blue text-sm font-medium text-white"
            style={{ textTransform: "uppercase" }}
          >
            Add account manually
          </span>
        </Link>
      </div>

      {global.region === "gb" && (
        <>
          <p className="text-center text-slate-500 mt-4">
            We are an agent of Plaid Financial Ltd. (FCA Firm Reference Number:
            804718). Plaid securely links to your UK bank account and provides
            you with regulated account information services through us as its
            agent.
          </p>
          <div className="flex justify-center">
            <a
              href="#"
              onClick={showModalHandler}
              className="text-royal-purple underline cursor-pointer"
            >
              More Info
            </a>
          </div>
        </>
      )}
    </div>
  );
}
//Uk bank account or US?

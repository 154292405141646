import classNames from "classnames";

export function TopiaToggle(props: {
  className?: string;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  id: string;
  label: string;
}) {
  return (
    <div className={classNames("flex items-center", props.className)}>
      <label
        htmlFor={props.id}
        className="block text-sm font-medium text-white"
      >
        {props.label}
      </label>
      <div className="mt-1">
        <input
          {...props.inputProps}
          type="checkbox"
          id={props.id}
          className="ml-2   border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useContext } from "react";
import { TopiaPromptModal, TopiaPromptModalProps } from "../components/TopaPromptModal";

export const ModalContext = React.createContext<{
  modal: React.ReactElement | undefined;
  open: boolean;
  openModal: (el: JSX.Element | undefined, options?: {}) => void;
  prompt(props: TopiaPromptModalProps): void;
  closeModal: () => void;
}>(undefined as any);

export function ModalProvider(props: { children: any }) {
  const [modal, setModal] = useState<React.ReactElement | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  function prompt(props: TopiaPromptModalProps) {
    setModal(<TopiaPromptModal  {...props} />);
    setOpen(true);
  }

  return (
    <ModalContext.Provider
      value={{
        modal,
        open,
        prompt,
        openModal: (body) => {
          setModal(body);
          setOpen(true);
        },
        closeModal: () => {
          setOpen(false);
        },
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}


export const useModalContext = () => useContext(ModalContext);

import { Account } from "@byundefined/topia-model/lib/commonTypes";
import { ACCOUNT_TYPES } from "@byundefined/topia-model/lib/constants/accountConstants";
import { filter } from "d3";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import {
  formatCurrency,
  getAccountsByType,
  getLastMonthTopup,
} from "../helpers";
import { useAppSelector } from "../store";

export function AccountList(props: {}) {
  const accountTypes = ["standard", "saving", "debt", "property"];
  const accounts = useAppSelector((state) => state.app.userData.accounts);

  const filteredAccounts = accounts.filter((acct) => !acct.from);

  const accountsByType = getAccountsByType(filteredAccounts);
  const accountTypeLabels = {
    standard: "Everyday Spending",
    saving: "Savings & Investments",
    debt: "Debt",
    property: "Property",
  };

  return accounts.length > 0 ? (
    <ul role="list" className="mt-2">
      {accountsByType.map((at) =>
        at.accounts.length == 0 ? (
          <></>
        ) : (
          <li key={at.type} className="mb-4 mt-2 ">
            <p className="mt-2 mb-1 text-lg text-white px-2">
              {accountTypeLabels[at.type]}
            </p>
            <ul role="list">
              {at.accounts.map((acct) => (
                <li
                  key={acct.id}
                  className="border-white/20 border-b last:border-0 "
                >
                  <Link
                    to={`/account/${acct.id}`}
                    className="hover:bg-topia-black active:ring-2 ring-future-blue py-1 cursor-pointer block py-3 px-2"
                  >
                    <div className="flex w-full">
                      <p className="text-sm font-medium text-white">
                        {acct.name}
                      </p>

                      <div className="flex-1"></div>
                      <div className="flex w-half">
                        {/* <p className="text-sm text-green-500 ml-2 flex-1">
                        {formatCurrency(getLastMonthTopup(acct))}
                      </p> */}
                        <p className="text-sm text-white ml-2 flex-1">
                          {formatCurrency(acct.amount)}
                        </p>
                      </div>
                      {/* <p className="text-sm font-medium text-white">{at.name}</p>
                <p className="text-sm text-white">{formatCurrency(at.amount)}</p> */}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        )
      )}
    </ul>
  ) : (
    <></>
  );
}

import { Menu, Transition } from "@headlessui/react";
import { ArrowRightIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { CurrencyDollarIcon, UserIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useModalContext } from "../context/ModalContext";
import { formatCurrency } from "../helpers";
import { appActions, store, useAppDispatch, useAppSelector } from "../store";
import { AccountForm } from "./AccountForm";
import { DataPoint } from "./core/DataPoint";
import { TopiaDataList } from "./core/TopiaDataList";
import { FITimeDifference } from "./FITimeDifference";
import { LifeEventIllustration } from "./core/illustrations/LifeEventIllustration";
import { LifeEventForm } from "./LifeEventForm";
import { NewLifeEventPopup } from "./NewLifeEventPopup";

export function PortfolioLifeEventsList(props: {}) {
  const modalCtx = useModalContext();
  const appDispatch = useAppDispatch();

  const oldLifeEvents = useAppSelector((state) =>
    (state.app.userData?.lifeEvents || []).filter((le) => !!le.asset)
  );

  const tinkerLifeEvents = useAppSelector((state) =>
    state.app.tinkerData?.lifeEvents.filter((le) => !!le.asset)
  );

  console.log("oldLifeEvents", oldLifeEvents);

  const futureAccounts = useAppSelector(
    (state) => (state.app.tinkerData || state.app.userData).accounts || []
  ).filter((a) => !!a.from);

  const list = [...(tinkerLifeEvents || oldLifeEvents), ...futureAccounts].filter((x) => !x.disabled);

  // console.log(oldLifeEvents);

  return (
    <TopiaDataList
      data={list}
      sourceData={oldLifeEvents}
      getLabel={(le) => le.name}
      renderRow={(le, matching) => {
        return (
          <>
            <p
              className={classNames(
                "text-sm font-medium truncate",
                le.disabled ? "text-white/50" : "text-white"
              )}
            >
              {le.name}
            </p>
            <p
              className={classNames(
                "mx-2 text-sm font-medium truncate",
                le.disabled ? "text-white/50" : "text-topia-gray"
              )}
            >
              <span>{le.from}</span>
            </p>
            {!le.amount && (
              <div className="ml-2">
                <FITimeDifference
                  diff={{
                    type: "life-event",
                    lifeEvent: le as any,
                  }}
                />
              </div>
            )}
            <div className="flex-1"></div>
            <div className="flex items-center">
              {le.increasedSpend && (
                <DataPoint
                  changeIndicatorPosition="left"
                  className={classNames(
                    "ml-4",
                    le.disabled ? "opacity-50" : ""
                  )}
                  // label="Amount"
                  ogValue={matching?.increasedSpend}
                  inverseColors={true}
                  value={le.increasedSpend}
                  type="currency"
                  suffix={!!le.to ? "/ month" : undefined}
                  editable
                  onFinishEditing={(val) => {
                    appDispatch(
                      appActions.updateTinkerLifeEvent({
                        ...(le as any),
                        increasedSpend: val,
                      } as any)
                    );
                  }}
                />
              )}
              {le.increasedIncome && (
                <DataPoint
                  changeIndicatorPosition="left"
                  className={classNames(
                    "ml-4",
                    le.disabled ? "opacity-50" : ""
                  )}
                  // label="Amount"
                  ogValue={matching?.increasedIncome}
                  inverseColors={true}
                  value={le.increasedIncome}
                  type="currency"
                  suffix={!!le.to ? "/ month" : undefined}
                  editable
                  onFinishEditing={(val) => {
                    appDispatch(
                      appActions.updateTinkerLifeEvent({
                        ...le,
                        increasedIncome: val,
                      })
                    );
                  }}
                />
              )}
            </div>
          </>
        );
      }}
      submenu={(x) => [
        {
          body: <p>Edit</p>,
          onClick: (le) => {
            if (le.amount) {
              // Account
              modalCtx.openModal(
                <AccountForm
                  account={le as any}
                  isFuture
                  onSubmit={async (vals) => {
                    appDispatch(appActions.updateTinkerAccount(vals as any));
                    modalCtx.closeModal();
                  }}
                />
              );
            } else {
              modalCtx.openModal(
                <LifeEventForm
                  lifeEvent={le as any}
                  onSubmit={(result) => {
                    appDispatch(appActions.updateTinkerLifeEvent(result));
                    modalCtx.closeModal();
                  }}
                />
              );
            }
          },
        },
        {
          body: <p>Delete</p>,
          onClick: (le) => {
            if (le.amount) {
              appDispatch(appActions.deleteTinkerAccount(le));
            } else {
              appDispatch(appActions.deleteTinkerLifeEvent(le));
            }
          },
        },
      ]}
    />
  );

  // return (
  //   <ul role="list" className="">
  //     {lifeEvents.length > 0 ? (
  //       (lifeEvents || []).map((le) => {
  //         return (
  //           <li key={le.id} className={classNames("p-2")}>
  //             <div className="flex items-center space-x-4">
  //               <div className="min-w-0 flex-1 mb-2 flex">
  //                 <p
  //                   className={classNames(
  //                     "text-sm font-medium text-white truncate",
  //                     le.disabled && "opacity-50"
  //                   )}
  //                 >
  //                   <span className="mr-2">
  //                     {/* @ts-ignore */}
  //                     {le.icon
  //                       ? // @ts-ignore
  //                         le.icon
  //                       : "🔵"}
  //                   </span>
  //                   {le.name}
  //                 </p>
  //                 <FITimeDifference
  //                   className={classNames(
  //                     "mx-2 ml-4",
  //                     le.disabled && "opacity-50"
  //                   )}
  //                   diff={{
  //                     type: "life-event",
  //                     lifeEvent: le,
  //                   }}
  //                 />
  //                 <div className="flex-1"></div>
  //                 <Menu as="div" className="relative inline-block text-left">
  //                   <div>
  //                     <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-white hover:bg-topia-gray focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
  //                       {/* Options
  //           <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}
  //                       <Bars3Icon className="w-6 h-6 text-white" />
  //                     </Menu.Button>
  //                   </div>

  //                   <Transition
  //                     as={Fragment}
  //                     enter="transition ease-out duration-100"
  //                     enterFrom="transform opacity-0 scale-95"
  //                     enterTo="transform opacity-100 scale-100"
  //                     leave="transition ease-in duration-75"
  //                     leaveFrom="transform opacity-100 scale-100"
  //                     leaveTo="transform opacity-0 scale-95"
  //                   >
  //                     <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
  //                       <div className="py-1">
  //                         <Menu.Item>
  //                           {({ active }) => (
  //                             <a
  //                               onClick={() => {
  //                                 modalCtx.openModal(
  //                                   <LifeEventForm
  //                                     lifeEvent={le}
  //                                     onSubmit={(result) => {
  //                                       appDispatch(
  //                                         appActions.updateTinkerLifeEvent(
  //                                           result
  //                                         )
  //                                       );
  //                                       modalCtx.closeModal();
  //                                     }}
  //                                   />
  //                                 );
  //                               }}
  //                               className={classNames(
  //                                 active
  //                                   ? "bg-gray-100 text-white"
  //                                   : "text-white",
  //                                 "block px-4 py-2 text-sm"
  //                               )}
  //                             >
  //                               Edit
  //                             </a>
  //                           )}
  //                         </Menu.Item>
  //                         <Menu.Item>
  //                           {({ active }) => (
  //                             <a
  //                               className={classNames(
  //                                 active
  //                                   ? "bg-gray-100 text-white"
  //                                   : "text-white",
  //                                 "block px-4 py-2 text-sm"
  //                               )}
  //                             >
  //                               Plan a change
  //                             </a>
  //                           )}
  //                         </Menu.Item>
  //                         <Menu.Item>
  //                           {({ active }) => (
  //                             <button
  //                               onClick={() => {
  //                                 appDispatch(
  //                                   appActions.updateTinkerLifeEvent({
  //                                     ...le,
  //                                     disabled: !le.disabled,
  //                                   })
  //                                 );
  //                               }}
  //                               className={classNames(
  //                                 active
  //                                   ? "bg-gray-100 text-white"
  //                                   : "text-white",
  //                                 "block w-full px-4 py-2 text-left text-sm"
  //                               )}
  //                             >
  //                               {le.disabled ? "Enable" : "Disable"}
  //                             </button>
  //                           )}
  //                         </Menu.Item>
  //                         <Menu.Item>
  //                           {({ active }) => (
  //                             <button
  //                               onClick={() => {
  //                                 appDispatch(
  //                                   appActions.deleteTinkerLifeEvent(le)
  //                                 );
  //                               }}
  //                               className={classNames(
  //                                 active
  //                                   ? "bg-gray-100 text-white"
  //                                   : "text-red-500",
  //                                 "block w-full px-4 py-2 text-left text-sm"
  //                               )}
  //                             >
  //                               Delete
  //                             </button>
  //                           )}
  //                         </Menu.Item>
  //                       </div>
  //                     </Menu.Items>
  //                   </Transition>
  //                 </Menu>
  //               </div>
  //             </div>
  //           </li>
  //         );
  //       })
  //     ) : (
  //       <></>
  //     )}
  //   </ul>
  // );
}

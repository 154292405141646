import { useState, useEffect } from "react";
import { TopiaButton } from "../components/core/TopiaButton";
import { Card } from "../components/core/Card";
import { DashboardChart } from "../components/DashboardChart";
import { FIBaseSettings } from "../components/FIBaseSettings";
import { useDataContext } from "../context/DataContext";
import { SpendingLifeEventsList } from "../components/SpendingLifeEventsList";
import { appActions, useAppDispatch, useAppSelector } from "../store";
import { useModalContext } from "../context/ModalContext";
import { LifeEventForm } from "../components/LifeEventForm";
import { CurrencyDollarIcon, FireIcon } from "@heroicons/react/24/solid";
import { IncomeForm } from "../components/IncomeForm";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { IncomeCard } from "../components/IncomeCard";
import { NewLifeEventPopup } from "../components/NewLifeEventPopup";
import { Income } from "@byundefined/topia-model/lib/commonTypes";
import classNames from "classnames";
import { SavingsRateChart } from "../components/SavingsRateChart";
import { PortfolioEventList } from "../components/PortfolioEventList";
import { NewPortfolioEventPopup } from "../components/NewPortfolioEventPopup";
import { FIStats } from "../components/FIStats";
import { SurplusWarning } from "../components/SurplusWarning";
import { Navigate } from "react-router-dom";
import { TopiaBannerCTA } from "../components/core/TopiaBannerCTA";
import { LifeEventIllustration } from "../components/core/illustrations/LifeEventIllustration";
import { BriefcaseIllustration } from "../components/core/illustrations/BriefcaseIllustration";
import { FullScreenTopiaLoader } from "../components/core/TopiaLoader";
import { WelcomeToTopiaModal } from "../components/WelcomeToTopiaModal";
import { PortfolioLifeEventsList } from "../components/PortfolioLifeEventsList";
import { trackAnalyticsEvent } from "../analytics";
import { Footer } from "../components/Footer";
import { useAuthContext } from "../auth";

export function DashboardPage(props: {}) {
  const isTinkerMode = useAppSelector((state) => !!state.app.tinkerData);
  const modalCtx = useModalContext();
  const userData = useAppSelector((state) => state.app.userData);
  const tinkerData = useAppSelector((state) => state.app.tinkerData);
  const appData = tinkerData || userData;
  const authCtx = useAuthContext();
  const [completedInitialLoad, setCompletedInitialLoad] = useState(false);


  useEffect(() => {
    trackAnalyticsEvent("dashboard_page_visited");
  }, []);

  useEffect(() => {
    if ((!authCtx.initialLoading && authCtx.user && userData.user) || userData.modelResult.fullModel) {
      setCompletedInitialLoad(true);
    }
  }, [userData.modelResult, authCtx.user]);

  if (!completedInitialLoad) {
    return <FullScreenTopiaLoader />;
  }

  if (userData.user && !userData.user.settings?.dateOfBirth) {
    return <Navigate to="/onboarding" />;
  }

  return (
    <>
      {/* @ts-ignore */}
      {!userData.user.hasOnboarded && userData.accounts.length === 0 && (
        <WelcomeToTopiaModal />
      )}
      <div
        className={classNames(
          "lg:flex lg:flex-col topia-bounce-in",
          isTinkerMode && "mb-40"
        )}
      >
        <div className="flex-1">
          <div className="mb-4">
            <div className="lg:flex">
              <div className="flex flex-col flex-1 ml-2">
                <p className="text-lg text-white my-2">Your Plan</p>
                <Card className="p-4">
                  <FIStats
                    className="w-full"
                    userData={{
                      modelResult: userData.modelResult,
                      settings: userData.user?.settings,
                    }}
                    tinkerData={
                      tinkerData
                        ? {
                            modelResult: tinkerData.modelResult,
                            settings: tinkerData.user.settings,
                          }
                        : undefined
                    }
                  />
                  <DashboardChart />
                </Card>

                <div className="mt-4">
                  <SavingsRateChart />
                </div>
              </div>

              <div className="flex-1 ml-6">
                <p className="text-lg text-white mt-2 px-2 mb-2">FI Settings</p>
                <Card className="mb-4">
                  <FIBaseSettings className="" />
                </Card>
                <IncomeCard />
                <div className="flex flex-col mt-4">
                  <p className="text-lg text-white mt-2 px-2 mb-2">
                    Life Events
                  </p>
                  {(appData.lifeEvents || []).length > 0 ? (
                    <SpendingLifeEventsList />
                  ) : (
                    <SpendingLifeEventsList />
                  )}
                  <div className="flex-1"></div>
                  <TopiaBannerCTA
                    analyticsEvent="spending_life_event_cta_clicked"
                    color="pale-yellow"
                    illustration={<LifeEventIllustration />}
                    title="Plan a Life Event"
                    copy="Plan events like having a child or making a large purchase to see how it affects your FI date."
                    onClick={() => {
                      modalCtx.openModal(<NewLifeEventPopup />);
                    }}
                  />
                </div>
                <div className="flex flex-col mt-4">
                  <p className="text-lg text-white mt-2 px-2 mb-2">
                    Portfolio Events
                  </p>
                  <PortfolioLifeEventsList />
                  <TopiaBannerCTA
                    color="future-blue"
                    analyticsEvent="portfolio_life_event_cta_clicked"
                    illustration={<BriefcaseIllustration />}
                    title="Plan a Portfolio Event"
                    copy="Expecting a windfall or bonus? Planning to buy a rental property or invest in an ETF?"
                    onClick={() => {
                      modalCtx.openModal(<NewPortfolioEventPopup />);
                    }}
                  />
                  <div className="flex-1"></div>
                  {/* <div
                    className="px-4 py-2 flex items-center cursor-pointer border-t mt-2 hover:bg-topia-gray"
                    onClick={() => {
                      modalCtx.openModal(<NewPortfolioEventPopup />);
                    }}
                  >
                    <div>
                      <p className="text-md font-bold m-0 text-white">
                        <span
                          className="underline"
                          style={{
                            textDecorationColor: "#0000ff",
                          }}
                        >
                          Plan a Portfolio Event
                        </span>
                      </p>
                      <p className="text-xs text-white m-0 pr-2">
                        Expecting a windfall or bonus? Planning to buy a rental
                        property or invest in an ETF?
                      </p>
                    </div>
                    <div className="flex-1"></div>
                    <FireIcon
                      className="h-6 w-6"
                      style={{ color: "#0000ff" }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

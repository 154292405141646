import * as d3 from "d3";
import { calculateFullModel } from "@byundefined/topia-model";
import moment from "moment";

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    compactDisplay: "short",
    maximumFractionDigits: 0,
  }).format(value);

export type SavingsRateDatum = {
  date: string;
  dateFormatted: string;
  investment: number;
  expenditure: number;
  staticSavingRate: number;
  staticIncome: number;
};

export class TopiaSavingsRateGraph {
  private data: SavingsRateDatum[] = null as any;

  margin: { top: number; right: number; bottom: number; left: number };
  xScale: d3.ScaleTime<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  vis: d3.Selection<any, unknown, null, undefined>;
  fiNrLabelRect: d3.Selection<SVGRectElement, unknown, null, undefined>;
  width: number;
  height: number;
  valueline: d3.Line<[number, number]>;
  normalResultFill: d3.Selection<SVGPathElement, any, null, undefined>;
  normalResultLine: d3.Selection<SVGPathElement, any, null, undefined>;
  valuearea: d3.Area<[number, number]>;
  yAxis: d3.Selection<SVGGElement, unknown, null, undefined>;
  horizontalLine: d3.Selection<SVGLineElement, unknown, null, undefined>;
  fiNrLabel: d3.Selection<SVGTextElement, unknown, null, undefined>;
  xAxis: d3.Selection<SVGGElement, unknown, null, undefined>;
  completedInitialLoad: boolean;
  xLines: d3.Selection<SVGLineElement, unknown, null, undefined>[];
  horizontalLines: d3.Selection<SVGLineElement, unknown, null, undefined>[];

  constructor(private readonly _svgElement: any) {
    const bbox = this._svgElement.getBoundingClientRect();
    this.margin = { top: 20, right: 0, bottom: 50, left: 50 };
    const width = bbox.width - this.margin.left - this.margin.right;
    const height = bbox.height - this.margin.top - this.margin.bottom;

    this.width = width;
    this.height = height;

    this.xScale = d3.scaleTime().range([0, width]);
    this.yScale = d3.scaleLinear().range([height, 0]);

    this.yScale.ticks(5);

    this.vis = d3.select(this._svgElement);
    this.vis.selectAll("*").remove();

    this.valuearea = d3
      .area()
      .x((d: any) => this.xScale(new Date(`${d.date}-01`)))
      .y0(height)
      .y1((d: any) => this.yScale(d.staticSavingRate));

    this.valueline = d3
      .line()
      .x((d: any) => this.xScale(new Date(`${d.date}-01`)))
      .y((d: any) => this.yScale(d.staticSavingRate));

    // Add the x Axis
    this.xAxis = this.vis
      .append("g")
      .attr(
        "transform",
        `translate(${this.margin.left}, ${height + this.margin.top})`
      )
      .call(d3.axisBottom(this.xScale))
      .attr("color", "#9E9E9E")
      .attr("font-family", "apercu")
      // .attr("stroke", "#1d1e20")
      .attr("font-size", "16px");

    // Add the y Axis
    this.yAxis = this.vis
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .call(
        d3
          .axisLeft(this.yScale)
          // @ts-ignore
          .tickFormat(d3.format("$,"))
      )
      .attr("color", "#9E9E9E")
      .attr("font-family", "apercu")
      // .attr("stroke", "#1d1e20")
      .attr("font-size", "16px");
    // gray rectangle taking up full visible area
    this.vis
      .append("g")
      .attr(
        "transform",
        `translate(${this.margin.left - 6}, ${this.margin.top - 8})`
      )
      .append("rect")
      .attr("width", width)
      .attr("height", height + 16)
      .attr("fill", "#1D1E20");

    this.horizontalLines = [0.25, 0.5, 0.75, 1].map((pct) => {
      return this.vis
        .append("g")
        .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
        .append("line")
        .attr("x1", 0)
        .attr("y1", this.yScale(pct))
        .attr("x2", width)
        .attr("y2", this.yScale(pct))
        .attr("stroke", "#9E9E9E")
        .attr("stroke-width", 1);
    });

    // Normal result
    this.normalResultFill = this.vis
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .append("path")
      .attr("class", "area")
      .attr("z-index", "100")
      .attr("fill", "#cccccc");

    // Normal result line
    this.normalResultLine = this.vis
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .append("path")
      .attr("class", "line")
      .attr("stroke", "black")
      .attr("stroke-width", 2)
      .attr("fill", "none");

  }

  setModelData(data: SavingsRateDatum[]) {
    this.data = data;
    this.updateGraph();
  }

  updateGraph() {
    const graphData = this.data;

    this.xScale.domain(
      // @ts-ignore
      d3.extent(graphData, (d) => new Date(`${d.date}-01`))
    );

    this.yScale.domain([0, 100]);

    const transitionDuration = this.completedInitialLoad ? 400 : 0;

    // Normal result
    this.normalResultFill
      .data([graphData])
      // .transition(d3.transition().duration(transitionDuration))
      //   @ts-ignore
      .attr("d", this.valuearea);

    // Normal result line
    this.normalResultLine
      .data([graphData])
      // .transition(d3.transition().duration(transitionDuration))
      //   @ts-ignore
      .attr("d", this.valueline);

    this.xAxis
      .transition(d3.transition().duration(transitionDuration))
      .call(
        d3
          .axisBottom(this.xScale)
          .ticks(this.data.length)
          .tickFormat((d: any) =>
            d.toLocaleString("default", { month: "short" })
          )
      )
      .attr("font-family", "apercu")
      .attr("font-size", "16px");

    this.yAxis.transition(d3.transition().duration(transitionDuration)).call(
      d3
        .axisLeft(this.yScale)
        .tickValues([0, 25, 50, 75, 100])
        // @ts-ignore
        .tickFormat((v) => v + "%")
    );

    this.completedInitialLoad = true;
  }
}

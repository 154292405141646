import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { formatCurrency } from "../../helpers";
import { ChangeIndicator } from "../ChangeIndicator";
import { PencilIllustration } from "./illustrations/PencilIllustration";
import { TopiaButton } from "./TopiaButton";
import { BASE_INPUT_CLASSES, TopiaTextInput } from "./TopiaInput";
import { TopiaTooltip } from "./TopiaTooltip";
import { useFloating, offset, shift, flip } from "@floating-ui/react";

export function DataPoint(
  props: {
    label?: string;
    value: any;
    ogValue?: any;
    inverseColors?: boolean;
    type?: "currency";
    suffix?: any;
    editable?: boolean;
    sz?: "md" | "lg";
    onFinishEditing?: (val: any) => void;
    onClick?: () => void;
    changeIndicatorPosition?: "left" | "right" | "below";
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const form = useForm();
  const { x, y, reference, floating, strategy } = useFloating({
    placement: "bottom",
    middleware: [offset(10), shift({}), flip()],
  });

  let formatFn = (v) => v;
  if (props.type === "currency") {
    formatFn = formatCurrency;
  }

  function onSubmit({ val }) {
    const v = props.type === "currency" ? Number(val) : val;
    props.onFinishEditing && props.onFinishEditing(v);
    setIsEditing(false);
  }
  const change =
    props.type === "currency"
      ? formatCurrency(props.value - props.ogValue)
      : props.value - props.ogValue;

  const changeIndicator =
    !isNaN(props.ogValue - props.value) && props.ogValue - props.value != 0 ? (
      <ChangeIndicator
        inverseColors={props.inverseColors}
        change={!!props.ogValue ? change : undefined}
        changeType={props.value > props.ogValue ? "increase" : "decrease"}
      />
    ) : (
      <></>
    );

  return (
    <>
      <div
        {...props}
        className={classNames("", props.className)}
        ref={reference}
      >
        <dt className="text-sm font-medium text-topia-gray flex items-center">
          {/* <div className="mr-2 -ml-7 w-5 h-5 rounded-full border-gray-400 border-2 flex items-center justify-center text-white">
          ?
        </div> */}
          <div className="flex items-center">
            {props.label && <span className="truncate">{props.label}</span>}
            {props.label && props.label !== "Full FI Age" && (
              <TopiaTooltip label={props.label} className="ml-2" />
            )}
          </div>
          {/* Needs TopiaTooltip here */}
        </dt>

        <dd
          className="mt-1 flex items-center cursor-pointer group"
          onClick={() => {
            props.onClick && props.onClick();
            if (props.editable) {
              setIsEditing(true);
            }
          }}
        >
          <div
            onClick={() => {
              props.onClick && props.onClick();
              if (props.editable) {
                setIsEditing(true);
              }
            }}
          >
            <div className="flex items-center truncate">
              {props.changeIndicatorPosition === "left" ? (
                <div className="mr-2">{changeIndicator}</div>
              ) : (
                <></>
              )}
              <span
                className={classNames(
                  "truncate text-white group-hover:text-future-blue",
                  props.sz === "lg" ? "text-xl" : "text-md"
                )}
              >
                {formatFn(props.value)}
              </span>
              {props.suffix && props.suffix}
              <div
                className="rounded-full cursor-pointer border-topia-dark-gray ml-2 border-1 group-hover:bg-topia-dark-gray flex items-center justify-center"
                style={{ width: 35, height: 35 }}
              >
                <PencilIllustration className="w-5 h-5" svgProps={{}} />
              </div>
              {!props.changeIndicatorPosition ||
              props.changeIndicatorPosition === "right" ? (
                changeIndicator
              ) : (
                <></>
              )}
            </div>
            {props.changeIndicatorPosition === "below" ? (
              changeIndicator
            ) : (
              <></>
            )}
          </div>
        </dd>
      </div>
      {isEditing && (
        <>
          <div
            className="bg-black/60 z-[50] fixed top-0 left-0 right-0 bottom-0"
            onClick={() => {
              setIsEditing(false);
            }}
          />
          <div
            ref={floating}
            className=""
            style={{
              width: 220,
              zIndex: 100,
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <form onSubmit={form.handleSubmit(onSubmit)} className="">
              <div className={"w-full flex items-center justify-center"}>
                <input
                  id={props.id}
                  // placeholder={props.label}
                  className={classNames(
                    BASE_INPUT_CLASSES,
                    "border-2 border-future-blue w-full mr-2"
                  )}
                  style={{
                    minWidth: 200,
                  }}
                  autoFocus={true}
                  // onBlur={() => {
                  //   console.log("blurring")
                  //   // const v = form.getValues();
                  //   // if (v.val === props.value) {
                  //     setIsEditing(false);
                  //   // }
                  // }}
                  onFocus={() => {
                    setTimeout(() => {
                      if (inputRef.current) {
                        inputRef.current.select();
                        inputRef.current.setSelectionRange(
                          -inputRef.current.value.length,
                          inputRef.current.value.length
                        );
                      }
                    }, 50);
                  }}
                  {...form.register("val", {
                    required: true,
                    value:
                      props.type === "currency" && props.value
                        ? Number(Number(props.value).toFixed(2))
                        : props.value || 0,
                  })}
                />
                <div className="flex w-full items-center justify-center">
                  <TopiaButton
                    className="flex-1"
                    btnStyle="future"
                    type="submit"
                  >
                    Save
                  </TopiaButton>
                  {/* <div
                className="text-white ml-4"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <XCircleIcon style={{ width: 35, height: 35 }} />
              </div> */}
                </div>
              </div>
              {/* <div className="w-full flex">
              <TopiaButton type="submit" btnStyle="secondary" className=" mr-2">
                Cancel
              </TopiaButton>
              <TopiaButton type="submit" btnStyle="future" className=" ml-2">
                Save
              </TopiaButton>
            </div> */}

              {/* <input
              ref={(x) => (inputRef.current = x)}
              type={props.type == "currency" ? "number" : undefined}
              className="w-full p-1"
              autoFocus
              onFocus={() => {
                // setTimeout(() => {
                if (inputRef.current) {
                  inputRef.current.select();
                  inputRef.current.setSelectionRange(
                    -inputRef.current.value.length,
                    inputRef.current.value.length
                  );
                }
                // }, 50);
              }}
              id={""}
            /> */}
            </form>
          </div>
        </>
      )}
    </>
  );
}

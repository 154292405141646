import { Account } from "@byundefined/topia-model/lib/commonTypes";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { trackAnalyticsEvent } from "../analytics";
import { Card } from "../components/core/Card";
import { DataPoint } from "../components/core/DataPoint";
import { TopiaButton } from "../components/core/TopiaButton";
import { TopiaCurrencyInput } from "../components/core/TopiaInput";
import { TopiaToggle } from "../components/core/TopiaToggle";
import { useDataContext } from "../context/DataContext";
import { formatCurrency } from "../helpers";
import { useAppSelector } from "../store";

export function ConfirmAccountsPage(props: {}) {
  const accounts = useAppSelector((state) => state.app.pendingAccounts);

  const [loading, setLoading] = useState(false);
  const form = useForm();
  const formValues = form.watch();
  const dataCtx = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    trackAnalyticsEvent("confirm_accounts_page_visited");
  }, []);

  async function onSubmit(vals) {
    // TODO: Error handling

    setLoading(true);

    const updatedAccounts = {};
    for (let accId of Object.keys(vals)) {
      const ogAcc = accounts.find((a) => a.id == accId);
      const formAcct = vals[accId];

      if (ogAcc.monthlyTopup != formAcct.monthlyTopup) {
        updatedAccounts[accId] = {
          ...updatedAccounts[accId],
          manualMonthlyTopup: formAcct.monthlyTopup,
        };
      }

      if (ogAcc.paidOffEachMonth != formAcct.paidOffEachMonth) {
        updatedAccounts[accId] = {
          ...updatedAccounts[accId],
          paidOffEachMonth: formAcct.paidOffEachMonth,
        };
      }
    }

    if (Object.keys(updatedAccounts).length > 0) {
      await dataCtx.operations.confirmRecentlyAddedAccounts({
        updatedAccounts,
      });
    }

    navigate("/");
    setLoading(false);
  }

  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <div className="mb-4 flex w-full items-center justify-center"></div>
      <h3 className="text-2xl text-white mb-4">Confirm Accounts</h3>
      <Card>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="divide-y divide-white/20">
            {(accounts || []).map((acc) => (
              <div key={acc.id} className="py-4">
                <div className="flex">
                  <div className="flex-1"></div>
                  {/* <p className="text-lg text-white">
                    {formatCurrency(acc.amount)}
                  </p> */}
                </div>
                <div className="flex mt-2 items-center align-center">
                  <h2 className="text-lg text-white">{acc.name}</h2>
                  {acc.type === "debt" && (
                    <TopiaToggle
                      inputProps={form.register(
                        acc.id + ".paidOffEachMonth",
                        {}
                      )}
                      className="mx-4"
                      label="I pay this debt off in full every month"
                      id={acc.id + ".paidOffEachMonth"}
                    />
                  )}
                  <div className="flex-1"></div>
                  {!(formValues[acc.id] || {}).paidOffEachMonth && (
                    <TopiaCurrencyInput
                      className=""
                      id={acc.id + ".monthlyTopup"}
                      label="Estimated Monthly Contribution"
                      inputProps={form.register(acc.id + ".monthlyTopup", {
                        value: acc.monthlyTopup || 0,
                      })}
                    />
                  )}
                </div>
              </div>
            ))}
            <p className="mt-4 text-md text-topia-gray text-center py-4">
              We use these numbers to help us forecast your FI date.
            </p>
            <TopiaButton
              btnStyle="future"
              className="mt-4 w-full"
              loading={loading}
              type="submit"
            >
              Confirm
            </TopiaButton>
          </div>
        </form>
      </Card>
    </div>
  );
}

import { generateFullUserSettings } from "@byundefined/topia-model";
import { UserModel } from "@byundefined/topia-model/lib/commonTypes";

import {
  ACCOUNT_SUBTYPES,
  ACCOUNT_TYPES,
} from "@byundefined/topia-model/lib/constants/accountConstants";
import { collection, doc, setDoc } from "firebase/firestore";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { useAuthContext } from "../auth";
import { Card } from "../components/core/Card";
import { TopiaButton } from "../components/core/TopiaButton";
import { TopiaDateInput } from "../components/core/TopiaDateInput";
import { FullScreenTopiaLoader } from "../components/core/TopiaLoader";
import { TopiaSelect } from "../components/core/TopiaSelect";
import {
  TopiaCurrencyInput,
  TopiaEmailInput,
  TopiaPasswordInput,
  TopiaTextInput,
} from "../components/core/TopiaInput";
import { DashboardChart } from "../components/DashboardChart";
import { FIStats } from "../components/FIStats";
import { cleanObject, useDataContext } from "../context/DataContext";
import { ACCOUNT_CATEGORIES } from "../enums";
import { db } from "../firebase";
import { useFinancialModel } from "../hooks/useFinancialModel";
import { TopiaLogo } from "../components/core/TopiaLogo";
// import _ from "lodash";

export function OnboardingPage(props: {}) {
  const authCtx = useAuthContext();
  const [loading, setLoading] = useState(false);
  const form = useForm();
  const formValues = form.watch();
  console.log(formValues);

  global.region = formValues.region;
  const uid = authCtx.user?.uid;
  const dataCtx = useDataContext();
  const navigate = useNavigate();

  async function saveUser(vals) {
    const userRecord: UserModel = {
      initialSettings: {},
      settings: {},
    } as any;

    const newSettings = generateFullUserSettings(userRecord);

    userRecord.region = vals.region;
    userRecord.settings.monthlyTakehome = Number(vals.monthlyTakehome);
    userRecord.settings.monthlySpend = Number(vals.monthlySpend);
    userRecord.settings.retirementSpent = Number(vals.retirementSpend);
    // @ts-ignore
    userRecord.settings.dateOfBirth = moment(vals.dateOfBirth).format(
      "DD/MM/YYYY"
    );

    // TODO
    // @ts-ignore
    userRecord.settings.estimatedNetWorth = Number(vals.estimatedNetWorth);

    userRecord.settings.inflation = Number(newSettings.inflation);
    userRecord.settings.withdrawalRate = Number(newSettings.withdrawalRate);

    userRecord.incomes = [
      {
        amount: Number(vals.monthlyTakehome),
        id: "salary",
        name: "Salary",
        type: "salary",
      },
    ];

    userRecord.initialSettings = userRecord.settings;
    userRecord.registeredOn = new Date();

    //   @ts-ignore
    userRecord.registeredOnPlatform = "web";

    await setDoc(doc(collection(db, "users"), uid), cleanObject(userRecord));

    return newSettings;
  }

  const monthlyTakehome = Number(formValues.monthlyTakehome);
  const retirementSpend = Number(formValues.retirementSpend);

  const settings: UserModel["settings"] = {
    monthlySpend: Number(formValues.monthlySpend || 0),
    monthlyTakehome: monthlyTakehome,
    postFIType: "lean",
    retirementSpent: retirementSpend,
    dateOfBirth: moment(formValues.dateOfBirth).format("DD/MM/YYYY") as any,
    inflation: 3,
    withdrawalRate: 4,
  };

  const modelResult = useFinancialModel({
    origin: "onboarding",
    accounts: [
      {
        name: "Est Net Worth",
        amount: Number(formValues.estimatedNetWorth || 0),
        annualReturn: 7,
        category: "isa",
        history: {},
        id: "static-est-net-worth",
        subType: "stocks_shares_isa",
        monthlyTopup: monthlyTakehome,
        type: "saving",
        userID: uid,
      },
    ],
    incomes: [
      {
        name: "Salary",
        amount: monthlyTakehome,
        type: "salary",
        id: "static-salary",
      },
    ],
    lifeEvents: [],
    settings,
  });

  async function onSubmit(vals) {
    try {
      setLoading(true);
      await saveUser(vals);
      navigate("/");
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  }

  if (authCtx.initialLoading && !authCtx.user) {
    return <FullScreenTopiaLoader />;
  }
  return (
    <>
      <div className="lg:grid lg:grid-cols-5 min-h-full justify-center py-12 sm:px-6 lg:px-8 bg-topia-black ">
        <div className="col-span-2 px-16">
          <div className="w-full">
            <div className="">
              <TopiaLogo svgProps={{ style: { fill: "white", width: 103 } }} />
            </div>
            <h2 className="mt-8 mb-2 text-5xl tracking-tight text-white font-serif font-light">
              {/* Let’s get your <strong className="font-black">FI</strong> Vitals now */}
              Let’s get your FI Vitals now
            </h2>
            <p className="text-white text-md mb-8">
              Play with your numbers to get a glimpse of your FI vitals. You can
              enter more information later on to finalize your FI roadmap.
            </p>
          </div>

          <div className="w-full">
            <div className="">
              <form className="" onSubmit={form.handleSubmit(onSubmit)}>
                <div className="grid grid-cols-2 gap-4">
                  <TopiaCurrencyInput
                    label="Estimated Net Worth"
                    className="mt-2"
                    inputProps={form.register("estimatedNetWorth", {
                      required: true,
                      value: 10000,
                    })}
                    id={"onboarding-estimatedNetWorth"}
                  />
                  <TopiaCurrencyInput
                    label="Current Monthly Net Income"
                    className="mt-2"
                    inputProps={form.register("monthlyTakehome", {
                      required: true,
                      value: 3000,
                    })}
                    id={"onboarding-monthlyTakehome"}
                  />
                  <TopiaCurrencyInput
                    label="Current Monthly Spend"
                    className="mt-2"
                    inputProps={form.register("monthlySpend", {
                      required: true,
                      value: 2000,
                    })}
                    id={"onboarding-monthlySpend"}
                  />
                  <TopiaCurrencyInput
                    label="Monthly Spend at FI"
                    className="mt-2"
                    inputProps={form.register("retirementSpend", {
                      required: true,
                      value: 1500,
                    })}
                    id={"onboarding-retirmentSpend"}
                  />
                </div>

                <TopiaDateInput
                  className="mt-2"
                  label="Date of Birth"
                  control={form.control}
                  // inputProps={form.register("dateOfBirth", { required: true })}
                  controllerProps={{
                    defaultValue: moment(new Date()).add(-18, "years").toDate(),
                    name: "dateOfBirth",
                    rules: { required: true },
                  }}
                  id="dateOfBirth"
                  datePickerProps={{ maxDate: new Date() }}
                />

                <TopiaSelect
                  className="mb-4"
                  label="Country"
                  inputProps={form.register("region", {
                    required: true,
                  })}
                  options={[
                    { value: "us", label: "United States" },
                    {
                      value: "gb",
                      label: "United Kingdom",
                    },
                  ]}
                  id={"signup-country"}
                />

                <TopiaButton
                  className="mt-4 w-full"
                  btnStyle="primary"
                  type="submit"
                  loading={loading}
                  analyticsEvent="onboarding_fi_vitals_submit_clicked"
                >
                  Create a plan to reduce your time to FI
                </TopiaButton>
              </form>
            </div>

            <p className="text-topia-gray text-center text-xs mt-8 mb-2">
              ASSUMPTIONS
            </p>
            <ul className="text-topia-gray text-center text-xs">
              <li>All monthly savings are invested</li>
              <li>Investments return 7% annually</li>
              <li>Inflation is fixed at 3%</li>
              <li>The safe withdrawal rate is 4%</li>
            </ul>
          </div>
        </div>
        <div className="col-span-3 p-8">
          <div className="flex items-center align-center justify-center p-10 h-full">
            <div className="rounded-2xl  p-10 w-full h-full bg-white">
              <p className="py-2 text-black text-md ">FI Roadmap</p>
              <FIStats
                inverse={true}
                userData={{
                  settings: {
                    dateOfBirth: moment(formValues.dateOfBirth).format(
                      "DD/MM/YYYY"
                    ),
                  } as any,
                  modelResult,
                }}
                region={formValues.region}
              />
              <DashboardChart modelResult={modelResult} inverse fill />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-4xl">
        <Card className="p-4">
          <h4 className="text-xl font-bold text-white">Get your FI Vitals</h4>
          <p className="my-4 text-md text-white">
            Play with your numbers to get your initial FI vitals. We will help
            you tweak your plan to improve your time to FI. You can change these
            numbers later.
          </p>
          <div className="mt-4"></div>
          <div className="">
            <div className="">
              <form onSubmit={form.handleSubmit(onSubmit)}></form>
            </div>
          </div>
          <p className="mt-4 text-white">(i) Underlying assumptions</p>
          <ul className="text-white list-disc pl-4">
            <li>All monthly savings are invested</li>
            <li>Investments return 7% annually</li>
            <li>Inflation is fixed at 3%</li>
            <li>The safe withdrawal rate is 4%</li>
          </ul>
        </Card>
      </div>
    </div>
  );
}

import { Account, LifeEvent } from "@byundefined/topia-model/lib/commonTypes";
import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { FinancialModelResult } from "./hooks/useFinancialModel";
import {
  DateYMString,
  UserModel,
} from "@byundefined/topia-model/lib/commonTypes";
import _ from "lodash";
import { useAuthContext } from "./auth";

export type AppUserData = {
  user: UserModel;
  accounts: Account[];
  lifeEvents: LifeEvent[];
  modelResult: FinancialModelResult;
};

export type AppState = {
  userData: AppUserData;
  tinkerData?: AppUserData;

  isLoadingGlobal: boolean;

  plaidLinkResult?: {
    public_token: string;
    metadata: any;
  };

  initialized: boolean;
  topiaRegion: "us" | "gb";

  pendingAccounts?: Account[];
};

function checkIsLoadingGlobal(state: AppState) {
  return !(
    state.userData &&
    state.userData?.user &&
    state.userData?.accounts &&
    state.userData?.lifeEvents
  );
}

const appSlice = createSlice({
  name: "app",
  initialState: { initialized: false, isLoadingGlobal: true } as AppState,
  reducers: {
    setPendingAccounts(state, action) {
      state.pendingAccounts = action.payload;
    },
    resetTinker(state, action) {
      state.tinkerData = undefined;
    },
    initialize(state, action) {
      state.initialized = true;
    },
    setPlaidLinkResult(state, action) {
      state.plaidLinkResult = action.payload;
    },
    setModelResult(state, action: PayloadAction<FinancialModelResult>) {
      state.userData = {
        ...state.userData,
        modelResult: cleanInput(action.payload),
      };
    },
    setTinkerModelResult(state, action: PayloadAction<FinancialModelResult>) {
      state.tinkerData = {
        ...state.tinkerData,
        modelResult: action.payload,
      };
    },
    setLifeEvents(
      state,
      action: PayloadAction<AppState["userData"]["lifeEvents"]>
    ) {
      state.userData = {
        ...state.userData,
        lifeEvents: cleanInput(action.payload),
      };
      state.isLoadingGlobal = checkIsLoadingGlobal(state);
    },
    setAccounts(
      state,
      action: PayloadAction<AppState["userData"]["accounts"]>
    ) {
      state.userData = {
        ...state.userData,
        accounts: action.payload.map(cleanInput),
      };
      state.isLoadingGlobal = checkIsLoadingGlobal(state);
    },
    setUser(state, action: PayloadAction<AppState["userData"]["user"]>) {
      state.userData.user = cleanInput(action.payload);
      state.isLoadingGlobal = checkIsLoadingGlobal(state);
    },
    updateTinkerUser(state, action: PayloadAction<Partial<UserModel>>) {
      state.tinkerData = state.tinkerData || _.cloneDeep(state.userData);
      state.tinkerData.user = {
        ...state.tinkerData.user,
        ...action.payload,
      };
    },
    updateTinkerUserSettings(state, action: PayloadAction<Partial<UserModel>>) {
      state.tinkerData = state.tinkerData || _.cloneDeep(state.userData);
      state.tinkerData.user = {
        ...state.tinkerData.user,
        ...action.payload,
        settings: {
          ...state.tinkerData.user.settings,
          ...action.payload,
        },
      };
    },
    addTinkerLifeEvent(state, action: PayloadAction<LifeEvent>) {
      state.tinkerData = state.tinkerData || _.cloneDeep(state.userData);
      state.tinkerData.lifeEvents.push(action.payload);
    },
    deleteTinkerLifeEvent(state, action: PayloadAction<LifeEvent>) {
      state.tinkerData = state.tinkerData || _.cloneDeep(state.userData);
      state.tinkerData.lifeEvents = state.tinkerData.lifeEvents.filter(
        (le) => le.id !== action.payload.id && le !== action.payload
      );
    },
    updateTinkerLifeEvent(state, action: PayloadAction<LifeEvent>) {
      state.tinkerData = state.tinkerData || _.cloneDeep(state.userData);
      state.tinkerData.lifeEvents = state.tinkerData.lifeEvents.map((le2) => {
        if (le2.id === action.payload.id) {
          return action.payload;
        }
        return le2;
      });
    },
    addTinkerAccount(state, action: PayloadAction<Account>) {
      state.tinkerData = state.tinkerData || _.cloneDeep(state.userData);
      state.tinkerData.accounts.push(action.payload);
    },
    deleteTinkerAccount(state, action: PayloadAction<Account>) {
      state.tinkerData = state.tinkerData || _.cloneDeep(state.userData);
      state.tinkerData.accounts = state.tinkerData.accounts.filter(
        (acc) => acc.id !== action.payload.id && acc !== action.payload
      );
    },
    updateTinkerAccount(state, action: PayloadAction<Account>) {
      state.tinkerData = state.tinkerData || _.cloneDeep(state.userData);
      state.tinkerData.accounts = state.tinkerData.accounts.map((acc) => {
        if (acc.id === action.payload.id) {
          return action.payload;
        }
        return acc;
      });
    },
  },
});

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
  },
  /**
   * Necessary because firestore uses a nonserializable Timestamp object
   */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useIsLoadingGlobal = () => {
  const authCtx = useAuthContext();
  return (
    useAppSelector((state) => state.app.isLoadingGlobal) &&
    ((!authCtx.user && authCtx.hasLocalCreds) || authCtx.initialLoading)
  );
};

export const appActions = appSlice.actions;

function cleanInput<T>(o: T): T {
  const ret = _.clone(o);
  for (let k in o) {
    const v: any = o[k];
    if (v && typeof v === "object") {
      ret[k] = !v.prototype ? cleanInput(v) : undefined;
    } else {
      ret[k] = v;
    }
  }
  return ret;
}

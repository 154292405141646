import { LifeEvent } from "@byundefined/topia-model/lib/commonTypes";
import classNames from "classnames";
import moment from "moment";
import { useState, useEffect } from "react";
import { useFinancialModel } from "../hooks/useFinancialModel";
import { useAppSelector } from "../store";
import { ChangeIndicator } from "./ChangeIndicator";

export function FITimeDifference(props: {
  className?: string;
  diff: {
    type: "life-event";
    lifeEvent: LifeEvent;
  };
}) {
  const [text, setText] = useState("");
  const [_isPositive, setIsPositive] = useState(false);
  const appData = useAppSelector(state => state.app.tinkerData || state.app.userData);

  const filteredLifeEvents = appData.lifeEvents.filter(
    (le) => le !== props.diff.lifeEvent && le.id !== props.diff.lifeEvent.id
  );

  const modelResult = useFinancialModel(
    appData && appData.user
      ? {
          accounts: appData?.accounts,
          lifeEvents: filteredLifeEvents,
          settings: appData?.user?.settings,
          incomes: appData?.user?.incomes,
        }
      : undefined
  );

  const modelResultNoLE = useFinancialModel(
    appData && appData.user
      ? {
          accounts: appData?.accounts,
          lifeEvents: [...filteredLifeEvents, props.diff.lifeEvent],
          settings: appData?.user?.settings,
          incomes: appData?.user?.incomes,
        }
      : undefined
  );

  const fiDate = modelResult.FIDate;
  const newFiDate = modelResultNoLE?.FIDate;

  useEffect(() => {
    let differenceInMonths = moment(fiDate).diff(moment(newFiDate), "months");
    const isPositive = differenceInMonths > 0;
    setIsPositive(isPositive)
    console.log(props.diff.lifeEvent.name, "diffInMonths", differenceInMonths)
    differenceInMonths = Math.abs(differenceInMonths);

    const yearsDifference = Math.floor(differenceInMonths / 12);
    const monthsDifference = Math.round(modulo(differenceInMonths, 12));


    const yearsText = `${Math.abs(yearsDifference)} year${
      yearsDifference !== 1 ? "s" : ""
    }`;
    const monthsText = `${Math.abs(monthsDifference)} month${
      monthsDifference !== 1 ? "s" : ""
    }`;

    console.log(props.diff.lifeEvent.name, "yearsDifference", yearsDifference, "monthsDifference", monthsDifference);

    if (isNaN(monthsDifference) || isNaN(yearsDifference) || (yearsDifference === 0 && monthsDifference === 0)) {
      setText(undefined);
    } else {
      setText(
        `Reach FI ${yearsText}, ${monthsText} ${
          !isPositive ? "later" : "sooner"
        }`
      );
    }
  }, [fiDate, newFiDate]);

  if (!text) {
    return <></>;
  }

  return <ChangeIndicator className={classNames(modelResult.isCalculating || modelResultNoLE.isCalculating ? "opacity-50" : undefined, props.className)} change={text} changeType={!_isPositive ? "increase" : "decrease"}/>;
}

const modulo = (n: number, m: number) => {
  //also works on negative number
  return ((n % m) + m) % m;
};

import {
  Account,
  DateYMDString,
} from "@byundefined/topia-model/lib/commonTypes";
import moment from "moment";
import { ACCOUNT_TYPES } from "./enums";

export function uuidv4() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

// export function makeDivComponent<TProps extends {}>((props: TProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => JSX.Element) {
//   return (props: TProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
//     return (
//       <div {...props}>
//         {props.children}
//       </div>
//     )
//   }
// }

export function getCurrencySymbol(): string {
  return global.region === "us" ? "$" : "£";
}

export function formatCurrency(input: string | number, overrideRegion?: "us" | "gb") {
  const region = overrideRegion || global.region;

  if (input === undefined || input === null) return "";
  
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: region === "us" ? "USD" : "GBP",
    maximumFractionDigits: 0,
  });

  const v = Number(input);
  return currencyFormatter.format(v);
}

export function dateToDashStringYMD(d: Date): DateYMDString {
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}` as any;
}

export function dateToDashStringYM(d: Date): DateYMDString {
  return `${d.getFullYear()}-${d.getMonth() + 1}` as any;
}

export function getLastMonthTopup(acct: Account): number | undefined {
  const lastMonth = dateToDashStringYM(moment().subtract(1, "month").toDate());
  const historyItem = (acct.history || {})[lastMonth];
  return historyItem?.monthlyTopup
    ? Number(historyItem.monthlyTopup)
    : undefined;
}

export function toFixedPrecision(n: number | string) {
  return Number(n).toFixed(2);
}

export function getAccountsByType(accounts: Account[]) {
  return Object.values(ACCOUNT_TYPES).map((type) => {
    if (type === "property") {
      return {
        type,
        accounts: accounts.filter(
          (acct) => acct.type === "debt" && acct.subType === "mortgage"
        ),
      };
    } else {
      return {
        type,
        accounts: accounts.filter(
          (acct) => acct.type === type && !acct.property
        ),
      };
    }
  });
}

import { XCircleIcon } from "@heroicons/react/20/solid";
import { Bars3Icon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../auth";
import { useDataContext } from "../context/DataContext";
import { useModalContext } from "../context/ModalContext";
import { formatCurrency } from "../helpers";
import { PortfolioDelta } from "../PortfolioDelta";
import { useAppSelector } from "../store";
import { AccountList } from "./AccountList";
import { AccountWarnings } from "./AccountWarnings";
import { Card } from "./core/Card";
import { TopiaButton } from "./core/TopiaButton";
import { FullScreenTopiaLoader } from "./core/TopiaLoader";
import { Footer } from "./Footer";
import { SurplusWarning } from "./SurplusWarning";

export function Sidebar(props: {}) {
  const user = useAppSelector((state) => state.app.userData.user);
  const authCtx = useAuthContext();
  const [hasDismissedOnboardingPrompt, setHasDismissedOnboardingPrompt] =
    useState(
      Boolean(localStorage.getItem("topia-dismissed-onboarding-prompt"))
    );

  useEffect(() => {
    if (hasDismissedOnboardingPrompt) {
      localStorage.setItem("topia-dismissed-onboarding-prompt", "true");
    }
  }, [hasDismissedOnboardingPrompt]);

  const settings = useAppSelector((state) => state.app.userData.user?.settings);
  const accounts = useAppSelector((state) => state.app.userData.accounts);
  const modelResult = useAppSelector((state) => state.app.userData.modelResult);

  const netWorth = modelResult?.netWorthInfo?.netWorth;
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    if (modelResult.fullModel) {
      setInitialLoad(true);
    }
  }, [modelResult]);

  if (!initialLoad) {
    return <></>;
  }

  return (
    <div
      className="rounded-tr-2xl rounded-br-2xl p-4 topia-bounce-in"
      style={{ background: "#262729" }}
    >
      {/* <h1 className="text-xl my-2">{isTinkerMode ? "TINKER" : "YOUR PLAN"}</h1> */}
      {/* <div
        className="flex flex-shrink-0 border-t border-gray-200 p-4"
        onClick={() => {
          authCtx.signOut();
        }}
      >
        <a href="#" className="group block flex-shrink-0">
          <div className="flex items-center">
            <div>
              <img
                className="inline-block h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                alt=""
              />
            </div>
            <div className="ml-3">
              <p className="text-base font-medium text-white group-hover:text-white">
                Whitney Francis
              </p>
              <p className="text-sm font-medium text-white group-hover:text-white">
                View profile
              </p>
            </div>
          </div>
        </a>
      </div> */}
      <div className="mt-2 px-2 mb-4">
        <p className="text-md flex w-full items-center">
          <span className="text-topia-gray text-xs mr-3">Est. Net Worth</span>
        </p>
        <p className="text-2xl text-white flex w-full items-center">
          {accounts.length && accounts.length > 0
            ? formatCurrency(netWorth)
            : // @ts-ignore
              formatCurrency(settings?.estimatedNetWorth || 0)}
        </p>
      </div>
      <div className="h-full m-2">
        <PortfolioDelta />
        <AccountList />
        <AccountWarnings />
        <div className="">
          <Link to="/add-account" type="button" className="w-full block">
            <TopiaButton btnStyle="future" className="w-full">
              Add account
            </TopiaButton>
          </Link>
        </div>
      </div>
    </div>
  );
}

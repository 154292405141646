import { CheckIcon } from "@heroicons/react/24/outline";
import {
  Income,
  LifeEvent,
  UserModel,
} from "@byundefined/topia-model/lib/commonTypes";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useModalContext } from "../context/ModalContext";
import { dateToDashStringYMD, uuidv4 } from "../helpers";
import { appActions, useAppDispatch, useAppSelector } from "../store";
import { LifeEventForm } from "./LifeEventForm";
import classNames from "classnames";
import { TopiaButton } from "./core/TopiaButton";

export function FIFlavorPopup(props: {}) {
  const appDispatch = useAppDispatch();
  const modalCtx = useModalContext();

  const appData = useAppSelector(
    (state) => state.app.tinkerData || state.app.userData
  );

  const user = appData.user;
  const settings: UserModel["settings"] = user?.settings || ({} as any);

  const options: {
    label: string;
    description: any;
    flavor: UserModel["settings"]["postFIType"];
  }[] = [
    {
      label: "FI",
      description: <p>Traditional Retirement</p>,
      flavor: "lean",
    },
    {
      label: "Barista FI",
      description: <p>Partial Retirement</p>,
      flavor: "barista",
    },
    {
      label: "Coast FI",
      description: <p>Coast your way to FI</p>,
      flavor: "coast",
    },
  ];

  const activeSetting =
    options.find((o) => o.flavor === settings?.postFIType)! || ({} as any);

  return (
    <div className="mx-auto max-w-7xl  py-24 px-4 sm:px-6 lg:px-8">
      <h1 className="my-2 text-2xl text-white text-center">How do you want to retire?</h1>
      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {options.map((tier) => (
          <div
            key={tier.description}
            className={classNames(
              "relative flex flex-col -2xl border rounded-xl  p-8",
              activeSetting === tier ? "border-future-blue" : "border-white/20"
              )}
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-white text-center">
                {tier.label}
              </h3>
              {/* {tier === activeSetting ? (
                <p className="absolute top-0 -translate-y-1/2 transform -full bg-blue-500 py-1.5 px-4 text-sm font-semibold text-white">
                  Most popular border-white/20
                </p>
              ) : null} */}
              {/* <p className="mt-4 flex items-baseline text-white">
                <span className="text-5xl font-bold tracking-tight">
                  ${tier.price}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  {tier.frequency}
                </span>
              </p> */}
              <p className="mt-2 text-white text-center">{tier.description}</p>

              {/* Feature list */}
              {/* <ul role="list" className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-blue-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-white">{feature}</span>
                  </li>
                ))}
              </ul> */}
            </div>

            <TopiaButton
              className={"flex-1 mt-2"}
              btnStyle={activeSetting === tier ? "future" : "primary"}
              onClick={() => {
                appDispatch(appActions.updateTinkerUserSettings({
                  postFIType: tier.flavor,
                }));
                modalCtx.closeModal();
              }}
            >
              {tier.label}
            </TopiaButton>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="">
      <div className="">
        <h3 className="text-lg mb-4 text-black font-bold">FI Flavor</h3>
        <p className="text-sm text-white">
          FI Flavor is a way to track your FI progress. You can choose from
        </p>
      </div>
      <div className="grid grid-cols-2 mt-4 px-2">
        <div>
          {options.map((opt) => (
            <div className="mb-4">
              <div className="flex items-center">
                <p className="text-md">{opt.label}</p>
                <p className="text-md">{opt.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { createContext, useContext, useEffect, useState } from "react";
import { appActions, useAppSelector, useAppDispatch } from "../store";
import { useFinancialModel } from "../hooks/useFinancialModel";
import {
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { useAuthContext } from "../auth";
import { cleanObject } from "./DataContext";

export const TinkerContext = createContext<any>(undefined as any);

export function TinkerProvider(props: { children: any }) {
  const userAppData = useAppSelector((state) => state.app.userData);
  const tinkerAppData = useAppSelector((state) => state.app.tinkerData);
  const appDispatch = useAppDispatch();
  const authCtx = useAuthContext();
  const [isSavingTinker, setIsSavingTinker] = useState(false);

  async function saveTinker() {
    try {
      setIsSavingTinker(true);

      const tasks: Promise<any>[] = [];

      const userDoc = doc(collection(db, "users"), authCtx.user.uid);

      tasks.push(
        updateDoc(userDoc, {
          incomes: [...tinkerAppData?.user?.incomes],
          settings: {
            ...tinkerAppData?.user?.settings,
          },
        })
      );

      for (let le of tinkerAppData.lifeEvents) {
        tasks.push(
          setDoc(
            doc(collection(db, "lifeEvents"), le.id),
            cleanObject({
              ...le,
              userId: authCtx.user.uid,
            })
          )
        );
      }

      for (let le of userAppData.lifeEvents) {
        if (!tinkerAppData.lifeEvents.find((l) => l.id === le.id)) {
          tasks.push(deleteDoc(doc(collection(db, "lifeEvents"), le.id)));
        }
      }

      for (let acct of tinkerAppData.accounts) {
        tasks.push(
          setDoc(
            doc(collection(userDoc, "accounts"), acct.id),
            cleanObject({
              ...acct,
              userId: authCtx.user.uid,
            })
          )
        );
      }

      for (let acct of userAppData.accounts) {
        if (!tinkerAppData.accounts.find((a) => a.id === acct.id)) {
          tasks.push(deleteDoc(doc(collection(userDoc, "accounts"), acct.id)));
        }
      }

      await Promise.all(tasks);
      appDispatch(appActions.resetTinker({}));
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsSavingTinker(false);
    }
  }

  const tinkerModelResult = useFinancialModel(
    tinkerAppData && tinkerAppData.user
      ? {
          accounts: tinkerAppData?.accounts,
          lifeEvents: tinkerAppData?.lifeEvents,
          settings: {
            ...tinkerAppData?.user?.settings,
            retirementIncome:
              tinkerAppData?.user?.settings?.postFIType === "barista"
                ? tinkerAppData.user.settings.retirementIncome
                : 0,
          },
          incomes: tinkerAppData?.user?.incomes,
          origin: "tinker",
        }
      : undefined
  );

  useEffect(() => {
    if (tinkerModelResult && tinkerAppData?.user) {
      appDispatch(appActions.setTinkerModelResult(tinkerModelResult));
    }
  }, [tinkerModelResult.version]);

  return (
    <TinkerContext.Provider value={{ saveTinker, isSavingTinker }}>
      {props.children}
    </TinkerContext.Provider>
  );
}

export const useTinkerContext = () => useContext(TinkerContext);

import { Income, LifeEvent } from "@byundefined/topia-model/lib/commonTypes";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useModalContext } from "../context/ModalContext";
import {
  dateToDashStringYMD,
  formatCurrency,
  getAccountsByType,
  getLastMonthTopup,
  uuidv4,
} from "../helpers";
import { appActions, useAppDispatch } from "../store";
import { TopiaButton } from "./core/TopiaButton";
import { TopiaCurrencyInput } from "./core/TopiaInput";
import { TopiaDateInput } from "./core/TopiaDateInput";
import { LifeEventForm } from "./LifeEventForm";
import { useDataContext } from "../context/DataContext";
import { DataPoint } from "./core/DataPoint";
import { SavingsRateDatum } from "../topia-graph/TopiaSavingsRateGraph";

export function SavingsRatePopup(props: {
  // income: Income;
  history: SavingsRateDatum;
  // onSubmit: (vals: Income) => void;
}) {
  const [lifeEvent, setLifeEvent] = useState<Partial<LifeEvent> | undefined>();
  const appDispatch = useAppDispatch();
  const modalCtx = useModalContext();
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const dataCtx = useDataContext();

  const accountsByType = getAccountsByType(dataCtx.accounts);

  // const savingAccs = dataCtx.accounts.filter((acc) => acc.type === "saving");
  // const debt = dataCtx.accounts.filter((acc) => acc.type === "debt");

  // async function onSubmit(vals) {
  //   setLoading(true);
  //   await props.onSubmit({
  //     amount: Number(vals.amount),
  //     from: vals.from,
  //     id: props.income.id || uuidv4(),
  //     name: props.income.name + " (future)",
  //     type: props.income.type,
  //   });
  //   modalCtx.closeModal();
  //   setLoading(false);
  //   // TODO: Error handling
  // }

  return (
    <div className="px-4 py-2">
      <h3 className="text-white text-xl">{props.history.dateFormatted}</h3>
      <ul role="list" className="">
        {accountsByType.map((at) => (
          <li key={at.type} className="mb-4 mt-4">
            <p className="mt-2 mb-1 text-lg border-b border-gray-200 text-white flex justify-between">
              {at.type.toLocaleUpperCase()}
              <span className="text-sm opacity-50">Top-up</span>
            </p>

            {at.accounts.length > 0 ? (
              <table className="w-full">
                {/* <thead>
                  <tr className="text-white text-sm truncate">
                    <td className="pb-2">Account Name</td>
                    <td className="pb-2 text-right">Top-up</td>
                  </tr>
                </thead> */}
                <tbody>
                  {at.accounts.map((acct) => (
                    <tr key={acct.id} className="" onClick={() => {}}>
                      {/* <Link
                          to={`/account/${acct.id}`}
                          className="hover:bg-cyan-100 active:bg-cyan-200 active:ring-2 ring-cyan-400 py-1 cursor-pointer block"
                        > */}
                      <td className="pb-2 w-1/3">
                        <p className="text-sm font-medium text-white">
                          {acct.name}
                        </p>
                        <p className="text-xs font-medium text-white">
                          {acct.subType}
                        </p>
                      </td>
                      <td className="pb-2 w-1/6">
                        <p className="text-sm text-white ml-2 flex justify-end">
                          <DataPoint
                            value={
                              acct.history[props.history.date]?.monthlyTopup ||
                              0
                            }
                            type="currency"
                            editable
                            onFinishEditing={async (val) => {
                              console.log("this is the new val", val);

                              let adjustedAcc: {
                                monthlyTopup?: number;
                                manualMonthlyTopup?: number;
                                history?: unknown;
                              } = {};

                              adjustedAcc.history = {
                                ...acct.history,
                                [props.history.date]: {
                                  ...(acct.history[props.history.date] || {}),
                                  monthlyTopup: val,
                                },
                              };

                              await dataCtx.operations.updateAccount({
                                id: acct.id,
                                data: {
                                  ...adjustedAcc,
                                },
                              });
                              // };
                            }}
                          />
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-sm text-white mt-2">No accounts</p>
            )}
          </li>
        ))}
      </ul>
      {/* <form onSubmit={form.handleSubmit(onSubmit)}>
        <TopiaCurrencyInput
          id={"future-income"}
          label="Future monthly net income"
          inputProps={form.register("amount", {
            value: props.income.amount + 1000,
            required: true,
          })}
        />
        <TopiaDateInput
        
          id={"future-income"}
          label="Effective date"
          inputProps={form.register("from", {
            value: moment(props.income.from).add("years", 1).format("YYYY-MM-DD"),
            required: true,
          })}
        />
        <TopiaButton type="submit" className="mt-2">Submit</TopiaButton>
      </form> */}
    </div>
  );
}

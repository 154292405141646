import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  createRoutesFromElements,
  Navigate,
  RouteProps,
} from "react-router-dom";
import { useAuthContext } from "./auth";
import { ApplicationShell } from "./components/ApplicationShell";
import { Card } from "./components/core/Card";
import { FullScreenTopiaLoader } from "./components/core/TopiaLoader";
import { IntercomIllustration } from "./components/core/illustrations/IntercomIllustration";
import { useDataContext } from "./context/DataContext";
import { AccountPage } from "./pages/AccountPage";
import { AddAccountManual } from "./pages/AddAccountManual";
import { AddAccountManualPrimer } from "./pages/AddAccountManualPrimer";
import { AddAccount } from "./pages/AddAccountPage";
import { CommunityPage } from "./pages/CommunityPage";
import { ConfirmAccountsPage } from "./pages/ConfirmAccountsPage";
import { DashboardPage } from "./pages/DashboardPage";
import { ErrorPage } from "./pages/ErrorPage";
import { LoginPage } from "./pages/LoginPage";
import { OnboardingPage } from "./pages/OnboardingPage";
import { PortfolioPage } from "./pages/PortfolioPage";
import { SavingsRatePage } from "./pages/SavingsRatePage";
import { SettingsPage } from "./pages/SettingsPage";
import { SignUpPage } from "./pages/SignUpPage";
import { useIsLoadingGlobal } from "./store";
import ForgotPassWordPage from "./pages/ForgotPasswordPage";

function ProtectedRoute(props: { children: any }) {
  const authCtx = useAuthContext();

  if (useIsLoadingGlobal()) {
    return <FullScreenTopiaLoader />
  }

  if (!authCtx.user) {
    return <Navigate to="/login" />;
  }

  return props.children;
}

function ApplicationRoute(children: JSX.Element) {
  return (
    <ProtectedRoute>
      <ApplicationShell>{children}</ApplicationShell>
    </ProtectedRoute>
  );
}

export const router = createBrowserRouter(  createRoutesFromElements(
    <>
      <Route errorElement={<ErrorPage />} path="/login" element={<LoginPage />} />
      <Route errorElement={<ErrorPage />} path="/forgot-password" element={<ForgotPassWordPage />} />
      <Route errorElement={<ErrorPage />} path="/sign-up" element={<SignUpPage />} />
      <Route errorElement={<ErrorPage />} path="/onboarding" element={<OnboardingPage />} />
      <Route errorElement={<ErrorPage />} path="/portfolio" element={ApplicationRoute(<PortfolioPage />)} />
      <Route errorElement={<ErrorPage />} path="/community" element={ApplicationRoute(<CommunityPage />)} />
      <Route errorElement={<ErrorPage />} path="/savings-rate" element={ApplicationRoute(<SavingsRatePage />)} />
      <Route errorElement={<ErrorPage />} path="/add-account" element={ApplicationRoute(<AddAccount />)} />
      <Route errorElement={<ErrorPage />} path="/confirm-accounts" element={ApplicationRoute(<ConfirmAccountsPage />)} />
      <Route errorElement={<ErrorPage />} path="/add-account-manual" element={ApplicationRoute(<AddAccountManualPrimer />)} />
      <Route errorElement={<ErrorPage />} path="/add-account-manual/:type" element={ApplicationRoute(<AddAccountManual />)} />
      <Route errorElement={<ErrorPage />} path="/account/:accountId" element={ApplicationRoute(<AccountPage />)} />
      <Route errorElement={<ErrorPage />} path="/settings" element={ApplicationRoute(<SettingsPage />)} />
      <Route errorElement={<ErrorPage />} path="/" element={ApplicationRoute(<DashboardPage />)} />
      <Route errorElement={<ErrorPage />} path="*" element={<LoginPage />} />
    </>
  )
);

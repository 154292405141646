import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import moment from "moment";
import { useDataContext } from "./context/DataContext";
import { formatCurrency } from "./helpers";

export function PortfolioDelta(props: { className?: string }) {
  const dataCtx = useDataContext();

  const netWorth = dataCtx.modelResult.netWorthInfo?.netWorth || 0;
  const fi = dataCtx.modelResult.FINr;
  const historyNetWorth = dataCtx.lastMonthNetWorth;

  let progressIncrease = "0";

  let percentage = netWorth / fi;
  if (percentage > 1) {
    percentage = 1;
  }

  if (historyNetWorth) {
    let historyPercentage = historyNetWorth / fi;

    if (historyPercentage > 1) {
      historyPercentage = 1;
    }

    progressIncrease = Number((percentage - historyPercentage) * 100).toFixed(
      2
    );
  }

  const progressPinContent = `${progressIncrease}% in ${moment().format(
    "MMM"
  )}`;

  return (
    <div className={classNames(["px-2 mb-4", props.className])}>
      <div className="flex mb-1 items-end align-end">
        <p className="text-white text-sm">
          {Number(percentage * 100).toFixed(2)}%
        </p>
        <div className="flex-1"></div>
        <div className="text-right">
          <p className="text-md w-full">
            <span className="text-topia-gray text-xs">FI Number</span>
          </p>
          <p className="text-lg text-white">
            {formatCurrency(dataCtx.modelResult.FINr)}
          </p>
        </div>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-3 mb-1 dark:bg-gray-700">
        <div
          className={classNames(
            "h-3 rounded-full",
            netWorth < 0 ? "bg-red-600" : "bg-future-blue"
          )}
          style={{
            width: `${Math.min(50, percentage * 100)}%`,
          }}
        ></div>
      </div>
      <p className="text-white text-sm flex align-center items-center">
        {Number(progressIncrease) > 0 ? (
          <ChevronUpIcon className="w-4 h-4 mr-2" />
        ) : (
          <ChevronDownIcon className="w-4 h-4 mr-2" />
        )}
        {progressPinContent}
      </p>{" "}
    </div>
  );
}

import { useEffect } from "react";
import { trackAnalyticsEvent } from "../analytics";
import { Card } from "../components/core/Card";
import { TopiaButton } from "../components/core/TopiaButton";
import { TopiaLogo } from "../components/core/TopiaLogo";
import { useDataContext } from "../context/DataContext";

export function CommunityPage(props: {}) {
  useEffect(() => {
    trackAnalyticsEvent("community_page_visited");
  }, [])

  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <Card>
        <div className="p-4">
          <div className="flex my-8 text-center items-center justify-center align-center">
            <div style={{ width: 150 }}>
              <TopiaLogo className="" />
            </div>
            <h1 className="text-xl"><sup>PLUS</sup></h1>
          </div>
          <section className="overflow-hidden ">
            <div className="relative mx-auto max-w-7xl px-4 pt-20 pb-12 sm:px-6 lg:px-8 lg:py-20">
              <svg
                className="absolute right-full top-1/2 hidden translate-x-1/2 -translate-y-1/2 transform lg:block"
                width={404}
                height={784}
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-white"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={784}
                  fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
                />
              </svg>

              <div className="relative lg:flex lg:items-center">
                <div className="hidden lg:block lg:flex-shrink-0">
                  <img
                    className="h-32 w-32 rounded-full xl:h-48 xl:w-48"
                    src="grant.jpg"
                    alt=""
                  />
                </div>

                <div className="relative lg:ml-10">
                  <blockquote className="relative">
                    <div className="text-2xl font-medium leading-9 text-white">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Nemo expedita voluptas culpa sapiente alias molestiae.
                        Numquam corrupti in laborum sed rerum et corporis.
                      </p>
                    </div>
                    <footer className="mt-8">
                      <div className="flex">
                        <div className="flex-shrink-0 lg:hidden">
                          <img
                            className="h-12 w-12 rounded-full"
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </div>
                        <div className="ml-4 lg:ml-0">
                          <div className="text-base font-medium text-white">
                            Grant Sabatier
                          </div>
                          <div className="text-base font-medium text-blue-600">
                            Cofounder, Topia
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </section>
          <TopiaButton className="w-full mt-8 my-4">
            Join The Waitlist
          </TopiaButton>
        </div>
      </Card>
    </div>
  );
}

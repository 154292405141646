import { updateDoc } from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AccountForm } from "../components/AccountForm";
import { Card } from "../components/core/Card";
import { DataPoint } from "../components/core/DataPoint";
import { TopiaButton } from "../components/core/TopiaButton";
import { TopiaDateInput } from "../components/core/TopiaDateInput";
import { TopiaSelect } from "../components/core/TopiaSelect";
import { useDataContext } from "../context/DataContext";
import { appActions, useAppDispatch, useAppSelector } from "../store";

export function SettingsPage(props: {}) {
  const ogData = useAppSelector((state) => state.app.userData);
  const appData = useAppSelector(
    (state) => state.app.tinkerData || state.app.userData
  );
  const dataCtx = useDataContext();
  const [loadingDob, setLoadingDob] = useState(false);
  const dobForm = useForm();
  const { dateOfBirth } = dobForm.watch();

  async function saveDob() {
    setLoadingDob(true);
    // TODO: Error handling

    await updateDoc(dataCtx.userRef, {
      "settings.dateOfBirth": moment(dateOfBirth).format("DD/MM/YYYY"),
    });

    setLoadingDob(false);
  }

  useEffect(() => {
    if (dateOfBirth) {
      saveDob();
    }
  }, [dateOfBirth]);

  const appDispatch = useAppDispatch();

  if (!ogData.user) return <></>;

  return (
    <div className="topia-bounce-in max-w-3xl m-auto">
      <div className="mb-4 flex w-full items-center justify-center">
        <div className="text-xl text-white">Settings</div>
      </div>
      <Card className="p-4">
        <DataPoint
          label="Inflation"
          value={appData.user?.settings?.inflation}
          suffix={<span className="text-white">%</span>}
          ogValue={ogData.user?.settings?.inflation}
          editable
          onFinishEditing={(val) => {
            appDispatch(
              appActions.updateTinkerUserSettings({
                inflation: val,
              })
            );
          }}
        />

        <TopiaDateInput
          controllerProps={{
            name: "dateOfBirth",
            defaultValue: moment(
              ogData.user?.settings?.dateOfBirth,
              "DD/MM/YYYY"
            ).toDate(),
            rules: {
              required: true,
              value: moment(
                ogData.user?.settings?.dateOfBirth,
                "DD/MM/YYYY"
              ).toDate(),
            },
          }}
          datePickerProps={{ maxDate: new Date() }}
          control={dobForm.control}
          label="Date of Birth"
          id="settingsDateOfBirth"
          className="mt-4"
        />

        <TopiaSelect
          inputProps={{
            value: ogData.user.region,
            onChange: (e) => {
              dataCtx.operations.updateRegion(
                e.currentTarget.value as "us" | "gb"
              );
            },
          }}
          label="Region"
          id="region"
          options={[
            { label: "US ($)", value: "us" },
            { label: "UK (£)", value: "gb" },
          ]}
        />

        {/* <DataPoint
          label="Date of birth"
          value={appData.user.settings?.dateOfBirth}
          ogValue={ogData.user.settings?.dateOfBirth}
          editable
          onFinishEditing={(val) => {
            appDispatch(
              appActions.updateTinkerUserSettings({
                dateOfBirth: val,
              })
            );
          }}
        /> */}
      </Card>
    </div>
  );
}

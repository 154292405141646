import { CONSTANTS } from "../constants";

export function Footer(props: {}) {
  return (
    <div className="mt-16 max-w-xl mx-auto mb-4">
      <p className="text-topia-gray text-xs">
        Copyright 2023 Axis Investing, Ltd. All rights reserved.
      </p>
      <div className="">
        <a href={CONSTANTS.links.privacyPolicy} target="_blank" className="text-royal-purple mr-4 text-xs">
          Privacy Policy
        </a>
        <a href={CONSTANTS.links.termsAndConditions} target="_blank" className="text-royal-purple text-xs">
          Terms &amp; Conditions
        </a>
      </div>
    </div>
  );
}

import { format } from "path";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth";
import { TopiaButton } from "../components/core/TopiaButton";
import {
  TopiaEmailInput,
  TopiaPasswordInput,
  TopiaTextInput,
} from "../components/core/TopiaInput";
import { TopiaLogo } from "../components/core/TopiaLogo";
import * as Sentry from "@sentry/react"

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export function LoginPage() {
  const authCtx = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoaing] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [err, setErr] = useState<any>();

  const navigate = useNavigate();

  const form = useForm();

  async function submitLogin(vals) {
    setLoading(true);
    try {
      await authCtx.signIn({
        email: vals.email.trim(),
        password: vals.password,
      });
      // This is a hack
      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 100)
    } catch (ex) {
      if (ex.code === "auth/user-not-found") {
        setErr(
          <div className="my-4">
            <p className="text-red-500 text-md">User not found</p>
          </div>
        );
      } else if (ex.code === "auth/wrong-password") {
        setErr(
          <div className="my-4">
            <p className="text-red-500 text-md">Incorrect password</p>
          </div>
        );
      } else {
        console.error(ex);
        setErr(
          <div className="my-4">
            <p className="text-red-500 text-md">{ex.code}</p>
          </div>
        );
      }
      setLoading(false);
    }
  }

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
      <div className="lg:grid lg:grid-cols-5 min-h-full justify-center py-12 sm:px-6 lg:px-8 bg-topia-black ">
        <div className="col-span-2 px-16">
          <div className="w-full">
            <div className="">
              <TopiaLogo svgProps={{ style: { fill: "white", width: 103 } }} />
            </div>
            <h2 className="my-8 text-5xl tracking-tight text-white font-serif font-light">
              Sign In
            </h2>
          </div>

          <div className="w-full">
            <div className="">
              <form
                className="space-y-6"
                onSubmit={form.handleSubmit(submitLogin)}
              >
                <div>
                  <TopiaEmailInput
                    label="Email address"
                    inputProps={form.register("email", {
                      required: true,
                    })}
                    id="login-email"
                    className=""
                  />
                  <TopiaPasswordInput
                    label="Password"
                    inputProps={form.register("password", {
                      required: true,
                    })}
                    id="login-password"
                    className="mt-4"
                  />
                </div>

                {/* <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-white"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none   border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                </div> */}

                <div className="flex items-center justify-between">
                  {/* <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-white"
                  >
                    Remember me
                  </label>
                </div> */}

                  <div className="text-sm">
                    <Link
                      to="/forgot-password"
                      className="font-medium text-center text-underline text-future-blue hover:text-white"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>

                <div>
                  <TopiaButton
                    type="submit"
                    className="w-full"
                    analyticsEvent="sign_in_clicked"
                    btnStyle="primary"
                    loading={loading}
                  >
                    Sign In
                  </TopiaButton>

                  <Link to="/sign-up" type="submit" className="w-full block">
                    <TopiaButton
                      className="w-full mt-4 "
                      btnStyle="future"
                      analyticsEvent="create_your_account_clicked"
                    >
                      Create your account
                    </TopiaButton>
                  </Link>

                  {/* <a
                    href="#"
                    className="inline-flex w-full justify-center w-1/2  border border-gray-300 py-2 px-4 text-sm font-medium text-white"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center w-1/2  border border-gray-300 py-2 px-4 text-sm font-medium text-white"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a> */}
                </div>
              </form>

              <div className="mt-6">
                <div className="relative">
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-white">Or</span>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-center">
                  <div>
                   <TopiaButton
                      loading={googleLoading}
                      onClick={async () => {
                        try {
                          setGoogleLoaing(true);
                          const res = await authCtx.signInWithGoogle();

                          if (res.isNewUser) {
                            navigate("/onboarding");
                          } else {
                            navigate("/");
                          }
                        } catch (err) {
                          Sentry.captureException(err, {
                            tags: { "error-type": "google-sign-in", "page": "login" },
                          })
                          console.log("err is,", err);
                          setGoogleLoaing(false);
                        }
                      }}
                      btnStyle="secondary"
                      className="w-full border-white mt-4 px-8 mx-2"
                    >
                      Continue with Google
                    </TopiaButton>
                    <TopiaButton
                      loading={appleLoading}
                      onClick={async () => {
                        try {
                          setAppleLoading(true);
                          const res = await authCtx.signInWithApple();

                          if (res.isNewUser) {
                            navigate("/onboarding");
                          } else {
                            navigate("/");
                          }
                        } catch (err) {
                          Sentry.captureException(err, {
                            tags: { "error-type": "apple-sign-in", "page": "login" },
                          })
                          console.log("err is,", err);
                          setAppleLoading(false);
                        }
                      }}
                      btnStyle="secondary"
                      className="w-full border-white mt-4 px-8 mx-2"
                    >
                      Continue with Apple
                    </TopiaButton> 
                  </div>
                </div>
              </div>
              {err}
            </div>
          </div>
        </div>
        <div className="col-span-3 p-8">
          <div className="rounded-xl flex items-center justify-center" style={{
            backgroundImage: "url(signup-photo.jpg)",
            backgroundSize: "100% auto",
            width: "50vw",
            height: "50vw",
            backgroundRepeat: "no-repeat",
          }}>
            {/* <h2 className="text-white" style={{
              fontSize: "80px"
            }}> */}
              {/* Accelerate<br />your journey<br />to FI */}
            {/* </h2> */}
          </div>
        </div>
      </div>
    </>
  );
}

import { UserModel } from "@byundefined/topia-model/lib/commonTypes";
import classNames from "classnames";
import classnames from "classnames";
import { useModalContext } from "../context/ModalContext";
import { formatCurrency } from "../helpers";
import {
  useAppDispatch,
  useAppSelector,
  appActions,
  useIsLoadingGlobal,
} from "../store";
import { Card } from "./core/Card";
import { DataPoint } from "./core/DataPoint";
import { IncomeForm } from "./IncomeForm";
import { IncomeList } from "./IncomeList";
import { TopiaSkeleton } from "./core/TopiaSkeleton";
import { FIFlavorPopup } from "./FIFlavorPopup";
import { BriefcaseIllustration } from "./core/illustrations/BriefcaseIllustration";

export function FIBaseSettings(props: { className?: string }) {
  const appDispatch = useAppDispatch();
  const modalCtx = useModalContext();

  const oldData = useAppSelector((state) => state.app.userData);

  const appData = useAppSelector(
    (state) => state.app.tinkerData || state.app.userData
  );

  if (useIsLoadingGlobal()) {
    return <TopiaSkeleton />;
  }

  const user = appData.user;
  const settings: UserModel["settings"] = user?.settings || ({} as any);

  const options: {
    label: string;
    description: any;
    flavor: UserModel["settings"]["postFIType"];
  }[] = [
    {
      label: "Traditional FI",
      description: <p>Traditional Retirement</p>,
      flavor: "lean",
    },
    {
      label: "Barista FI",
      description: <p>Partial Retirement</p>,
      flavor: "barista",
    },
    {
      label: "Coast FI",
      description: <p>Coast your way to FI</p>,
      flavor: "coast",
    },
  ];

  const activeSetting =
    options.find((o) => o.flavor === settings?.postFIType)! || ({} as any);

  return (
    <dl className={classNames("h-full flex flex-col", props.className)}>
      <div
        className={classNames(
          "flex"
          // settings?.postFIType === "coast" || settings.postFIType === "barista"
          //   ? "grid-cols-2"
          //   : "grid-cols-3"
        )}
      >
        <DataPoint
          label="FI Flavor"
          value={activeSetting.label}
          onClick={() => {
            modalCtx.openModal(<FIFlavorPopup />);
          }}
          className="flex-1"
          changeIndicatorPosition="below"
        />
        <DataPoint
          label="Monthly Spend at FI"
          onFinishEditing={(v) => {
            appDispatch(
              appActions.updateTinkerUserSettings({
                retirementSpent: v,
              })
            );
          }}
          ogValue={oldData.user?.settings.retirementSpent}
          value={settings.retirementSpent}
          editable
          className="flex-1 mx-1"
          type="currency"
          changeIndicatorPosition="below"
        />
        <DataPoint
          label="Current Monthly Spend"
          onFinishEditing={(v) => {
            appDispatch(
              appActions.updateTinkerUserSettings({
                monthlySpend: v,
              })
            );
          }}
          ogValue={oldData.user?.settings.monthlySpend}
          value={settings.monthlySpend}
          editable
          className="flex-1 mx-1"
          type="currency"
          changeIndicatorPosition="below"
        />
        {settings.postFIType === "barista" && (
          <DataPoint
            label="Retirement Income"
            inverseColors
            onFinishEditing={(v) => {
              appDispatch(
                appActions.updateTinkerUserSettings({
                  retirementIncome: v,
                })
              );
            }}
            ogValue={oldData.user?.settings.retirementIncome || 1000}
            value={settings.retirementIncome || 1000}
            editable
            className="flex-1 mx-1"
            type="currency"
            changeIndicatorPosition="below"
          />
        )}
        {settings.postFIType === "coast" && (
          <DataPoint
            label="Full FI Age"
            inverseColors
            onFinishEditing={(v) => {
              appDispatch(
                appActions.updateTinkerUserSettings({
                  coastFIAge: v,
                })
              );
            }}
            value={settings.coastFIAge || 60}
            editable
            className="flex-1 mx-1"
            changeIndicatorPosition="below"
          />
        )}
        {/* <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-white">About</dt>
            <dd className="mt-1 text-sm text-white">
              Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
              incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
              consequat sint. Sit id mollit nulla mollit nostrud in ea officia
              proident. Irure nostrud pariatur mollit ad adipisicing
              reprehenderit deserunt qui eu.
            </dd>
          </div> */}
      </div>
    </dl>
  );
}

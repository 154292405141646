import classNames from "classnames";
import { DetailedHTMLProps, SelectHTMLAttributes } from "react";
import { BASE_INPUT_CLASSES } from "./TopiaInput";

export function TopiaSelect(props: {
  className?: string;
  inputProps?: DetailedHTMLProps<
    SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;
  id: string;
  label: string;
  options: { value: string; label: string }[];
}) {
  return (
    <div className={classNames(props.className)}>
      <label
        htmlFor={props.id}
        className="block text-sm font-medium text-topia-gray"
      >
        {props.label}
      </label>
      <div className="mt-1">
        <select {...props.inputProps} id="type" className={BASE_INPUT_CLASSES}>
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

import { FirebaseOptions, initializeApp } from "firebase/app";
import * as fbFunctions from "firebase/functions";
import * as fbFirestore from "firebase/firestore";
import * as fbAuth from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectAuthEmulator } from "firebase/auth";
import { connectFunctionsEmulator } from "firebase/functions";

// PROD
const firebaseEnv: "staging" | "production" | "emulator" =
  process.env.REACT_APP_FIREBASE_ENV as any;

let firebaseConfig: any;
if (firebaseEnv === "staging" || firebaseEnv === "emulator") {
  firebaseConfig = {
    apiKey: "AIzaSyAeoxNEno4aX0ybyxU1Z_5ahqyIFCdwzpI",
    authDomain: "topia-staging.firebaseapp.com",
    projectId: "topia-staging",
    storageBucket: "topia-staging.appspot.com",
    messagingSenderId: "859350511514",
    appId: "1:859350511514:web:5a1d68e197aa1d4da5d423",
    measurementId: "G-XW6HRE6GST",
  };
} else if (firebaseEnv === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCCpy60HGEjSXW7kYSfLO-njdhywJuN_N0",
    authDomain: "topia-58848.firebaseapp.com",
    databaseURL: "https://topia-58848.firebaseio.com",
    projectId: "topia-58848",
    storageBucket: "topia-58848.appspot.com",
    messagingSenderId: "632766455119",
    appId: "1:632766455119:web:81999442a887deb308393a",
    measurementId: "G-DJDSCGNDE8",
  };
} else {
  throw new Error("Invalid firebase config");
}

const app = initializeApp(firebaseConfig);

export const auth = fbAuth.getAuth(app);
export const db = fbFirestore.getFirestore(app);
export const functions = fbFunctions.getFunctions(app, "europe-west2");

if (firebaseEnv === "emulator") {
  connectFirestoreEmulator(db, "localhost", 9090);
  connectAuthEmulator(auth, "http://localhost:9099", {
    disableWarnings: true,
  });
  connectFunctionsEmulator(functions, "localhost", 5001);
}

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { formatCurrency } from "../helpers";
import { AppUserData, useAppSelector } from "../store";
import { ChangeIndicator } from "./ChangeIndicator";
import { UseFinancialModelResult } from "../hooks/useFinancialModel";
import React from "react";

function getFIAge(dob: string, fiDate: Date | string): number {
  // console.log("age", dob, fiDate);
  const dobMoment = moment(dob, "DD/MM/YYYY");
  const fiMoment = moment(fiDate);

  let difference: any = Math.ceil(fiMoment.diff(dobMoment, "years", true));

  // console.log("diff", difference);
  if (isNaN(difference)) {
    difference = "N/A";
  }

  return difference;
}

const modulo = (n: number, m: number) => {
  //also works on negative number
  return ((n % m) + m) % m;
};

const formatDateDifference = (
  prevDate: any,
  date: any,
  includeMonths: boolean
) => {
  const difference = moment(prevDate).diff(moment(date), "days");
  const start = moment(prevDate);
  const end = moment(date);
  let comparisonInvalid = false;

  if (
    start.isBefore(moment("01/01/2005", "DD/MM/YYYY")) ||
    end.isBefore(moment("01/01/2005", "DD/MM/YYYY"))
  ) {
    comparisonInvalid = true;
  }
  let differenceString;

  // let years = start.diff(end, 'year');
  // end.add(years, 'years');

  // let months = start.diff(end, 'months');
  // end.add(months, 'months');

  // let yearsAbs = Math.abs(years);
  // let monthsAbs = Math.abs(months);

  // let monthsString = `${monthsAbs} mo${monthsAbs > 1 ? 's' : ''}`;
  // differenceString = `${yearsAbs} yr${yearsAbs > 1 ? 's' : ''}`;

  const differenceInMonths = Math.abs(
    moment(prevDate)
      .startOf("month")
      .diff(moment(date).startOf("month"), "months")
  );

  const yearsDifference = Math.floor(differenceInMonths / 12);
  const monthsDifference = Math.round(modulo(differenceInMonths, 12));

  const yearsText = `${Math.abs(yearsDifference)} yr${
    yearsDifference !== 1 ? "s" : ""
  }`;
  const monthsText = `${Math.abs(monthsDifference)} mo${
    monthsDifference !== 1 ? "s" : ""
  }`;

  differenceString = yearsText;

  if (includeMonths) {
    differenceString = differenceString + " " + monthsText;
  }

  return { difference, differenceString, comparisonInvalid };
};

export const FIStats = React.memo(_FIStats, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

function _FIStats(props: {
  className?: string;
  inverse?: boolean;
  userData: {
    settings: AppUserData["user"]["settings"];
    modelResult: UseFinancialModelResult;
  };
  tinkerData?: {
    settings: AppUserData["user"]["settings"];
    modelResult: UseFinancialModelResult;
  };
  region?: "us" | "gb";
}) {
  const { userData, tinkerData } = props;

  const isTinkerMode = !!tinkerData;
  const region = props.region || global.region || "us";

  const settings = (tinkerData || userData).settings;

  if (!settings) {
    return <></>;
  }

  const prevFIDate = userData.modelResult?.FIDate?.toDate();
  const fiDate = tinkerData?.modelResult?.FIDate?.toDate();

  const prevFINumber = userData.modelResult.FINr;
  const fiNumber = tinkerData?.modelResult?.FINr;

  const prevCoastFIDate = userData.modelResult.coastFIDate;
  const coastFIDate = tinkerData?.modelResult?.coastFIDate;

  const date =
    settings.postFIType === "coast" && coastFIDate ? coastFIDate : fiDate;

  const formattedDate = formatDateDifference(prevFIDate, date, true);

  const fiNumberDiff = Math.round(fiNumber - prevFINumber);
  const fiCoastAgeDiff =
    getFIAge(settings?.dateOfBirth, coastFIDate) -
    getFIAge(settings?.dateOfBirth, prevCoastFIDate);

  // console.log("2@ fiDate", settings.dateOfBirth, fiDate);
  const fiAge = getFIAge(settings.dateOfBirth, fiDate || prevFIDate);

  const coastFiAge = getFIAge(
    settings.dateOfBirth,
    tinkerData?.modelResult?.coastFIDate
  );
  const prevCoastFiAge = getFIAge(
    settings.dateOfBirth,
    userData.modelResult?.coastFIDate
  );

  // console.log("prevFiAge");
  const prevFiAge = getFIAge(settings.dateOfBirth, prevFIDate);
  const fiAgeDiff = fiAge - prevFiAge;

  let displayFiAge: any;

  if (isTinkerMode) {
    displayFiAge =
      tinkerData?.settings?.postFIType === "coast" ? coastFiAge : fiAge;
  } else {
    displayFiAge =
      userData?.settings.postFIType === "coast" ? prevCoastFiAge : prevFiAge;
  }

  const stats: any[] = [
    {
      id: 1,
      name: "FI Number",
      stat: formatCurrency(fiNumber || prevFINumber, region),
      // countStat: true,
      icon: UsersIcon,
      ...(isTinkerMode
        ? {
            change: formatCurrency(
              Math.abs(userData.modelResult.FINr - tinkerData.modelResult.FINr), region
            ),
            changeType:
              userData.modelResult.FINr < tinkerData.modelResult.FINr
                ? "increase"
                : "decrease",
          }
        : undefined),
    },
    {
      id: 2,
      name: "FI Date",
      stat: (tinkerData || userData).modelResult.FIDateString,
      icon: EnvelopeOpenIcon,
      // ...(isTinkerMode && !isNaN(fiAgeDiff)
      //   ? {
      //       change: fiAgeDiff,
      //       changeType: fiAgeDiff > 0 ? "increase" : "decrease",
      //     }
      //   : undefined),
      ...(isTinkerMode &&
      props.userData.modelResult.canReachFI &&
      props.tinkerData?.modelResult?.canReachFI
        ? {
            change: formattedDate.differenceString,
            changeType: formattedDate.difference < 0 ? "increase" : "decrease",
          }
        : undefined),
    },
    // {
    //   id: 3,
    //   name: "Time to FI",
    //   stat: (tinkerData || userData).modelResult.timeToFIString,
    //   icon: CursorArrowRaysIcon,
    // },
    {
      id: 4,
      name:
        (tinkerData || userData).settings.postFIType === "coast"
          ? "Coast FI Age"
          : "FI Age",
      stat: displayFiAge,
      icon: CursorArrowRaysIcon,
      ...(isTinkerMode &&
      userData?.modelResult?.canReachFI &&
      tinkerData?.modelResult?.canReachFI
        ? {
            change: fiAgeDiff,
            changeType: fiAgeDiff > 0 ? "increase" : "decrease",
          }
        : undefined),
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if ((tinkerData || userData).modelResult.canReachFI === false) {
    return (
      <div className="flex">
        <div
          className={classNames("relative rounded-l mr-4 sm:mr-8 flex-1")}
        >
          <dt>
            <p
              className={classNames(
                "truncate text-sm font-medium",
                props.inverse ? "text-black" : "text-white"
              )}
            >
              FI Number
            </p>
          </dt>
          <dd className="flex-column">
            <p
              className={classNames(
                "text-xl lg:text-xl 2xl:text-3xl text-white",
                props.inverse ? "text-black" : "text-white"
              )}
            >
              {formatCurrency(fiNumber || prevFINumber, region)}
            </p>
            {isTinkerMode &&
              userData.modelResult.FINr - tinkerData.modelResult.FINr !== 0 ? (
                <ChangeIndicator
                  change={formatCurrency(
                    Math.abs(
                      userData.modelResult.FINr - tinkerData.modelResult.FINr
                    ),region
                  )}
                  changeType={
                    userData.modelResult.FINr < tinkerData.modelResult.FINr
                      ? "increase"
                      : "decrease"
                  }
                />
              ) : <></>}
          </dd>
        </div>

        <p className="text-md text-white">
          Uh-oh, please double check your settings. Based on your inputs, your
          net worth will never exceed your FI number.
        </p>
      </div>
    );
  }

  return (
    <dl className={classNames("flex", props.className)}>
      {stats.map((item, ix) => (
        <div
          key={item.id}
          className={classNames("relative rounded-l mr-4 sm:mr-8 flex-1")}
        >
          <dt>
            {/* <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div> */}
            <p
              className={classNames(
                "truncate text-sm font-medium",
                props.inverse ? "text-black" : "text-white"
              )}
            >
              {item.name}
            </p>
          </dt>
          <dd className="flex-column">
            <p
              className={classNames(
                "text-xl lg:text-xl 2xl:text-3xl text-white font-light",
                props.inverse ? "text-black" : "text-white"
              )}
            >
              {/* {item.countStat ? <CountUp end={item.stat} duration={2} startVal={item.stat * 0.5} formattingFn={formatCurrency} /> : item.stat} */}
              {item.stat}
            </p>
            {item.change && (
              <ChangeIndicator
                change={item.change}
                changeType={item.changeType}
              />
            )}
          </dd>
        </div>
      ))}
    </dl>
  );
}

import { calculateDateNetWorth } from "@byundefined/topia-model";
import { DateYMString } from "@byundefined/topia-model/lib/commonTypes";
import _ from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDataContext } from "../context/DataContext";
import { formatCurrency } from "../helpers";
import { useAppSelector } from "../store";
import {
  SavingsRateDatum,
  TopiaSavingsRateGraph,
} from "../topia-graph/TopiaSavingsRateGraph";
import { Card } from "./core/Card";
import { TopiaButton } from "./core/TopiaButton";
import { SurplusWarning } from "./SurplusWarning";

export function SavingsRateChart(props: {}) {
  const accounts = useAppSelector((state) => state.app.userData.accounts);
  const modelResult = useAppSelector((state) => state.app.userData.modelResult);
  const settings = useAppSelector(
    (state) => state.app.userData?.user?.settings
  );
  const svgRef = useRef<any>();

  const dataCtx = useDataContext();
  const history = dataCtx.history;

  const graphRef = useRef<TopiaSavingsRateGraph>();
  useEffect(() => {
    if (svgRef.current && !graphRef.current) {
      graphRef.current = new TopiaSavingsRateGraph(svgRef.current);
    }

    const onWindowResize = _.throttle(
      () => {
        graphRef.current.updateGraph();
      },
      100,
      {
        trailing: true,
      }
    );

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [svgRef.current]);

  useEffect(() => {
    if (graphRef.current && history) {
      graphRef.current.setModelData(history);
    }
  }, [history]);

  const investmentContribution = Number(
    modelResult?.netWorthInfo?.investmentContribution
  );
  let currentInvestmentRate =
    investmentContribution / settings?.monthlyTakehome!;

  return (
    <div className="w-full">
      <p className="text-lg text-white pt-4 mb-2">Investment Rate</p>
      <Card>
        <SurplusWarning />
        {accounts.length > 0 ? (
          <>
            <div className="">
              <div className="">
                <svg
                  width="100%"
                  height={180}
                  ref={svgRef}
                  style={{
                    overflow: "hidden",
                  }}
                />
              </div>
            </div>
            <div className="p-2">
              <p className="text-lg text-white">
                {formatCurrency(Math.round(investmentContribution))} Forecasted
                Investment {moment().format("MMM YYYY")}
              </p>
              {!isNaN(currentInvestmentRate) && (
                <p className="text-sm text-white">
                  {Math.floor((currentInvestmentRate || 0) * 100)}% of your
                  income
                </p>
              )}
            </div>
          </>
        ) : (
          <div className="">
            <p className="text-lg text-white my-2">
              You have not connected any accounts. Add an account to start
              tracking your progress to FI.
            </p>
            <Link to="/add-account">
              <TopiaButton btnStyle="future" className="my-4">
                Add Accounts
              </TopiaButton>
            </Link>
          </div>
        )}
      </Card>
    </div>
  );
}

import classNames from "classnames";
import { getCurrencySymbol } from "../../helpers";
import { TopiaTooltip } from "./TopiaTooltip";
export const BASE_INPUT_CLASSES =
  "block w-full rounded-xl bg-topia-black border-white/20 p-4 my-2 font-light text-white placeholder:text-white";

export type TopiaBaseInputProps = {
  className?: string;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  id?: string;
  label?: string;
  labelType?: "above";
};

export function TopiaBaseInput(props: TopiaBaseInputProps) {
  return (
    <div className={`${classNames(props.className)}`}>
      {/* {props.labelType === "above" && ( */}
      <div className="flex gap-3">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-topia-gray"
        >
          {props.label}
        </label>
        {props.id !== "name" && <TopiaTooltip label={props.label} />}
      </div>
      {/* )} */}
      <div className="mt-1">
        <input
          type={props.inputProps.type}
          id={props.id}
          // placeholder={props.label}
          className={BASE_INPUT_CLASSES}
          {...props.inputProps}
        />
      </div>
    </div>
  );
}

export const TopiaTextInput = (props: TopiaBaseInputProps) => (
  <TopiaBaseInput
    {...props}
    inputProps={{ ...props.inputProps, type: "text" }}
  />
);

export const TopiaCurrencyInput = (props: TopiaBaseInputProps) => (
  <div className="relative">
    <div className="absolute right-6 bottom-1/4 text-md text-topia-gray">
      {getCurrencySymbol()}
    </div>
    <TopiaBaseInput
      labelType="above"
      {...props}
      inputProps={{
        ...props.inputProps,
        type: "number",
        placeholder: undefined,
        step: 0.01,
      }}
    />
  </div>
);

export const TopiaEmailInput = (props: TopiaBaseInputProps) => (
  <TopiaBaseInput
    {...props}
    inputProps={{ ...props.inputProps, type: "email" }}
  />
);

export const TopiaPasswordInput = (props: TopiaBaseInputProps) => (
  <TopiaBaseInput
    {...props}
    inputProps={{ ...props.inputProps, type: "password" }}
  />
);

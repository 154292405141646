export function PlaidGlassesIllustration(props: {
  className?: string;
  svgProps?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="61"
      fill="none"
      viewBox="0 0 63 61"
      className={props.className}
      {...props.svgProps}
    >
      <g>
        <path
          fill="#E0FEA1"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M31.06 59.68c16.878 0 30.56-13.248 30.56-29.59S47.938.5 31.06.5.5 13.748.5 30.09s13.682 29.59 30.56 29.59Z"
        />
        <path
          fill="#1D1D20"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="m5.33 25.22 21.09-8.33c1.5-.59 3.21.33 3.21 1.72v4.06c0 .71-.64 1.31-1.49 1.38-1.09.09-2.03-.64-2.03-1.56v-1.2c0-.95-.97-1.58-1.84-1.21L5.33 28.29v-3.07Zm51.26 0L35.5 16.89c-1.5-.59-3.21.33-3.21 1.72v4.06c0 .71.64 1.31 1.49 1.38 1.09.09 2.03-.64 2.03-1.56v-1.2c0-.95.97-1.58 1.84-1.21l18.95 8.2v-3.07l-.01.01Z"
        />
        <path
          fill="#6B33EC"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M56.39 25.22H5.33c-.33 0-.6.27-.6.6v2.87c0 .33.27.6.6.6h1.61v5.36c0 5.76 4.67 10.43 10.43 10.43S27.8 40.41 27.8 34.65v-6.83c2.16-.53 4.42-.5 6.56.12v6.71c0 5.76 4.67 10.43 10.43 10.43s10.43-4.67 10.43-10.43v-5.36h1.15c.33 0 .6-.27.6-.6v-2.87c0-.33-.27-.6-.6-.6h.02Z"
        />
        <path
          fill="#C84897"
          stroke="#1D1D20"
          stroke-miterlimit="10"
          d="M17.38 43.2c-3.75 0-6.93-2.45-8.04-5.83-.27-.82-.41-1.7-.41-2.62v-6.62c0-.57.46-1.02 1.02-1.02H24.8c.57 0 1.02.46 1.02 1.02v6.62c0 4.67-3.78 8.45-8.45 8.45h.01Zm27.43 0c-4.67 0-8.45-3.78-8.45-8.45v-6.62c0-.57.46-1.02 1.02-1.02h14.85c.57 0 1.02.46 1.02 1.02v6.62c0 4.67-3.78 8.45-8.45 8.45h.01Z"
        />
        <path
          stroke="#1D1D20"
          stroke-linecap="round"
          stroke-miterlimit="10"
          d="m49.5 35.06-2.27 2.31m1.26-7.43-2.27 2.3m3.88-.72-5.29 5.05"
        />
      </g>
    </svg>
  );
}
